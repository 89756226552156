/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BatchMaster } from '../models';
import { BatchTerm } from '../models';
import { BatchTimeslot } from '../models';
import { ClassMaster } from '../models';
import { ProgramMaster } from '../models';
import { SignedUrl } from '../models';
import { SubjectMaster } from '../models';
import { Teacher } from '../models';
import { TeacherBatchClasses } from '../models';
import { TeacherClassMaterial } from '../models';
import { TeacherPayment } from '../models';
import { TeacherSubject } from '../models';
import { TeacherTeacherSubjectBody } from '../models';
import { UserTimeslot } from '../models';
import { UserTimeslotHistory } from '../models';
/**
 * TeacherApi - axios parameter creator
 * @export
 */
export const TeacherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get class info
         * @param {number} classMasterId 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _class: async (classMasterId: number, classType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling _class.');
            }
            const localVarPath = `/teacher/class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (classType !== undefined) {
                localVarQueryParameter['classType'] = classType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher's class material
         * @param {string} [timeFilter] 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classMaterial: async (timeFilter?: string, classType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/class_material`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timeFilter !== undefined) {
                localVarQueryParameter['timeFilter'] = timeFilter;
            }

            if (classType !== undefined) {
                localVarQueryParameter['classType'] = classType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to add details of teacher timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherSchedule: async (body: Array<UserTimeslot>, timezone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTeacherSchedule.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling createTeacherSchedule.');
            }
            const localVarPath = `/teacher/teacher_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get list of program master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListProgramMaster: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/list_program_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMaster: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getTeacherBatchMaster.');
            }
            const localVarPath = `/teacher/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMasterTerms: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getTeacherBatchMasterTerms.');
            }
            const localVarPath = `/teacher/batch_master_terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMasterTimeslots: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getTeacherBatchMasterTimeslots.');
            }
            const localVarPath = `/teacher/batch_master_timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getTeacherClassMaster.');
            }
            const localVarPath = `/teacher/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of teacher timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherSchedule: async (date: string, type: string, timezone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getTeacherSchedule.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getTeacherSchedule.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling getTeacherSchedule.');
            }
            const localVarPath = `/teacher/teacher_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get today's classes for teacher
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayClasses: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/today_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list_program_subject
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramSubject: async (programId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling listProgramSubject.');
            }
            const localVarPath = `/teacher/list_program_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markTeacherAttendance: async (classMasterId: number, time: string, eventType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling markTeacherAttendance.');
            }
            // verify required parameter 'time' is not null or undefined
            if (time === null || time === undefined) {
                throw new RequiredError('time','Required parameter time was null or undefined when calling markTeacherAttendance.');
            }
            // verify required parameter 'eventType' is not null or undefined
            if (eventType === null || eventType === undefined) {
                throw new RequiredError('eventType','Required parameter eventType was null or undefined when calling markTeacherAttendance.');
            }
            const localVarPath = `/teacher/mark_teacher_attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher's payment history
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHistory: async (teacherId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling paymentHistory.');
            }
            const localVarPath = `/teacher/payment_history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher's regular classes
         * @param {number} teacherId 
         * @param {string} [timeFilter] 
         * @param {string} [searchQuery] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherBatchClassesClasses: async (teacherId: number, timeFilter?: string, searchQuery?: string, subjects?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling teacherBatchClassesClasses.');
            }
            const localVarPath = `/teacher/teacher_batchClasses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (timeFilter !== undefined) {
                localVarQueryParameter['timeFilter'] = timeFilter;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (subjects) {
                localVarQueryParameter['subjects'] = subjects.join(COLLECTION_FORMATS.csv);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher batches
         * @param {string} [date] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherBatches: async (date?: string, status?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/teacher_batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherClasses: async (classType?: string, date?: string, fromDate?: string, toDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/teacher_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classType !== undefined) {
                localVarQueryParameter['classType'] = classType;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/teacher_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete a teacher's subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTeacherSubjectDelete: async (teacherSubjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherSubjectId' is not null or undefined
            if (teacherSubjectId === null || teacherSubjectId === undefined) {
                throw new RequiredError('teacherSubjectId','Required parameter teacherSubjectId was null or undefined when calling teacherTeacherSubjectDelete.');
            }
            const localVarPath = `/teacher/teacher_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherSubjectId !== undefined) {
                localVarQueryParameter['teacherSubjectId'] = teacherSubjectId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to post teacher's subject
         * @param {TeacherTeacherSubjectBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTeacherSubjectPost: async (body: TeacherTeacherSubjectBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling teacherTeacherSubjectPost.');
            }
            const localVarPath = `/teacher/teacher_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUploadProfilepic: async (profilePic: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling teacherUploadProfilepic.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling teacherUploadProfilepic.');
            }
            const localVarPath = `/teacher/teacher_upload_profilepic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get teacher's teaching material of programs subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachingMaterialProgramsAndSubject: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/teaching_material_programsAndSubject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher's subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techerSubjects: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teacher/teacher_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update teacher profile
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacherProfile: async (body: Teacher, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTeacherProfile.');
            }
            const localVarPath = `/teacher/teacher_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update details of teacher timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacherSchedule: async (body: UserTimeslotHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTeacherSchedule.');
            }
            const localVarPath = `/teacher/teacher_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeacherApi - functional programming interface
 * @export
 */
export const TeacherApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get class info
         * @param {number} classMasterId 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _class(classMasterId: number, classType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration)._class(classMasterId, classType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher's class material
         * @param {string} [timeFilter] 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classMaterial(timeFilter?: string, classType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherClassMaterial>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).classMaterial(timeFilter, classType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to add details of teacher timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeacherSchedule(body: Array<UserTimeslot>, timezone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).createTeacherSchedule(body, timezone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get list of program master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListProgramMaster(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramMaster>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getListProgramMaster(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherBatchMaster(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTeacherBatchMaster(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherBatchMasterTerms(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTerm>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTeacherBatchMasterTerms(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherBatchMasterTimeslots(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTeacherBatchMasterTimeslots(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTeacherClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of teacher timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherSchedule(date: string, type: string, timezone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTeacherSchedule(date, type, timezone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get today's classes for teacher
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodayClasses(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).getTodayClasses(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list_program_subject
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramSubject(programId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubjectMaster>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).listProgramSubject(programId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markTeacherAttendance(classMasterId: number, time: string, eventType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).markTeacherAttendance(classMasterId, time, eventType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher's payment history
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentHistory(teacherId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeacherPayment>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).paymentHistory(teacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher's regular classes
         * @param {number} teacherId 
         * @param {string} [timeFilter] 
         * @param {string} [searchQuery] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherBatchClassesClasses(teacherId: number, timeFilter?: string, searchQuery?: string, subjects?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherBatchClasses>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherBatchClassesClasses(teacherId, timeFilter, searchQuery, subjects, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher batches
         * @param {string} [date] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherBatches(date?: string, status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchMaster>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherBatches(date, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherClasses(classType, date, fromDate, toDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete a teacher's subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherTeacherSubjectDelete(teacherSubjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherTeacherSubjectDelete(teacherSubjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to post teacher's subject
         * @param {TeacherTeacherSubjectBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherTeacherSubjectPost(body: TeacherTeacherSubjectBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherSubject>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherTeacherSubjectPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherUploadProfilepic(profilePic: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teacherUploadProfilepic(profilePic, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get teacher's teaching material of programs subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teachingMaterialProgramsAndSubject(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherSubject>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).teachingMaterialProgramsAndSubject(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher's subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techerSubjects(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeacherSubject>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).techerSubjects(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update teacher profile
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeacherProfile(body: Teacher, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).updateTeacherProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update details of teacher timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeacherSchedule(body: UserTimeslotHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await TeacherApiAxiosParamCreator(configuration).updateTeacherSchedule(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TeacherApi - factory interface
 * @export
 */
export const TeacherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api get class info
         * @param {number} classMasterId 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _class(classMasterId: number, classType?: string, options?: any): AxiosPromise<ClassMaster> {
            return TeacherApiFp(configuration)._class(classMasterId, classType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher's class material
         * @param {string} [timeFilter] 
         * @param {string} [classType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classMaterial(timeFilter?: string, classType?: string, options?: any): AxiosPromise<TeacherClassMaterial> {
            return TeacherApiFp(configuration).classMaterial(timeFilter, classType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to add details of teacher timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherSchedule(body: Array<UserTimeslot>, timezone: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return TeacherApiFp(configuration).createTeacherSchedule(body, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get list of program master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListProgramMaster(options?: any): AxiosPromise<Array<ProgramMaster>> {
            return TeacherApiFp(configuration).getListProgramMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMaster(batchId: number, options?: any): AxiosPromise<BatchMaster> {
            return TeacherApiFp(configuration).getTeacherBatchMaster(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMasterTerms(batchId: number, options?: any): AxiosPromise<Array<BatchTerm>> {
            return TeacherApiFp(configuration).getTeacherBatchMasterTerms(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherBatchMasterTimeslots(batchId: number, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return TeacherApiFp(configuration).getTeacherBatchMasterTimeslots(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherClassMaster(classMasterId: number, options?: any): AxiosPromise<ClassMaster> {
            return TeacherApiFp(configuration).getTeacherClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of teacher timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherSchedule(date: string, type: string, timezone: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return TeacherApiFp(configuration).getTeacherSchedule(date, type, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get today's classes for teacher
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayClasses(type?: string, options?: any): AxiosPromise<Array<ClassMaster>> {
            return TeacherApiFp(configuration).getTodayClasses(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list_program_subject
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramSubject(programId: number, options?: any): AxiosPromise<Array<SubjectMaster>> {
            return TeacherApiFp(configuration).listProgramSubject(programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markTeacherAttendance(classMasterId: number, time: string, eventType: string, options?: any): AxiosPromise<void> {
            return TeacherApiFp(configuration).markTeacherAttendance(classMasterId, time, eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher's payment history
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHistory(teacherId: number, options?: any): AxiosPromise<Array<TeacherPayment>> {
            return TeacherApiFp(configuration).paymentHistory(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher's regular classes
         * @param {number} teacherId 
         * @param {string} [timeFilter] 
         * @param {string} [searchQuery] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherBatchClassesClasses(teacherId: number, timeFilter?: string, searchQuery?: string, subjects?: Array<string>, options?: any): AxiosPromise<TeacherBatchClasses> {
            return TeacherApiFp(configuration).teacherBatchClassesClasses(teacherId, timeFilter, searchQuery, subjects, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher batches
         * @param {string} [date] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherBatches(date?: string, status?: string, options?: any): AxiosPromise<Array<BatchMaster>> {
            return TeacherApiFp(configuration).teacherBatches(date, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<ClassMaster>> {
            return TeacherApiFp(configuration).teacherClasses(classType, date, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherProfile(options?: any): AxiosPromise<Teacher> {
            return TeacherApiFp(configuration).teacherProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete a teacher's subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTeacherSubjectDelete(teacherSubjectId: number, options?: any): AxiosPromise<void> {
            return TeacherApiFp(configuration).teacherTeacherSubjectDelete(teacherSubjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to post teacher's subject
         * @param {TeacherTeacherSubjectBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTeacherSubjectPost(body: TeacherTeacherSubjectBody, options?: any): AxiosPromise<TeacherSubject> {
            return TeacherApiFp(configuration).teacherTeacherSubjectPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUploadProfilepic(profilePic: string, email: string, options?: any): AxiosPromise<SignedUrl> {
            return TeacherApiFp(configuration).teacherUploadProfilepic(profilePic, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get teacher's teaching material of programs subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachingMaterialProgramsAndSubject(options?: any): AxiosPromise<TeacherSubject> {
            return TeacherApiFp(configuration).teachingMaterialProgramsAndSubject(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher's subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techerSubjects(options?: any): AxiosPromise<Array<TeacherSubject>> {
            return TeacherApiFp(configuration).techerSubjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update teacher profile
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacherProfile(body: Teacher, options?: any): AxiosPromise<Teacher> {
            return TeacherApiFp(configuration).updateTeacherProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update details of teacher timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacherSchedule(body: UserTimeslotHistory, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return TeacherApiFp(configuration).updateTeacherSchedule(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeacherApi - object-oriented interface
 * @export
 * @class TeacherApi
 * @extends {BaseAPI}
 */
export class TeacherApi extends BaseAPI {
    /**
     * 
     * @summary api get class info
     * @param {number} classMasterId 
     * @param {string} [classType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public _class(classMasterId: number, classType?: string, options?: any) {
        return TeacherApiFp(this.configuration)._class(classMasterId, classType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher's class material
     * @param {string} [timeFilter] 
     * @param {string} [classType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public classMaterial(timeFilter?: string, classType?: string, options?: any) {
        return TeacherApiFp(this.configuration).classMaterial(timeFilter, classType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to add details of teacher timeslot
     * @param {Array&lt;UserTimeslot&gt;} body 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public createTeacherSchedule(body: Array<UserTimeslot>, timezone: string, options?: any) {
        return TeacherApiFp(this.configuration).createTeacherSchedule(body, timezone, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get list of program master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getListProgramMaster(options?: any) {
        return TeacherApiFp(this.configuration).getListProgramMaster(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTeacherBatchMaster(batchId: number, options?: any) {
        return TeacherApiFp(this.configuration).getTeacherBatchMaster(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTeacherBatchMasterTerms(batchId: number, options?: any) {
        return TeacherApiFp(this.configuration).getTeacherBatchMasterTerms(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTeacherBatchMasterTimeslots(batchId: number, options?: any) {
        return TeacherApiFp(this.configuration).getTeacherBatchMasterTimeslots(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTeacherClassMaster(classMasterId: number, options?: any) {
        return TeacherApiFp(this.configuration).getTeacherClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of teacher timeslot
     * @param {string} date 
     * @param {string} type 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTeacherSchedule(date: string, type: string, timezone: string, options?: any) {
        return TeacherApiFp(this.configuration).getTeacherSchedule(date, type, timezone, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get today's classes for teacher
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public getTodayClasses(type?: string, options?: any) {
        return TeacherApiFp(this.configuration).getTodayClasses(type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list_program_subject
     * @param {number} programId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public listProgramSubject(programId: number, options?: any) {
        return TeacherApiFp(this.configuration).listProgramSubject(programId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {string} time 
     * @param {string} eventType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public markTeacherAttendance(classMasterId: number, time: string, eventType: string, options?: any) {
        return TeacherApiFp(this.configuration).markTeacherAttendance(classMasterId, time, eventType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher's payment history
     * @param {number} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public paymentHistory(teacherId: number, options?: any) {
        return TeacherApiFp(this.configuration).paymentHistory(teacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher's regular classes
     * @param {number} teacherId 
     * @param {string} [timeFilter] 
     * @param {string} [searchQuery] 
     * @param {Array&lt;string&gt;} [subjects] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherBatchClassesClasses(teacherId: number, timeFilter?: string, searchQuery?: string, subjects?: Array<string>, options?: any) {
        return TeacherApiFp(this.configuration).teacherBatchClassesClasses(teacherId, timeFilter, searchQuery, subjects, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher batches
     * @param {string} [date] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherBatches(date?: string, status?: string, options?: any) {
        return TeacherApiFp(this.configuration).teacherBatches(date, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher_classes
     * @param {string} [classType] 
     * @param {string} [date] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any) {
        return TeacherApiFp(this.configuration).teacherClasses(classType, date, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherProfile(options?: any) {
        return TeacherApiFp(this.configuration).teacherProfile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete a teacher's subject
     * @param {number} teacherSubjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherTeacherSubjectDelete(teacherSubjectId: number, options?: any) {
        return TeacherApiFp(this.configuration).teacherTeacherSubjectDelete(teacherSubjectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to post teacher's subject
     * @param {TeacherTeacherSubjectBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherTeacherSubjectPost(body: TeacherTeacherSubjectBody, options?: any) {
        return TeacherApiFp(this.configuration).teacherTeacherSubjectPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile pic upload for user
     * @summary upload profile pic of the User
     * @param {string} profilePic 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teacherUploadProfilepic(profilePic: string, email: string, options?: any) {
        return TeacherApiFp(this.configuration).teacherUploadProfilepic(profilePic, email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get teacher's teaching material of programs subjects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public teachingMaterialProgramsAndSubject(options?: any) {
        return TeacherApiFp(this.configuration).teachingMaterialProgramsAndSubject(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher's subjects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public techerSubjects(options?: any) {
        return TeacherApiFp(this.configuration).techerSubjects(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update teacher profile
     * @param {Teacher} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public updateTeacherProfile(body: Teacher, options?: any) {
        return TeacherApiFp(this.configuration).updateTeacherProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update details of teacher timeslot
     * @param {UserTimeslotHistory} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherApi
     */
    public updateTeacherSchedule(body: UserTimeslotHistory, options?: any) {
        return TeacherApiFp(this.configuration).updateTeacherSchedule(body, options).then((request) => request(this.axios, this.basePath));
    }
}
