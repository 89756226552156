/* eslint-disable import/no-anonymous-default-export */

import { ClassMgmtActions } from '../actions/index'
import { ActionType } from '../action-types/index'
import { ClassMaster } from '../../swagger/models'

interface ClassManagement {
  classMasterDetail?: ClassMaster
}

const initialState = {
  classMasterDetail: {},
}

const classMgmtreducer = (state: ClassManagement = initialState, action: ClassMgmtActions) => {
  switch (action.type) {
    case ActionType.GET_CLASS_MASTER:
      return {
        ...state,
        classMasterDetail: action.classMasterDetail,
      }
    case ActionType.ADD_CLASS:
      return {
        ...state,
        classMasterDetail: action.AddClassMasterDetail,
      }
    case ActionType.UPDATE_CLASS:
      return {
        ...state,
        classMasterDetail: action.updateClassMasterDetail,
      }
    case ActionType.UPDATE_DEMO_CLASS:
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          DemoClass: action.updateDemoClassDetail,
        },
      }
    case ActionType.UPDATE_BATCH_CLASS:
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          BatchClass: action.updateBatchClassDetail,
        },
      }
    case ActionType.ADD_CLASS_MASTER_DOCUMENT:
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          ClassMasterDocuments: [
            ...state.classMasterDetail?.ClassMasterDocuments,
            action.classMasterDocumentDetail,
          ],
        },
      }
    case ActionType.REMOVE_CLASS_MASTER_DOCUMENT:
      const updatedClassDocsArray = state.classMasterDetail?.ClassMasterDocuments
      updatedClassDocsArray.splice(action.classMasterDocumentIndex, 1)
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          ClassMasterDocuments: updatedClassDocsArray,
        },
      }
    case ActionType.ADD_CLASS_MASTER_STUDENTS:
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          BatchClass: {
            ...state.classMasterDetail?.BatchClass,
            StudentClasses: action.studentClasses,
          },
        },
      }
    case ActionType.ADD_CLASS_MASTER_LEADS:
      return {
        ...state,
        classMasterDetail: {
          ...state.classMasterDetail,
          DemoClass: {
            ...state.classMasterDetail?.DemoClass,
            DemoClassStudents: action.demoClassStudents,
          },
        },
      }
    default:
      return state
  }
}

export default classMgmtreducer
