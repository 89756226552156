import { Box, Button, Card, FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { ApiController } from '../../apiController'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import './auth.scss'
import { useDispatch } from 'react-redux'
import SnackbarHelper from '../common-module/snackbar-helper'
import { showErrorSnackbar, showSuccessSnackbar } from '../../state/action-creators/snackbarActions'
import { forgetPasswordValidationSchema } from '../common-module/validation'
import { useNavigate } from 'react-router-dom'

function ForgotPassword() {
  const dispatch = useDispatch()
  const [userEmail, setUserEmail] = useState(() => '')

  async function sendLink() {
    try {
      await ApiController.getInstance().openApi.forgotPassword(userEmail)
      dispatch(showSuccessSnackbar('Email sent successfully'))
    } catch (error: any) {
      dispatch(showErrorSnackbar(error?.response?.data?.message))
    }
  }

  const formOptions = { resolver: yupResolver(forgetPasswordValidationSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState
  const navigate = useNavigate()

  function onSubmit(data: any) {
    sendLink()
    return false
  }

  return (
    <React.Fragment>
      <SnackbarHelper />
      <Box className="auth" sx={{ backgroundImage: 'url(/images/login-bg.svg)' }}>
        <Grid
          container={true}
          spacing="2"
          alignItems="center"
          justifyContent="end"
          className="mvh-100"
        >
          <Grid item={true} md={6} lg={5} xl={4}>
            <Card sx={{ px: 3, py: 4, boxShadow: 3 }}>
              <div className="logo">
                <img src="/images/logo.png" alt="" />
              </div>
              <h1 className="text-center">
                Forgot Password
                <small className="d-block">Enter your email id to get password reset link.</small>
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth={true}>
                  <TextField
                    type="text"
                    error={!!errors.email}
                    value={userEmail}
                    {...register('email')}
                    fullWidth={true}
                    name="email"
                    id="email"
                    label="Email id"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    required={true}
                    onChange={(e) => {
                      setUserEmail(e.target.value)
                    }}
                  />
                  <FormHelperText className="error">{errors.email?.message}</FormHelperText>
                </FormControl>
                <div className="text-center">
                  <Button variant="contained" type="submit" className="btn btn-primary">
                    Send password reset link
                  </Button>
                  <Box className="d-block mt-2">
                    <Button variant="text" color="primary" onClick={() => navigate(-1)}>
                      Back to login
                    </Button>
                  </Box>
                </div>
              </form>
            </Card>
          </Grid>
          <Grid item={true} lg={1} />
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default ForgotPassword
