import React from 'react'
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import './App.scss'
import Logout from './component/auth-module/logout'
import Loader from './component/common-module/loader'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import variables from './variables.module.scss'
import 'remixicon/fonts/remixicon.css'
import ForgotPassword from './component/auth-module/forgot-password'
import ChangePassword from './component/auth-module/change-password'
import KidProfileContainer from './component/auth-module/student-sign-up/kid-profile-container'
import Login from './component/auth-module/student-login/login'
import { socket, SocketContext } from './context/socket'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
const Admin = React.lazy(() => import('./component/admin-module/admin-module'))
const Teacher = React.lazy(() => import('./component/teacher-module/teacher-module'))
const Student = React.lazy(() => import('./component/student-module/student-module'))
const OPS = React.lazy(() => import('./component/ops-module/ops-module'))
const RM = React.lazy(() => import('./component/rm-module/rm-module'))
const WHB = React.lazy(() => import('./component/where-by'))
const LeadDemo = React.lazy(() => import('./component/lead-demo-module/lead-demo-module'))

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'whereby-embed': any
    }
  }
}

const theme = createTheme({
  typography: {
    fontFamily: variables.fontFamily,
  },
  palette: {
    primary: {
      main: variables.primaryColor,
      light: variables.primaryLightColor,
    },
    secondary: {
      main: variables.secondaryColor,
    },
    success: {
      main: variables.successColor,
    },
    text: {
      primary: variables.primaryTextColor,
    },
    common: {
      white: variables.whiteColor,
    },
    error: {
      main: variables.errorColor,
    },
  },
})

function App() {
  moment.locale('en', {
    week: {
      dow: 8,
    },
  })
  moment.locale('en')
  const userUUID = localStorage.getItem('UUID')
  if (!userUUID) {
    localStorage.setItem('UUID', uuidv4())
  }
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/sign-up" element={<ParentSignUp />} /> */}
            <Route path="/sign-up" element={<KidProfileContainer />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password/:token" element={<ChangePassword />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/admin/*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Admin />
                </React.Suspense>
              }
            />
            <Route
              path="/student/*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Student />
                </React.Suspense>
              }
            />
            <Route
              path="/teacher/*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Teacher />
                </React.Suspense>
              }
            />
            <Route
              path="/ops"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OPS />
                </React.Suspense>
              }
            />
            <Route
              path="/whereby"
              element={
                <React.Suspense fallback={<Loader />}>
                  <WHB />
                </React.Suspense>
              }
            />
            <Route
              path="/rm"
              element={
                <React.Suspense fallback={<Loader />}>
                  <RM />
                </React.Suspense>
              }
            />
            <Route
              path="/demo/*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <LeadDemo />
                </React.Suspense>
              }
            />
            <Route path="*" element={<Navigate replace={true} to="login" />} />
          </Routes>
          <Outlet />
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
