/* eslint-disable import/no-anonymous-default-export */
import { StudentMgmtAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Student, UserTimeslot } from '../../swagger/models'

interface StudentManagement {
  getStudentDetail?: Student
  userTimeslots?: UserTimeslot[]
  actionType?: string
  shouldShowLoader: boolean
}

const initialState = {
  getStudentDetail: {},
  userTimeslots: [],
  shouldShowLoader: false,
}

const studentMgmtReducer = (state: StudentManagement = initialState, action: StudentMgmtAction) => {
  switch (action.type) {
    case ActionType.GET_STUDENT:
      return {
        ...state,
        getStudentDetail: action.studentDetail,
        actionType: 'GET',
      }
    case ActionType.UPDATE_STUDENT:
      return {
        ...state,
        getStudentDetail: action.studentDetail,
        actionType: 'UPDATE',
      }
    case ActionType.ADD_STUDENT:
      return {
        ...state,
        getStudentDetail: action.studentDetail,
        actionType: 'ADD',
      }
    case ActionType.ADD_STUDENT_TIMESLOT:
      return {
        ...state,
        actionType: 'UPDATE',
        userTimeslots: action.usertimeslot,
      }
    case ActionType.GET_STUDENT_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.ADD_STUDENT_PAYMENT:
      return {
        ...state,
        actionType: 'PAYMENT',
      }
    case ActionType.UPDATE_STUDENT_PAYMENT:
      return {
        ...state,
        actionType: 'PAYMENT',
      }
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        shouldShowLoader: action.shouldShowLoader,
      }
    default:
      return state
  }
}

export default studentMgmtReducer
