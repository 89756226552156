/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Lead } from '../models';
import { LoginPhoneRequest } from '../models';
import { LoginPhoneRequest1 } from '../models';
import { LoginPhoneRequest2 } from '../models';
import { LoginRequest } from '../models';
import { LoginRequest1 } from '../models';
import { LoginRequest2 } from '../models';
import { LoginRequest3 } from '../models';
import { User } from '../models';
/**
 * OpenApi - axios parameter creator
 * @export
 */
export const OpenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch the user email address using uuid
         * @summary change user's password link
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTokenEmail: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling fetchTokenEmail.');
            }
            const localVarPath = `/open/fetch_token_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary send forgot password link to the user.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/open/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Lead via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadPhoneLogin: async (body: LoginPhoneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling leadPhoneLogin.');
            }
            const localVarPath = `/open/lead_phone_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/open/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password after clicking on link
         * @summary change user's password
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (body: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/open/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * send_otp
         * @summary Login API for Teacher
         * @param {number} phone 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp: async (phone: number, countryCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            if (phone === null || phone === undefined) {
                throw new RequiredError('phone','Required parameter phone was null or undefined when calling sendOtp.');
            }
            // verify required parameter 'countryCode' is not null or undefined
            if (countryCode === null || countryCode === undefined) {
                throw new RequiredError('countryCode','Required parameter countryCode was null or undefined when calling sendOtp.');
            }
            const localVarPath = `/open/send_otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Student via email
         * @summary Login API for student
         * @param {LoginRequest3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentEmailLogin: async (body: LoginRequest3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling studentEmailLogin.');
            }
            const localVarPath = `/open/student_email_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Student via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentPhoneLogin: async (body: LoginPhoneRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling studentPhoneLogin.');
            }
            const localVarPath = `/open/student_phone_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Teacher
         * @summary Login API for teacher
         * @param {LoginRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherLogin: async (body: LoginRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling teacherLogin.');
            }
            const localVarPath = `/open/teacher_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Teacher via phone
         * @summary Login API for Teacher
         * @param {LoginPhoneRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPhoneLogin: async (body: LoginPhoneRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling teacherPhoneLogin.');
            }
            const localVarPath = `/open/teacher_phone_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenApi - functional programming interface
 * @export
 */
export const OpenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Fetch the user email address using uuid
         * @summary change user's password link
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTokenEmail(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).fetchTokenEmail(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary send forgot password link to the user.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).forgotPassword(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Lead via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadPhoneLogin(body: LoginPhoneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).leadPhoneLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).login(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Reset password after clicking on link
         * @summary change user's password
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(body: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).resetPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * send_otp
         * @summary Login API for Teacher
         * @param {number} phone 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOtp(phone: number, countryCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).sendOtp(phone, countryCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Student via email
         * @summary Login API for student
         * @param {LoginRequest3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentEmailLogin(body: LoginRequest3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).studentEmailLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Student via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentPhoneLogin(body: LoginPhoneRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).studentPhoneLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Teacher
         * @summary Login API for teacher
         * @param {LoginRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherLogin(body: LoginRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).teacherLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Teacher via phone
         * @summary Login API for Teacher
         * @param {LoginPhoneRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherPhoneLogin(body: LoginPhoneRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).teacherPhoneLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpenApi - factory interface
 * @export
 */
export const OpenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Fetch the user email address using uuid
         * @summary change user's password link
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTokenEmail(token: string, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).fetchTokenEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Send forgot password link to the user.
         * @summary send forgot password link to the user.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(email: string, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).forgotPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Lead via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadPhoneLogin(body: LoginPhoneRequest, options?: any): AxiosPromise<Lead> {
            return OpenApiFp(configuration).leadPhoneLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginRequest1, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).login(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password after clicking on link
         * @summary change user's password
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body: LoginRequest, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).resetPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * send_otp
         * @summary Login API for Teacher
         * @param {number} phone 
         * @param {string} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp(phone: number, countryCode: string, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).sendOtp(phone, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Student via email
         * @summary Login API for student
         * @param {LoginRequest3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentEmailLogin(body: LoginRequest3, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).studentEmailLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Student via phone
         * @summary Login API for student
         * @param {LoginPhoneRequest1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentPhoneLogin(body: LoginPhoneRequest1, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).studentPhoneLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Teacher
         * @summary Login API for teacher
         * @param {LoginRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherLogin(body: LoginRequest2, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).teacherLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Teacher via phone
         * @summary Login API for Teacher
         * @param {LoginPhoneRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPhoneLogin(body: LoginPhoneRequest2, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).teacherPhoneLogin(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenApi - object-oriented interface
 * @export
 * @class OpenApi
 * @extends {BaseAPI}
 */
export class OpenApi extends BaseAPI {
    /**
     * Fetch the user email address using uuid
     * @summary change user's password link
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public fetchTokenEmail(token: string, options?: any) {
        return OpenApiFp(this.configuration).fetchTokenEmail(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send forgot password link to the user.
     * @summary send forgot password link to the user.
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public forgotPassword(email: string, options?: any) {
        return OpenApiFp(this.configuration).forgotPassword(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Lead via phone
     * @summary Login API for student
     * @param {LoginPhoneRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public leadPhoneLogin(body: LoginPhoneRequest, options?: any) {
        return OpenApiFp(this.configuration).leadPhoneLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Admin
     * @summary Login API for user
     * @param {LoginRequest1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public login(body: LoginRequest1, options?: any) {
        return OpenApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Reset password after clicking on link
     * @summary change user's password
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public resetPassword(body: LoginRequest, options?: any) {
        return OpenApiFp(this.configuration).resetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * send_otp
     * @summary Login API for Teacher
     * @param {number} phone 
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public sendOtp(phone: number, countryCode: string, options?: any) {
        return OpenApiFp(this.configuration).sendOtp(phone, countryCode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Student via email
     * @summary Login API for student
     * @param {LoginRequest3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public studentEmailLogin(body: LoginRequest3, options?: any) {
        return OpenApiFp(this.configuration).studentEmailLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Student via phone
     * @summary Login API for student
     * @param {LoginPhoneRequest1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public studentPhoneLogin(body: LoginPhoneRequest1, options?: any) {
        return OpenApiFp(this.configuration).studentPhoneLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Teacher
     * @summary Login API for teacher
     * @param {LoginRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public teacherLogin(body: LoginRequest2, options?: any) {
        return OpenApiFp(this.configuration).teacherLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Teacher via phone
     * @summary Login API for Teacher
     * @param {LoginPhoneRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public teacherPhoneLogin(body: LoginPhoneRequest2, options?: any) {
        return OpenApiFp(this.configuration).teacherPhoneLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
}
