import { combineReducers } from 'redux'
import batchMgmtReducer from './listBatchReducer'
import teacherMgmtReducer from './teacherMgmtReducer'
import teacherPortalReducer from './teacherPortalReducer'
import studentPortalReducer from './studentPortalReducer'
import authReducer from './authReducer'
import snackberReducer from './snackbarReducer'
import projectMgmt from './programMgmtReducer'
import studentMgmtReducer from './studentMgmtReducer'
import userMgmtReducer from './userMgmtReducer'
import classMgmtReducer from './classMgmtReducer'

const reducers = combineReducers({
  batchMgmtReducer,
  teacherMgmt: teacherMgmtReducer,
  teacherPortal: teacherPortalReducer,
  studentPortal: studentPortalReducer,
  userLogin: authReducer,
  snackberReducer,
  projectMgmt,
  studentMgmtReducer,
  userMgmtReducer,
  classMgmtReducer,
})

export default reducers

export type State = ReturnType<typeof reducers>
