/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Notification } from '../models';
import { SignedUrl } from '../models';
import { UpdatePassword } from '../models';
import { User } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get_profile api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/get_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Api to get all the notifications related to user
         * @summary change user's password link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove profile pic of the User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfilePic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove_profile_pic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the password of logged in user
         * @summary change user's password link
         * @param {UpdatePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (body: UpdatePassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePassword.');
            }
            const localVarPath = `/auth/update_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the profile of logged in user
         * @summary change user's password link
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProfile.');
            }
            const localVarPath = `/auth/update_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilepic: async (profilePic: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling uploadProfilepic.');
            }
            const localVarPath = `/auth/upload_profilepic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get_profile api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Api to get all the notifications related to user
         * @summary change user's password link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).notifications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary remove profile pic of the User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProfilePic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).removeProfilePic(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the password of logged in user
         * @summary change user's password link
         * @param {UpdatePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(body: UpdatePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updatePassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the profile of logged in user
         * @summary change user's password link
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updateProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilepic(profilePic: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).uploadProfilepic(profilePic, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get_profile api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Api to get all the notifications related to user
         * @summary change user's password link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifications(options?: any): AxiosPromise<Array<Notification>> {
            return AuthApiFp(configuration).notifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove profile pic of the User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfilePic(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).removeProfilePic(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the password of logged in user
         * @summary change user's password link
         * @param {UpdatePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(body: UpdatePassword, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).updatePassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the profile of logged in user
         * @summary change user's password link
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(body: User, options?: any): AxiosPromise<User> {
            return AuthApiFp(configuration).updateProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilepic(profilePic: string, options?: any): AxiosPromise<SignedUrl> {
            return AuthApiFp(configuration).uploadProfilepic(profilePic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary get_profile api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getProfile(options?: any) {
        return AuthApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Api to get all the notifications related to user
     * @summary change user's password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public notifications(options?: any) {
        return AuthApiFp(this.configuration).notifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary remove profile pic of the User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public removeProfilePic(options?: any) {
        return AuthApiFp(this.configuration).removeProfilePic(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the password of logged in user
     * @summary change user's password link
     * @param {UpdatePassword} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updatePassword(body: UpdatePassword, options?: any) {
        return AuthApiFp(this.configuration).updatePassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the profile of logged in user
     * @summary change user's password link
     * @param {User} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateProfile(body: User, options?: any) {
        return AuthApiFp(this.configuration).updateProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile pic upload for user
     * @summary upload profile pic of the User
     * @param {string} profilePic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public uploadProfilepic(profilePic: string, options?: any) {
        return AuthApiFp(this.configuration).uploadProfilepic(profilePic, options).then((request) => request(this.axios, this.basePath));
    }
}
