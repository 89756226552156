export enum ActionType {
  TEACHER_PROFILE = 'TEACHER_PROFILE',
  UPDATE_TEACHER_PROFILE = 'UPDATE_TEACHER_PROFILE',
  GET_TEACHER_PROFILE = 'GET_TEACHER_PROFILE',
  LIST_TEACHER_SUBJECTS = 'LIST_TEACHER_SUBJECTS',
  FETCH_LIST_BATCH_MASTER = 'FETCH_LIST_BATCH_MASTER',
  GET_BATCH_MASTER = 'GET_BATCH_MASTER',
  ADD_BATCH_MASTER = 'ADD_BATCH_MASTER',
  UPDATE_BATCH_MASTER = 'UPDATE_BATCH_MASTER',
  LOGIN = 'LOGIN',
  FETCH_LIST_TEACHER = 'FETCH_LIST_TEACHER',
  ADD_TEACHER = 'ADD_TEACHER',
  ADD_TEACHER_SUBJECT = 'ADD_TEACHER_SUBJECT',
  REMOVE_TEACHER_SUBJECT = 'REMOVE_TEACHER_SUBJECT',
  UPDATE_TEACHER = 'UPDATE_TEACHER',
  GET_TEACHER = 'GET_TEACHER',
  GET_TERM = 'GET_TERM',
  GET_TERM_LIST = 'GET_TERM_LIST',
  UPDATE_TERM = 'UPDATE_TERM',
  ADD_TERM = 'ADD_TERM',
  UPDATE_TERM_SUBJECT = 'UPDATE_TERM_SUBJECT',
  UPDATE_TERM_CLASS = 'UPDATE_TERM_CLASS',
  REMOVE_TERM_CLASS_CHAPTER = 'REMOVE_TERM_CLASS_CHAPTER',
  ADD_TERM_CLASS_DOCUMENT = 'ADD_TERM_CLASS_DOCUMENT',
  REMOVE_TERM_CLASS_DOCUMENT = 'REMOVE_TERM_CLASS_DOCUMENT',
  REMOVE_TERM_CLASS = 'REMOVE_TERM_CLASS',
  ADD_STUDENT = 'ADD_STUDENT',
  UPDATE_STUDENT = 'UPDATE_STUDENT',
  GET_STUDENT = 'GET_STUDENT',
  ADD_STUDENT_PAYMENT = 'ADD_STUDENT_PAYMENT',
  UPDATE_STUDENT_PAYMENT = 'UPDATE_STUDENT_PAYMENT',
  GET_TIMESLOT = 'GET_TIMESLOT',
  ADD_TIMESLOT = 'ADD_TIMESLOT',
  GET_TEACHER_TIMESLOT = 'GET_TEACHER_TIMESLOT',
  GET_STUDENT_TIMESLOT = 'GET_STUDENT_TIMESLOT',
  ADD_TEACHER_TIMESLOT = 'ADD_TEACHER_TIMESLOT',
  ADD_STUDENT_TIMESLOT = 'ADD_STUDENT_TIMESLOT',
  UPDATE_TIMESLOT = 'UPDATE_TIMESLOT',
  UPDATE_TEACHER_TIMESLOT = 'UPDATE_TEACHER_TIMESLOT',
  GET_STUDENT_PROFILE = 'GET_STUDENT_PROFILE',
  UPDATE_STUDENT_PROFILE = 'UPDATE_STUDENT_PROFILE',
  GET_CLASS_MASTER = 'GET_CLASS_MASTER',
  ADD_CLASS = 'ADD_CLASS',
  UPDATE_CLASS = 'UPDATE_CLASS',
  ADD_CLASS_TEACHER = 'ADD_CLASS_TEACHER',
  ADD_DEMOCLASS_LEAD = 'ADD_DEMOCLASS_LEAD',
  UPDATE_DEMO_CLASS = 'UPDATE_DEMO_CLASS',
  UPDATE_BATCH_CLASS = 'UPDATE_BATCH_CLASS',
  ADD_CLASS_MASTER_DOCUMENT = 'ADD_CLASS_MASTER_DOCUMENT',
  REMOVE_CLASS_MASTER_DOCUMENT = 'REMOVE_CLASS_MASTER_DOCUMENT',
  ADD_CLASS_MASTER_STUDENTS = 'ADD_CLASS_MASTER_STUDENTS',
  ADD_CLASS_MASTER_LEADS = 'ADD_CLASS_MASTER_LEADS',
  ASSIGN_BATCH_STUDENTS = 'ASSIGN_BATCH_STUDENTS',
  ASSIGN_BATCH_SUBJECT_TEACHER = 'ASSIGN_BATCH_SUBJECT_TEACHER',
  ASSIGN_BATCH_TERM_STUDENT = 'ASSIGN_BATCH_TERM_STUDENT',
  SCHEDULE_BATCH_TIMESLOTS = 'SCHEDULE_BATCH_TIMESLOTS',
  DELETE_BATCH_TIMESLOTS = 'DELETE_BATCH_TIMESLOTS',
  DELETE_SINGLE_BATCH_TIMESLOTS = 'DELETE_SINGLE_BATCH_TIMESLOTS',
  BATCH_PUBLISHED = 'BATCH_PUBLISHED',
  SHOW_LOADER = 'SHOW_LOADER',
  GET_PROGRAM_GROUP_LIST = 'GET_PROGRAM_GROUP_LIST',
  ADD_PROGRAM_GROUP = 'ADD_PROGRAM_GROUP',
  UPDATE_PROGRAM_GROUP = 'UPDATE_PROGRAM_GROUP',
  DELETE_BATCH_TERM = 'DELETE_BATCH_TERM',
  DELETE_BATCH_TERM_SUBJECT = 'DELETE_BATCH_TERM_SUBJECT',
  ADD_BATCH_TERM = 'ADD_BATCH_TERM',
}

export enum SnackbarActionType {
  SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS',
  SNACKBAR_FAILURE = 'SNACKBAR_FAILURE',
  SNACKBAR_CLEAR = 'SNACKBAR_CLEAR',
}
