import { ListBatchesParams } from './../../swagger/models/list-batches-params'
import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { BatchMaster, BatchStudent, BatchSubject, BatchTimeslot } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { BatchMgmtActions, snackbarAction } from '../actions'

export const fetchListBatchMaster = (listBatchesParams: ListBatchesParams) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      const batches = await (
        await ApiController.getInstance().batchMgmtApi.listBatches(listBatchesParams)
      ).data
      dispatch({
        type: ActionType.FETCH_LIST_BATCH_MASTER,
        batches: batches.data,
        countOfBactches: batches.recordsFiltered,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addBatchMaster = (batchMaster: BatchMaster) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const batch = await (
        await ApiController.getInstance().batchMgmtApi.createBatchMaster(batchMaster)
      ).data
      dispatch({
        type: ActionType.ADD_BATCH_MASTER,
        batch,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Batch details added successfully!',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateBatchMaster = (batchMaster: BatchMaster) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const batch = await (
        await ApiController.getInstance().batchMgmtApi.updateBatchMaster(batchMaster)
      ).data
      dispatch({
        type: ActionType.UPDATE_BATCH_MASTER,
        updateBatch: batch,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Batch details updated successfully!',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getBatchMaster = (batchId: number) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const batch = await (
        await ApiController.getInstance().batchMgmtApi.getBatchMaster(batchId)
      ).data
      const batchTerms = await (
        await ApiController.getInstance().batchMgmtApi.getBatchMasterTerms(batchId)
      ).data
      const batchTimeslots = await (
        await ApiController.getInstance().batchMgmtApi.getBatchMasterTimeslots(batchId)
      ).data
      batch.BatchTimeslots = batchTimeslots
      batch.BatchTerms = batchTerms
      dispatch({
        type: ActionType.GET_BATCH_MASTER,
        batch,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const AssignBatchSubjetTeachers = (
  batchTermindex: number,
  batchTermId: number,
  batchSubjects: BatchSubject[],
  classTeacherId?: number
) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const updatedBatchSubjects = await (
        await ApiController.getInstance().batchMgmtApi.assignBatchSubjectTeacher(
          batchSubjects,
          batchTermId,
          classTeacherId
        )
      ).data
      dispatch({
        type: ActionType.ASSIGN_BATCH_SUBJECT_TEACHER,
        BatchSubjects: updatedBatchSubjects,
        batchTermindex,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Teachers assigned successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const AssignBatchTermStundets = (
  batchTermindex: number,
  batchTermId: number,
  batchStudents: BatchStudent[]
) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const updatedBatchStudents = await (
        await ApiController.getInstance().batchMgmtApi.assignBatchTermStudent(
          batchStudents,
          batchTermId
        )
      ).data
      dispatch({
        type: ActionType.ASSIGN_BATCH_TERM_STUDENT,
        BatchStudents: updatedBatchStudents,
        batchTermindex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Students assigned successfully!',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const publishBatch = (batchId: number) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().batchMgmtApi.createStudentClasses(batchId)
      ).data
      const batchStatus = await (
        await ApiController.getInstance().batchMgmtApi.publishBatch(batchId)
      ).status
      if (batchStatus && batchStatus === 200) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Batch is published successfully!',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: ActionType.BATCH_PUBLISHED,
        })
      }
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const scheduleTimeslotsApi = (
  batchTimeslots: BatchTimeslot[],
  batchTermId: number,
  startDate: string,
  batchTermIndex: number
) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslots = await (
        await ApiController.getInstance().batchMgmtApi.scheduleBatchClass(
          batchTimeslots,
          batchTermId,
          startDate
        )
      ).data
      dispatch({
        type: ActionType.SCHEDULE_BATCH_TIMESLOTS,
        BatchTimeslots: timeslots,
        startDate,
        batchTermIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Schedule added successfully!',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete all the timeslots of specific batch term subject */
export const deleteSubjectTimeslotsApi = (
  batchTermId: number,
  batchSubjectId: number,
  batchTermIndex: number,
  allFuture: boolean,
  batchTimeslotId?: number
) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslots = await (
        await ApiController.getInstance().batchMgmtApi.subejctBatchTimeslots(
          batchTermId,
          batchSubjectId,
          allFuture,
          batchTimeslotId
        )
      ).data
      dispatch({
        type: ActionType.DELETE_BATCH_TIMESLOTS,
        BatchTimeslots: timeslots,
        batchSubjectId,
        batchTermIndex,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'All schedule removed successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteSingleSubjectTimeslotsApi = (batchTimeslotId: number) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslots = await (
        await ApiController.getInstance().batchMgmtApi.deleteBatchTimeslot(batchTimeslotId)
      ).data
      dispatch({
        type: ActionType.DELETE_SINGLE_BATCH_TIMESLOTS,
        BatchTimeslots: timeslots,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Schedule removed successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the subject of specific batch term subject */
export const deleteBatchTermSubjectApi = (
  batchSubjectId: number,
  batchTermIndex: number,
  batchTermSubjectIndex: number
) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      const timeslots = await (
        await ApiController.getInstance().batchMgmtApi.deleteBatchTermSubject(batchSubjectId)
      ).data
      dispatch({
        type: ActionType.DELETE_BATCH_TERM_SUBJECT,
        batchSubjectId,
        batchTermIndex,
        batchTermSubjectIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Subject removed successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the term of specific batch and remove all the subjects from it */
export const deleteBatchTermApi = (batchTermId: number, batchTermIndex: number) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      const timeslots = await (
        await ApiController.getInstance().batchMgmtApi.deleteBatchTerm(batchTermId)
      ).data
      dispatch({
        type: ActionType.DELETE_BATCH_TERM,
        batchTermId,
        batchTermIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Batch Term removed successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to Add the term of specific batch and add all the subjects inside it */
export const addBatchTermApi = (batchId: number, programId: number) => {
  return async (dispatch: Dispatch<BatchMgmtActions | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const batchTerm = await (
        await ApiController.getInstance().batchMgmtApi.createBatchMasterTerm(programId, batchId)
      ).data
      dispatch({
        type: ActionType.ADD_BATCH_TERM,
        batchTerm,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Batch Term added successfully!',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
