/* eslint-disable import/no-anonymous-default-export */

import { UserMgmtAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { UserTimeslotHistory } from '../../swagger/models'

interface TeacherManagement {
  userTimeslotHistory?: UserTimeslotHistory
}

const initialState = {
  userTimeslotHistory: {},
}

const userMgmtReducer = (state: TeacherManagement = initialState, action: UserMgmtAction) => {
  switch (action.type) {
    case ActionType.ADD_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    case ActionType.UPDATE_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    case ActionType.GET_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    default:
      return state
  }
}

export default userMgmtReducer
