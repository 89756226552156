/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ListClassesOutput } from '../models';
import { ListClassesParams } from '../models';
import { ListStudentOutput } from '../models';
import { ListStudentPaymentOutput } from '../models';
import { ListStudentPaymentsParams } from '../models';
import { ListStudentsParams } from '../models';
import { Student } from '../models';
import { StudentPayment } from '../models';
import { UserTimeslot } from '../models';
/**
 * StudentMgmtApi - axios parameter creator
 * @export
 */
export const StudentMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStudentProfile: async (studentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling activateStudentProfile.');
            }
            const localVarPath = `/admin/student-management/activate_student_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update the class credits of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClassCredit: async (body: StudentPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addClassCredit.');
            }
            const localVarPath = `/admin/student-management/add_class_credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudent: async (body: Student, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStudent.');
            }
            const localVarPath = `/admin/student-management/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentPayment: async (body: StudentPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStudentPayment.');
            }
            const localVarPath = `/admin/student-management/student_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api post student_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentTimeslot: async (body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStudentTimeslot.');
            }
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling createStudentTimeslot.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling createStudentTimeslot.');
            }
            const localVarPath = `/admin/student-management/student_timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (isRepeatWeeklyScheduleOn !== undefined) {
                localVarQueryParameter['isRepeatWeeklyScheduleOn'] = isRepeatWeeklyScheduleOn;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateStudentProfile: async (studentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling deactivateStudentProfile.');
            }
            const localVarPath = `/admin/student-management/deactivate_student_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete the details of student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudent: async (studentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling deleteStudent.');
            }
            const localVarPath = `/admin/student-management/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to fetch existing student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStudent: async (studentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling fetchStudent.');
            }
            const localVarPath = `/admin/student-management/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get student payment details
         * @param {number} stuPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentPayment: async (stuPaymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stuPaymentId' is not null or undefined
            if (stuPaymentId === null || stuPaymentId === undefined) {
                throw new RequiredError('stuPaymentId','Required parameter stuPaymentId was null or undefined when calling getStudentPayment.');
            }
            const localVarPath = `/admin/student-management/student_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (stuPaymentId !== undefined) {
                localVarQueryParameter['stuPaymentId'] = stuPaymentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentTimeslot: async (uId: number, date: string, timezone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling getStudentTimeslot.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getStudentTimeslot.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling getStudentTimeslot.');
            }
            const localVarPath = `/admin/student-management/student_timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the student payments
         * @param {ListStudentPaymentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentPayment: async (body: ListStudentPaymentsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listStudentPayment.');
            }
            const localVarPath = `/admin/student-management/list_student_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the students
         * @param {ListStudentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentPost: async (body: ListStudentsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listStudentPost.');
            }
            const localVarPath = `/admin/student-management/list_student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student_classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentClassesList: async (body: ListClassesParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling studentClassesList.');
            }
            const localVarPath = `/admin/student-management/student_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to resend invitation to student
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentResendInvitation: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling studentResendInvitation.');
            }
            const localVarPath = `/admin/student-management/resend_invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update the details of student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudent: async (body: Student, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStudent.');
            }
            const localVarPath = `/admin/student-management/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update the details of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentPayment: async (body: StudentPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStudentPayment.');
            }
            const localVarPath = `/admin/student-management/student_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentMgmtApi - functional programming interface
 * @export
 */
export const StudentMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateStudentProfile(studentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).activateStudentProfile(studentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update the class credits of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClassCredit(body: StudentPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentPayment>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).addClassCredit(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudent(body: Student, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).createStudent(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentPayment(body: StudentPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentPayment>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).createStudentPayment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api post student_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).createStudentTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateStudentProfile(studentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).deactivateStudentProfile(studentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete the details of student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStudent(studentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).deleteStudent(studentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to fetch existing student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchStudent(studentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).fetchStudent(studentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get student payment details
         * @param {number} stuPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentPayment(stuPaymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentPayment>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).getStudentPayment(stuPaymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentTimeslot(uId: number, date: string, timezone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).getStudentTimeslot(uId, date, timezone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the student payments
         * @param {ListStudentPaymentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudentPayment(body: ListStudentPaymentsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudentPaymentOutput>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).listStudentPayment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the students
         * @param {ListStudentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudentPost(body: ListStudentsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudentOutput>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).listStudentPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student_classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentClassesList(body: ListClassesParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClassesOutput>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).studentClassesList(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to resend invitation to student
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentResendInvitation(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).studentResendInvitation(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update the details of student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudent(body: Student, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).updateStudent(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update the details of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentPayment(body: StudentPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentPayment>> {
            const localVarAxiosArgs = await StudentMgmtApiAxiosParamCreator(configuration).updateStudentPayment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentMgmtApi - factory interface
 * @export
 */
export const StudentMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStudentProfile(studentId: number, options?: any): AxiosPromise<Student> {
            return StudentMgmtApiFp(configuration).activateStudentProfile(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update the class credits of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClassCredit(body: StudentPayment, options?: any): AxiosPromise<StudentPayment> {
            return StudentMgmtApiFp(configuration).addClassCredit(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudent(body: Student, options?: any): AxiosPromise<Student> {
            return StudentMgmtApiFp(configuration).createStudent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentPayment(body: StudentPayment, options?: any): AxiosPromise<StudentPayment> {
            return StudentMgmtApiFp(configuration).createStudentPayment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api post student_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return StudentMgmtApiFp(configuration).createStudentTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to activate student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateStudentProfile(studentId: number, options?: any): AxiosPromise<Student> {
            return StudentMgmtApiFp(configuration).deactivateStudentProfile(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete the details of student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudent(studentId: number, options?: any): AxiosPromise<void> {
            return StudentMgmtApiFp(configuration).deleteStudent(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to fetch existing student
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStudent(studentId: number, options?: any): AxiosPromise<Student> {
            return StudentMgmtApiFp(configuration).fetchStudent(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get student payment details
         * @param {number} stuPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentPayment(stuPaymentId: number, options?: any): AxiosPromise<StudentPayment> {
            return StudentMgmtApiFp(configuration).getStudentPayment(stuPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentTimeslot(uId: number, date: string, timezone: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return StudentMgmtApiFp(configuration).getStudentTimeslot(uId, date, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the student payments
         * @param {ListStudentPaymentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentPayment(body: ListStudentPaymentsParams, options?: any): AxiosPromise<ListStudentPaymentOutput> {
            return StudentMgmtApiFp(configuration).listStudentPayment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the students
         * @param {ListStudentsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentPost(body: ListStudentsParams, options?: any): AxiosPromise<ListStudentOutput> {
            return StudentMgmtApiFp(configuration).listStudentPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student_classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentClassesList(body: ListClassesParams, options?: any): AxiosPromise<ListClassesOutput> {
            return StudentMgmtApiFp(configuration).studentClassesList(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to resend invitation to student
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentResendInvitation(email: string, options?: any): AxiosPromise<void> {
            return StudentMgmtApiFp(configuration).studentResendInvitation(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update the details of student
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudent(body: Student, options?: any): AxiosPromise<Student> {
            return StudentMgmtApiFp(configuration).updateStudent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update the details of student payment
         * @param {StudentPayment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentPayment(body: StudentPayment, options?: any): AxiosPromise<StudentPayment> {
            return StudentMgmtApiFp(configuration).updateStudentPayment(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentMgmtApi - object-oriented interface
 * @export
 * @class StudentMgmtApi
 * @extends {BaseAPI}
 */
export class StudentMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api to activate student
     * @param {number} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public activateStudentProfile(studentId: number, options?: any) {
        return StudentMgmtApiFp(this.configuration).activateStudentProfile(studentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update the class credits of student payment
     * @param {StudentPayment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public addClassCredit(body: StudentPayment, options?: any) {
        return StudentMgmtApiFp(this.configuration).addClassCredit(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new student
     * @param {Student} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public createStudent(body: Student, options?: any) {
        return StudentMgmtApiFp(this.configuration).createStudent(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new student payment
     * @param {StudentPayment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public createStudentPayment(body: StudentPayment, options?: any) {
        return StudentMgmtApiFp(this.configuration).createStudentPayment(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api post student_timeslot
     * @param {Array&lt;UserTimeslot&gt;} body 
     * @param {number} uId 
     * @param {string} timezone 
     * @param {boolean} [isRepeatWeeklyScheduleOn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public createStudentTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any) {
        return StudentMgmtApiFp(this.configuration).createStudentTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to activate student
     * @param {number} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public deactivateStudentProfile(studentId: number, options?: any) {
        return StudentMgmtApiFp(this.configuration).deactivateStudentProfile(studentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete the details of student
     * @param {number} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public deleteStudent(studentId: number, options?: any) {
        return StudentMgmtApiFp(this.configuration).deleteStudent(studentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to fetch existing student
     * @param {number} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public fetchStudent(studentId: number, options?: any) {
        return StudentMgmtApiFp(this.configuration).fetchStudent(studentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get student payment details
     * @param {number} stuPaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public getStudentPayment(stuPaymentId: number, options?: any) {
        return StudentMgmtApiFp(this.configuration).getStudentPayment(stuPaymentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student_timeslot
     * @param {number} uId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public getStudentTimeslot(uId: number, date: string, timezone: string, options?: any) {
        return StudentMgmtApiFp(this.configuration).getStudentTimeslot(uId, date, timezone, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the student payments
     * @param {ListStudentPaymentsParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public listStudentPayment(body: ListStudentPaymentsParams, options?: any) {
        return StudentMgmtApiFp(this.configuration).listStudentPayment(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the students
     * @param {ListStudentsParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public listStudentPost(body: ListStudentsParams, options?: any) {
        return StudentMgmtApiFp(this.configuration).listStudentPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student_classes
     * @param {ListClassesParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public studentClassesList(body: ListClassesParams, options?: any) {
        return StudentMgmtApiFp(this.configuration).studentClassesList(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to resend invitation to student
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public studentResendInvitation(email: string, options?: any) {
        return StudentMgmtApiFp(this.configuration).studentResendInvitation(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update the details of student
     * @param {Student} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public updateStudent(body: Student, options?: any) {
        return StudentMgmtApiFp(this.configuration).updateStudent(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update the details of student payment
     * @param {StudentPayment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentMgmtApi
     */
    public updateStudentPayment(body: StudentPayment, options?: any) {
        return StudentMgmtApiFp(this.configuration).updateStudentPayment(body, options).then((request) => request(this.axios, this.basePath));
    }
}
