/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BatchMaster } from '../models';
import { BatchStudent } from '../models';
import { BatchSubject } from '../models';
import { BatchTerm } from '../models';
import { BatchTimeslot } from '../models';
import { ListBatchesOutput } from '../models';
import { ListBatchesParams } from '../models';
import { Student } from '../models';
import { Teacher } from '../models';
import { UserTimeslot } from '../models';
/**
 * BatchMgmtApi - axios parameter creator
 * @export
 */
export const BatchMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchSubject&gt;} body 
         * @param {number} batchTermId 
         * @param {number} [classTeacherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignBatchSubjectTeacher: async (body: Array<BatchSubject>, batchTermId: number, classTeacherId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling assignBatchSubjectTeacher.');
            }
            // verify required parameter 'batchTermId' is not null or undefined
            if (batchTermId === null || batchTermId === undefined) {
                throw new RequiredError('batchTermId','Required parameter batchTermId was null or undefined when calling assignBatchSubjectTeacher.');
            }
            const localVarPath = `/admin/batch-management/assign_batch_subject_teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTermId !== undefined) {
                localVarQueryParameter['batchTermId'] = batchTermId;
            }

            if (classTeacherId !== undefined) {
                localVarQueryParameter['classTeacherId'] = classTeacherId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchStudent&gt;} body 
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignBatchTermStudent: async (body: Array<BatchStudent>, batchTermId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling assignBatchTermStudent.');
            }
            // verify required parameter 'batchTermId' is not null or undefined
            if (batchTermId === null || batchTermId === undefined) {
                throw new RequiredError('batchTermId','Required parameter batchTermId was null or undefined when calling assignBatchTermStudent.');
            }
            const localVarPath = `/admin/batch-management/assign_batch_term_student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTermId !== undefined) {
                localVarQueryParameter['batchTermId'] = batchTermId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api add details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchMaster: async (body: BatchMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBatchMaster.');
            }
            const localVarPath = `/admin/batch-management/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchMasterTerm: async (programId: number, batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling createBatchMasterTerm.');
            }
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling createBatchMasterTerm.');
            }
            const localVarPath = `/admin/batch-management/batch_master_term`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentClasses: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling createStudentClasses.');
            }
            const localVarPath = `/admin/batch-management/create_student_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchMaster: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling deleteBatchMaster.');
            }
            const localVarPath = `/admin/batch-management/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTerm: async (batchTermId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchTermId' is not null or undefined
            if (batchTermId === null || batchTermId === undefined) {
                throw new RequiredError('batchTermId','Required parameter batchTermId was null or undefined when calling deleteBatchTerm.');
            }
            const localVarPath = `/admin/batch-management/batch_term`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTermId !== undefined) {
                localVarQueryParameter['batchTermId'] = batchTermId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api delete details of the batch subject and all the classes
         * @param {number} batchSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTermSubject: async (batchSubjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchSubjectId' is not null or undefined
            if (batchSubjectId === null || batchSubjectId === undefined) {
                throw new RequiredError('batchSubjectId','Required parameter batchSubjectId was null or undefined when calling deleteBatchTermSubject.');
            }
            const localVarPath = `/admin/batch-management/batch_term_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchSubjectId !== undefined) {
                localVarQueryParameter['batchSubjectId'] = batchSubjectId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchTimeslotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTimeslot: async (batchTimeslotId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchTimeslotId' is not null or undefined
            if (batchTimeslotId === null || batchTimeslotId === undefined) {
                throw new RequiredError('batchTimeslotId','Required parameter batchTimeslotId was null or undefined when calling deleteBatchTimeslot.');
            }
            const localVarPath = `/admin/batch-management/batch_timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTimeslotId !== undefined) {
                localVarQueryParameter['batchTimeslotId'] = batchTimeslotId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMaster: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getBatchMaster.');
            }
            const localVarPath = `/admin/batch-management/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMasterTerms: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getBatchMasterTerms.');
            }
            const localVarPath = `/admin/batch-management/batch_master_terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMasterTimeslots: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getBatchMasterTimeslots.');
            }
            const localVarPath = `/admin/batch-management/batch_master_timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentAvailability: async (uId: number, date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling getStudentAvailability.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getStudentAvailability.');
            }
            const localVarPath = `/admin/batch-management/student_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {ListBatchesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatches: async (body: ListBatchesParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listBatches.');
            }
            const localVarPath = `/admin/batch-management/list_batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} batchId 
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableStudents: async (body: Array<BatchTimeslot>, timezone: string, batchId: number, termId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadAvailableStudents.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling loadAvailableStudents.');
            }
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling loadAvailableStudents.');
            }
            // verify required parameter 'termId' is not null or undefined
            if (termId === null || termId === undefined) {
                throw new RequiredError('termId','Required parameter termId was null or undefined when calling loadAvailableStudents.');
            }
            const localVarPath = `/admin/batch-management/load_available_students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (termId !== undefined) {
                localVarQueryParameter['termId'] = termId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableTeachers: async (body: Array<BatchTimeslot>, timezone: string, batchId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loadAvailableTeachers.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling loadAvailableTeachers.');
            }
            const localVarPath = `/admin/batch-management/load_available_teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBatch: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling publishBatch.');
            }
            const localVarPath = `/admin/batch-management/publish_batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {number} batchTermId 
         * @param {string} startDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleBatchClass: async (body: Array<BatchTimeslot>, batchTermId: number, startDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling scheduleBatchClass.');
            }
            // verify required parameter 'batchTermId' is not null or undefined
            if (batchTermId === null || batchTermId === undefined) {
                throw new RequiredError('batchTermId','Required parameter batchTermId was null or undefined when calling scheduleBatchClass.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling scheduleBatchClass.');
            }
            const localVarPath = `/admin/batch-management/schedule_batch_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTermId !== undefined) {
                localVarQueryParameter['batchTermId'] = batchTermId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchTermId 
         * @param {number} batchSubjectId 
         * @param {boolean} allFuture 
         * @param {number} [batchTimeslotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subejctBatchTimeslots: async (batchTermId: number, batchSubjectId: number, allFuture: boolean, batchTimeslotId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchTermId' is not null or undefined
            if (batchTermId === null || batchTermId === undefined) {
                throw new RequiredError('batchTermId','Required parameter batchTermId was null or undefined when calling subejctBatchTimeslots.');
            }
            // verify required parameter 'batchSubjectId' is not null or undefined
            if (batchSubjectId === null || batchSubjectId === undefined) {
                throw new RequiredError('batchSubjectId','Required parameter batchSubjectId was null or undefined when calling subejctBatchTimeslots.');
            }
            // verify required parameter 'allFuture' is not null or undefined
            if (allFuture === null || allFuture === undefined) {
                throw new RequiredError('allFuture','Required parameter allFuture was null or undefined when calling subejctBatchTimeslots.');
            }
            const localVarPath = `/admin/batch-management/subejct_batch_timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchTermId !== undefined) {
                localVarQueryParameter['batchTermId'] = batchTermId;
            }

            if (batchSubjectId !== undefined) {
                localVarQueryParameter['batchSubjectId'] = batchSubjectId;
            }

            if (allFuture !== undefined) {
                localVarQueryParameter['allFuture'] = allFuture;
            }

            if (batchTimeslotId !== undefined) {
                localVarQueryParameter['batchTimeslotId'] = batchTimeslotId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchMaster: async (body: BatchMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBatchMaster.');
            }
            const localVarPath = `/admin/batch-management/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchMgmtApi - functional programming interface
 * @export
 */
export const BatchMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchSubject&gt;} body 
         * @param {number} batchTermId 
         * @param {number} [classTeacherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignBatchSubjectTeacher(body: Array<BatchSubject>, batchTermId: number, classTeacherId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchSubject>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).assignBatchSubjectTeacher(body, batchTermId, classTeacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchStudent&gt;} body 
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignBatchTermStudent(body: Array<BatchStudent>, batchTermId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchStudent>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).assignBatchTermStudent(body, batchTermId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api add details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchMaster(body: BatchMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).createBatchMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchMasterTerm(programId: number, batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchTerm>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).createBatchMasterTerm(programId, batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentClasses(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).createStudentClasses(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatchMaster(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).deleteBatchMaster(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatchTerm(batchTermId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).deleteBatchTerm(batchTermId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api delete details of the batch subject and all the classes
         * @param {number} batchSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatchTermSubject(batchSubjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).deleteBatchTermSubject(batchSubjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchTimeslotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatchTimeslot(batchTimeslotId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).deleteBatchTimeslot(batchTimeslotId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchMaster(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).getBatchMaster(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchMasterTerms(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTerm>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).getBatchMasterTerms(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchMasterTimeslots(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).getBatchMasterTimeslots(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentAvailability(uId: number, date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).getStudentAvailability(uId, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {ListBatchesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBatches(body: ListBatchesParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBatchesOutput>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).listBatches(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} batchId 
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAvailableStudents(body: Array<BatchTimeslot>, timezone: string, batchId: number, termId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Student>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).loadAvailableStudents(body, timezone, batchId, termId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAvailableTeachers(body: Array<BatchTimeslot>, timezone: string, batchId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Teacher>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).loadAvailableTeachers(body, timezone, batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishBatch(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).publishBatch(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {number} batchTermId 
         * @param {string} startDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleBatchClass(body: Array<BatchTimeslot>, batchTermId: number, startDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).scheduleBatchClass(body, batchTermId, startDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchTermId 
         * @param {number} batchSubjectId 
         * @param {boolean} allFuture 
         * @param {number} [batchTimeslotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subejctBatchTimeslots(batchTermId: number, batchSubjectId: number, allFuture: boolean, batchTimeslotId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).subejctBatchTimeslots(batchTermId, batchSubjectId, allFuture, batchTimeslotId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchMaster(body: BatchMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await BatchMgmtApiAxiosParamCreator(configuration).updateBatchMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BatchMgmtApi - factory interface
 * @export
 */
export const BatchMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchSubject&gt;} body 
         * @param {number} batchTermId 
         * @param {number} [classTeacherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignBatchSubjectTeacher(body: Array<BatchSubject>, batchTermId: number, classTeacherId?: number, options?: any): AxiosPromise<Array<BatchSubject>> {
            return BatchMgmtApiFp(configuration).assignBatchSubjectTeacher(body, batchTermId, classTeacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchStudent&gt;} body 
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignBatchTermStudent(body: Array<BatchStudent>, batchTermId: number, options?: any): AxiosPromise<Array<BatchStudent>> {
            return BatchMgmtApiFp(configuration).assignBatchTermStudent(body, batchTermId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api add details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchMaster(body: BatchMaster, options?: any): AxiosPromise<BatchMaster> {
            return BatchMgmtApiFp(configuration).createBatchMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchMasterTerm(programId: number, batchId: number, options?: any): AxiosPromise<BatchTerm> {
            return BatchMgmtApiFp(configuration).createBatchMasterTerm(programId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentClasses(batchId: number, options?: any): AxiosPromise<BatchMaster> {
            return BatchMgmtApiFp(configuration).createStudentClasses(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchMaster(batchId: number, options?: any): AxiosPromise<void> {
            return BatchMgmtApiFp(configuration).deleteBatchMaster(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api delete details of the batch term and all the subjects
         * @param {number} batchTermId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTerm(batchTermId: number, options?: any): AxiosPromise<void> {
            return BatchMgmtApiFp(configuration).deleteBatchTerm(batchTermId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api delete details of the batch subject and all the classes
         * @param {number} batchSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTermSubject(batchSubjectId: number, options?: any): AxiosPromise<void> {
            return BatchMgmtApiFp(configuration).deleteBatchTermSubject(batchSubjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchTimeslotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatchTimeslot(batchTimeslotId: number, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return BatchMgmtApiFp(configuration).deleteBatchTimeslot(batchTimeslotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMaster(batchId: number, options?: any): AxiosPromise<BatchMaster> {
            return BatchMgmtApiFp(configuration).getBatchMaster(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMasterTerms(batchId: number, options?: any): AxiosPromise<Array<BatchTerm>> {
            return BatchMgmtApiFp(configuration).getBatchMasterTerms(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMasterTimeslots(batchId: number, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return BatchMgmtApiFp(configuration).getBatchMasterTimeslots(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentAvailability(uId: number, date: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return BatchMgmtApiFp(configuration).getStudentAvailability(uId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {ListBatchesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatches(body: ListBatchesParams, options?: any): AxiosPromise<ListBatchesOutput> {
            return BatchMgmtApiFp(configuration).listBatches(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} batchId 
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableStudents(body: Array<BatchTimeslot>, timezone: string, batchId: number, termId: number, options?: any): AxiosPromise<Array<Student>> {
            return BatchMgmtApiFp(configuration).loadAvailableStudents(body, timezone, batchId, termId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {string} timezone 
         * @param {number} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableTeachers(body: Array<BatchTimeslot>, timezone: string, batchId?: number, options?: any): AxiosPromise<Array<Teacher>> {
            return BatchMgmtApiFp(configuration).loadAvailableTeachers(body, timezone, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to publish the batch
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBatch(batchId: number, options?: any): AxiosPromise<void> {
            return BatchMgmtApiFp(configuration).publishBatch(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {Array&lt;BatchTimeslot&gt;} body 
         * @param {number} batchTermId 
         * @param {string} startDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleBatchClass(body: Array<BatchTimeslot>, batchTermId: number, startDate: string, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return BatchMgmtApiFp(configuration).scheduleBatchClass(body, batchTermId, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {number} batchTermId 
         * @param {number} batchSubjectId 
         * @param {boolean} allFuture 
         * @param {number} [batchTimeslotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subejctBatchTimeslots(batchTermId: number, batchSubjectId: number, allFuture: boolean, batchTimeslotId?: number, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return BatchMgmtApiFp(configuration).subejctBatchTimeslots(batchTermId, batchSubjectId, allFuture, batchTimeslotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api update details of the batch
         * @param {BatchMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchMaster(body: BatchMaster, options?: any): AxiosPromise<BatchMaster> {
            return BatchMgmtApiFp(configuration).updateBatchMaster(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchMgmtApi - object-oriented interface
 * @export
 * @class BatchMgmtApi
 * @extends {BaseAPI}
 */
export class BatchMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api get list of all the batches
     * @param {Array&lt;BatchSubject&gt;} body 
     * @param {number} batchTermId 
     * @param {number} [classTeacherId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public assignBatchSubjectTeacher(body: Array<BatchSubject>, batchTermId: number, classTeacherId?: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).assignBatchSubjectTeacher(body, batchTermId, classTeacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {Array&lt;BatchStudent&gt;} body 
     * @param {number} batchTermId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public assignBatchTermStudent(body: Array<BatchStudent>, batchTermId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).assignBatchTermStudent(body, batchTermId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api add details of the batch
     * @param {BatchMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public createBatchMaster(body: BatchMaster, options?: any) {
        return BatchMgmtApiFp(this.configuration).createBatchMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api delete details of the batch term and all the subjects
     * @param {number} programId 
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public createBatchMasterTerm(programId: number, batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).createBatchMasterTerm(programId, batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public createStudentClasses(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).createStudentClasses(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api update details of the batch
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public deleteBatchMaster(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).deleteBatchMaster(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api delete details of the batch term and all the subjects
     * @param {number} batchTermId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public deleteBatchTerm(batchTermId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).deleteBatchTerm(batchTermId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api delete details of the batch subject and all the classes
     * @param {number} batchSubjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public deleteBatchTermSubject(batchSubjectId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).deleteBatchTermSubject(batchSubjectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to publish the batch
     * @param {number} batchTimeslotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public deleteBatchTimeslot(batchTimeslotId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).deleteBatchTimeslot(batchTimeslotId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public getBatchMaster(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).getBatchMaster(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public getBatchMasterTerms(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).getBatchMasterTerms(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public getBatchMasterTimeslots(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).getBatchMasterTimeslots(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of student timeslot
     * @param {number} uId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public getStudentAvailability(uId: number, date: string, options?: any) {
        return BatchMgmtApiFp(this.configuration).getStudentAvailability(uId, date, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {ListBatchesParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public listBatches(body: ListBatchesParams, options?: any) {
        return BatchMgmtApiFp(this.configuration).listBatches(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {Array&lt;BatchTimeslot&gt;} body 
     * @param {string} timezone 
     * @param {number} batchId 
     * @param {number} termId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public loadAvailableStudents(body: Array<BatchTimeslot>, timezone: string, batchId: number, termId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).loadAvailableStudents(body, timezone, batchId, termId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {Array&lt;BatchTimeslot&gt;} body 
     * @param {string} timezone 
     * @param {number} [batchId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public loadAvailableTeachers(body: Array<BatchTimeslot>, timezone: string, batchId?: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).loadAvailableTeachers(body, timezone, batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to publish the batch
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public publishBatch(batchId: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).publishBatch(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {Array&lt;BatchTimeslot&gt;} body 
     * @param {number} batchTermId 
     * @param {string} startDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public scheduleBatchClass(body: Array<BatchTimeslot>, batchTermId: number, startDate: string, options?: any) {
        return BatchMgmtApiFp(this.configuration).scheduleBatchClass(body, batchTermId, startDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {number} batchTermId 
     * @param {number} batchSubjectId 
     * @param {boolean} allFuture 
     * @param {number} [batchTimeslotId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public subejctBatchTimeslots(batchTermId: number, batchSubjectId: number, allFuture: boolean, batchTimeslotId?: number, options?: any) {
        return BatchMgmtApiFp(this.configuration).subejctBatchTimeslots(batchTermId, batchSubjectId, allFuture, batchTimeslotId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api update details of the batch
     * @param {BatchMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchMgmtApi
     */
    public updateBatchMaster(body: BatchMaster, options?: any) {
        return BatchMgmtApiFp(this.configuration).updateBatchMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
}
