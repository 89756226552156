/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BatchMaster } from '../models';
import { ClassAttendance } from '../models';
import { ClassMaster } from '../models';
import { DemoClassStudent } from '../models';
import { RoleMaster } from '../models';
import { StudentClass } from '../models';
import { Teacher } from '../models';
import { User } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to mark Lead's attendance
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkLeadAttendance: async (classMasterId: number, leadId: number, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling adminMarkLeadAttendance.');
            }
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling adminMarkLeadAttendance.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling adminMarkLeadAttendance.');
            }
            const localVarPath = `/admin/mark_lead_attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to mark student's attendance
         * @param {number} classMasterId 
         * @param {number} studentId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkStudentAttendance: async (classMasterId: number, studentId: number, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling adminMarkStudentAttendance.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling adminMarkStudentAttendance.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling adminMarkStudentAttendance.');
            }
            const localVarPath = `/admin/mark_student_attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to mark teacher's attendance
         * @param {number} classMasterId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkTeacherAttendance: async (classMasterId: number, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling adminMarkTeacherAttendance.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling adminMarkTeacherAttendance.');
            }
            const localVarPath = `/admin/mark_teacher_attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get to get live class users details
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStatusOfAClass: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getLiveStatusOfAClass.');
            }
            const localVarPath = `/admin/get_live_status_of_a_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get to get all active users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/get_live_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatchesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/list_batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the relationship managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterRms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/list_rms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the sales managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterSms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/list_sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterTeachers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/list_teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the role master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRollMasters: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/list_roll_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to mark Lead's attendance
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMarkLeadAttendance(classMasterId: number, leadId: number, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoClassStudent>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).adminMarkLeadAttendance(classMasterId, leadId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to mark student's attendance
         * @param {number} classMasterId 
         * @param {number} studentId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMarkStudentAttendance(classMasterId: number, studentId: number, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentClass>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).adminMarkStudentAttendance(classMasterId, studentId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to mark teacher's attendance
         * @param {number} classMasterId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMarkTeacherAttendance(classMasterId: number, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).adminMarkTeacherAttendance(classMasterId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get to get live class users details
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveStatusOfAClass(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).getLiveStatusOfAClass(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get to get all active users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLiveStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassAttendance>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).getUserLiveStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBatchesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchMaster>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).listBatchesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the relationship managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFilterRms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).listFilterRms(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the sales managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFilterSms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).listFilterSms(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFilterTeachers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Teacher>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).listFilterTeachers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the role master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRollMasters(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleMaster>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).listRollMasters(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to mark Lead's attendance
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkLeadAttendance(classMasterId: number, leadId: number, status: string, options?: any): AxiosPromise<DemoClassStudent> {
            return AdminApiFp(configuration).adminMarkLeadAttendance(classMasterId, leadId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to mark student's attendance
         * @param {number} classMasterId 
         * @param {number} studentId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkStudentAttendance(classMasterId: number, studentId: number, status: string, options?: any): AxiosPromise<StudentClass> {
            return AdminApiFp(configuration).adminMarkStudentAttendance(classMasterId, studentId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to mark teacher's attendance
         * @param {number} classMasterId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMarkTeacherAttendance(classMasterId: number, status: string, options?: any): AxiosPromise<ClassMaster> {
            return AdminApiFp(configuration).adminMarkTeacherAttendance(classMasterId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get to get live class users details
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStatusOfAClass(classMasterId: number, options?: any): AxiosPromise<Array<any>> {
            return AdminApiFp(configuration).getLiveStatusOfAClass(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get to get all active users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveStatus(options?: any): AxiosPromise<Array<ClassAttendance>> {
            return AdminApiFp(configuration).getUserLiveStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatchesGet(options?: any): AxiosPromise<Array<BatchMaster>> {
            return AdminApiFp(configuration).listBatchesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the relationship managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterRms(options?: any): AxiosPromise<Array<User>> {
            return AdminApiFp(configuration).listFilterRms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the sales managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterSms(options?: any): AxiosPromise<Array<User>> {
            return AdminApiFp(configuration).listFilterSms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterTeachers(options?: any): AxiosPromise<Array<Teacher>> {
            return AdminApiFp(configuration).listFilterTeachers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the role master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRollMasters(options?: any): AxiosPromise<Array<RoleMaster>> {
            return AdminApiFp(configuration).listRollMasters(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary api to mark Lead's attendance
     * @param {number} classMasterId 
     * @param {number} leadId 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMarkLeadAttendance(classMasterId: number, leadId: number, status: string, options?: any) {
        return AdminApiFp(this.configuration).adminMarkLeadAttendance(classMasterId, leadId, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to mark student's attendance
     * @param {number} classMasterId 
     * @param {number} studentId 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMarkStudentAttendance(classMasterId: number, studentId: number, status: string, options?: any) {
        return AdminApiFp(this.configuration).adminMarkStudentAttendance(classMasterId, studentId, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to mark teacher's attendance
     * @param {number} classMasterId 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMarkTeacherAttendance(classMasterId: number, status: string, options?: any) {
        return AdminApiFp(this.configuration).adminMarkTeacherAttendance(classMasterId, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get to get live class users details
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getLiveStatusOfAClass(classMasterId: number, options?: any) {
        return AdminApiFp(this.configuration).getLiveStatusOfAClass(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get to get all active users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUserLiveStatus(options?: any) {
        return AdminApiFp(this.configuration).getUserLiveStatus(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listBatchesGet(options?: any) {
        return AdminApiFp(this.configuration).listBatchesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the relationship managers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listFilterRms(options?: any) {
        return AdminApiFp(this.configuration).listFilterRms(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the sales managers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listFilterSms(options?: any) {
        return AdminApiFp(this.configuration).listFilterSms(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the Term class master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listFilterTeachers(options?: any) {
        return AdminApiFp(this.configuration).listFilterTeachers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the role master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listRollMasters(options?: any) {
        return AdminApiFp(this.configuration).listRollMasters(options).then((request) => request(this.axios, this.basePath));
    }
}
