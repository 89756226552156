import {
  AdminApi,
  AuthApi,
  BatchMgmtApi,
  ClassMgmtApi,
  Configuration,
  LeadApi,
  LeadMgmtApi,
  OpenApi,
  ProgramMgmtApi,
  StudentMgmtApi,
  TeacherApi,
  TeacherMgmtApi,
  UserMgmtApi,
} from './swagger'
import { BehaviorSubject } from 'rxjs'
import { Lead, User } from './swagger/models'
import { StudentApi } from './swagger/apis/student-api'
import jwtDecode from 'jwt-decode'
export class ApiController {
  private static instance: ApiController
  private config: Configuration = new Configuration()
  public authApi: AuthApi = new AuthApi()
  public openApi: OpenApi = new OpenApi()
  public adminApi: AdminApi = new AdminApi()
  public userMgmtApi: UserMgmtApi = new UserMgmtApi()
  public leadMgmtApi: LeadMgmtApi = new LeadMgmtApi()
  public teacherMgmtApi: TeacherMgmtApi = new TeacherMgmtApi()
  public programMgmtApi: ProgramMgmtApi = new ProgramMgmtApi()
  public batchMgmtApi: BatchMgmtApi = new BatchMgmtApi()
  public classMgmtApi: ClassMgmtApi = new ClassMgmtApi()
  public studentMgmtApi: StudentMgmtApi = new StudentMgmtApi()
  public teacherApi: TeacherApi = new TeacherApi()
  public studentApi: StudentApi = new StudentApi()
  public leadApi: LeadApi = new LeadApi()

  public user: User | null = null
  public user$ = new BehaviorSubject<User | null>(null)
  public lead: Lead | null = null
  public lead$ = new BehaviorSubject<Lead | null>(null)
  public UserLoggedIn: User | null = null
  public UserLoggedIn$ = new BehaviorSubject<User | null>(null)
  public isCallScreen = false
  public isCallScreen$ = new BehaviorSubject<boolean>(false)

  private constructor() {
    const token = this.getSavedToken()

    if (token) {
      this.initApis(token)
      this.loadSavedUser()
      this.loadSavedLead()
    } else {
      this.clearToken()
    }
  }

  public updateUserProfile(user: User | null): void {
    this.user = user
    this.user$.next(user)
    this.saveUser(user)
  }

  public updateLoggedInStatus(user: User | null): void {
    this.UserLoggedIn = user
    this.UserLoggedIn$.next(user)
  }

  public updateLeadProfile(lead: Lead | null): void {
    this.lead = lead
    this.lead$.next(lead)
    this.saveLead(lead)
  }

  public updateCallScreenStatus(status: boolean): void {
    this.isCallScreen = status
    this.isCallScreen$.next(status)
  }

  private saveUser(user: User | null): void {
    localStorage.setItem('USER', JSON.stringify(user))
  }

  private saveLead(lead: Lead | null): void {
    localStorage.setItem('LEAD', JSON.stringify(lead))
  }

  public static getInstance(): ApiController {
    if (!ApiController.instance) {
      ApiController.instance = new ApiController()
    }
    return ApiController.instance
  }

  private loadSavedUser(): User {
    const strUser = localStorage.getItem('USER')
    if (!strUser) {
      return {}
    } else {
      const user = JSON.parse(strUser)
      this.user = user
      this.updateLoggedInStatus(user)
      this.user$.next(user)
      return user
    }
  }

  private loadSavedLead(): Lead {
    const strLead = localStorage.getItem('LEAD')
    if (!strLead) {
      return {}
    } else {
      const lead = JSON.parse(strLead)
      this.lead = lead
      this.lead$.next(lead)
      return lead
    }
  }

  private saveToken(token: string | null): void {
    // tslint:disable-next-line: no-console
    token = token ? token : JSON.stringify(null)
    localStorage.setItem('TOKEN', token)
    localStorage.setItem('TOKEN_TS', JSON.stringify(Date.now()))
  }

  public getSavedToken(): string {
    const base64token = localStorage.getItem('TOKEN')
    if (!base64token) {
      return ''
    }
    const token: any = jwtDecode(base64token)
    if (token?.exp * 1000 <= new Date().getTime()) {
      return ''
    }
    return base64token
  }

  public setToken(token: string | null): void {
    this.saveToken(token)
    this.setApiConfig(token)
  }

  public clearToken(): void {
    this.config = {}
    this.updateUserProfile(null)
    this.updateLeadProfile(null)
    this.setToken(null)
    this.updateLoggedInStatus(null)
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('TOKEN_TS')
    localStorage.removeItem('UUID')
    this.setApiConfig('')
  }

  public initApis(token: string | null) {
    this.setToken(token)
  }

  private setApiConfig(token: string | null): void {
    this.config.baseOptions = {
      headers: {
        authorization: token,
      },
    }
    this.authApi = new AuthApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.openApi = new OpenApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.adminApi = new AdminApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.userMgmtApi = new UserMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.leadMgmtApi = new LeadMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.teacherMgmtApi = new TeacherMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.programMgmtApi = new ProgramMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.batchMgmtApi = new BatchMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.classMgmtApi = new ClassMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.studentMgmtApi = new StudentMgmtApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.teacherApi = new TeacherApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.studentApi = new StudentApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.leadApi = new LeadApi(this.config, process.env.REACT_APP_BASE_PATH)
    // Add other api services here
  }
}
