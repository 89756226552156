import { ApiController } from '../../apiController'
import { UserTimeslot, UserTimeslotHistory } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { Dispatch } from 'redux'
import { snackbarAction, TeacherPortalAction } from '../actions'

export const addTimeslotAPI = (timeslot: UserTimeslot[], timezone: string) => {
  return async (dispatch: Dispatch<TeacherPortalAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await ApiController.getInstance().teacherApi.createTeacherSchedule(
        timeslot,
        timezone
      )
      dispatch({
        type: ActionType.ADD_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const updateTimeslotAPI = (timeslot: UserTimeslotHistory) => {
  return async (dispatch: Dispatch<TeacherPortalAction | snackbarAction>) => {
    try {
      const timeslotResponse = await ApiController.getInstance().teacherApi.updateTeacherSchedule(
        timeslot
      )
      dispatch({
        type: ActionType.UPDATE_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const getTimeslotAPI = (queryDate: string, classType: string, timezone: string) => {
  return async (dispatch: Dispatch<TeacherPortalAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await ApiController.getInstance().teacherApi.getTeacherSchedule(
        queryDate.toString(),
        classType,
        timezone
      )
      dispatch({
        type: ActionType.GET_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
