import { ActionType, SnackbarActionType } from '../action-types'
import { ApiController } from '../../apiController'
import { Dispatch } from 'redux'
import { LoginPhoneRequest, LoginRequest, Teacher } from '../../swagger/models'
import { Action, snackbarAction } from '../actions/index'

export const login = (loginRequest: LoginRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.login(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const teacherLogin = (loginRequest: LoginRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.teacherLogin(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const studentEmailLogin = (loginRequest: LoginRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.studentEmailLogin(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const studentPhoneLogin = (loginRequest: LoginPhoneRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.studentPhoneLogin(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const teacherPhoneLogin = (loginRequest: LoginPhoneRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.teacherPhoneLogin(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const leadPhoneLogin = (loginRequest: LoginPhoneRequest) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().openApi.leadPhoneLogin(loginRequest)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLeadProfile(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getTeacherProfileApi = () => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().teacherApi.teacherProfile()
      dispatch({
        type: ActionType.GET_TEACHER_PROFILE,
        getTeacherProfile: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const listTeacherSubjects = (teacherId: number) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const teacherSubjects = await ApiController.getInstance().teacherApi.techerSubjects(teacherId)
      dispatch({
        type: ActionType.LIST_TEACHER_SUBJECTS,
        teacherSubjectList: teacherSubjects.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateTeacherProfileApi = (teacherDetails: Teacher) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const updatedTeacherDetails = await (
        await ApiController.getInstance().teacherApi.updateTeacherProfile(teacherDetails)
      ).data
      dispatch({
        type: ActionType.UPDATE_TEACHER_PROFILE,
        updatedTeacherDetails,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Details updated successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
