import { DemoClass } from './../../swagger/models/demo-class'
import { ClassMaster } from './../../swagger/models/class-master'
import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { ActionType, SnackbarActionType } from '../action-types'
import { ClassMgmtActions, snackbarAction } from '../actions'
import {
  BatchClass,
  ClassMasterDocument,
  DemoClassStudent,
  StudentClass,
} from '../../swagger/models'

export const getClassMaster = (classMasterId: number) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const classMaster = await (
        await ApiController.getInstance().classMgmtApi.getClassMaster(classMasterId)
      ).data
      dispatch({
        type: ActionType.GET_CLASS_MASTER,
        classMasterDetail: classMaster,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addClassMaster = (classMasterDetail: ClassMaster) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const classMaster = await (
        await ApiController.getInstance().classMgmtApi.createClassMaster(classMasterDetail)
      ).data
      dispatch({
        type: ActionType.ADD_CLASS,
        AddClassMasterDetail: classMaster,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateClassMaster = (classMasterDetail: ClassMaster) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const classMaster = await (
        await ApiController.getInstance().classMgmtApi.updateClassMaster(classMasterDetail)
      ).data
      dispatch({
        type: ActionType.UPDATE_CLASS,
        updateClassMasterDetail: classMaster,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateDemoClass = (demoClass: DemoClass) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const demoClassResponse = await (
        await ApiController.getInstance().classMgmtApi.updateDemoClass(demoClass)
      ).data
      dispatch({
        type: ActionType.UPDATE_DEMO_CLASS,
        updateDemoClassDetail: demoClassResponse,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const updateBatchClass = (batchClass: BatchClass) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const batchClassResponse = await (
        await ApiController.getInstance().classMgmtApi.updateBatchClass(batchClass)
      ).data
      dispatch({
        type: ActionType.UPDATE_BATCH_CLASS,
        updateBatchClassDetail: batchClassResponse,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const linkClassMasterTeacher = (classMasterId: number, teacherId: number) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const classMaster = await (
        await ApiController.getInstance().classMgmtApi.assignClassTeacher(classMasterId, teacherId)
      ).data
      dispatch({
        type: ActionType.ADD_CLASS_TEACHER,
        updateClassMasterDetail: classMaster,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to add the term document and dispatch an event */
export const addClassMasterDocumentAPI = (termDocument: ClassMasterDocument) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const classMasterDocumentDetail = await (
        await ApiController.getInstance().classMgmtApi.createClassMasterDocument(termDocument)
      ).data
      dispatch({
        type: ActionType.ADD_CLASS_MASTER_DOCUMENT,
        classMasterDocumentDetail,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class document added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the term document and dispatch an event */
export const deleteClassMasterDocumentAPI = (
  classDocId: number,
  classMasterDocumentIndex: number
) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      await (
        await ApiController.getInstance().classMgmtApi.deleteClassMasterDocument(classDocId)
      ).data
      dispatch({
        type: ActionType.REMOVE_CLASS_MASTER_DOCUMENT,
        classDocId,
        classMasterDocumentIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class document deleted successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addRegularClassStudentsAPI = (
  regularClassStudents: StudentClass[],
  classMasterId: number
) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const studentClasses = await (
        await ApiController.getInstance().classMgmtApi.assignRegularClassStudents(
          regularClassStudents,
          classMasterId
        )
      ).data
      dispatch({
        type: ActionType.ADD_CLASS_MASTER_STUDENTS,
        studentClasses,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Students added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addDemoClassLeadsAPI = (
  demoClassStudents: DemoClassStudent[],
  classMasterId: number
) => {
  return async (dispatch: Dispatch<ClassMgmtActions | snackbarAction>) => {
    try {
      const demoClassStudentsResp = await (
        await ApiController.getInstance().classMgmtApi.assignDemoClassLeads(
          demoClassStudents,
          classMasterId
        )
      ).data
      dispatch({
        type: ActionType.ADD_CLASS_MASTER_LEADS,
        demoClassStudents: demoClassStudentsResp,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Leads added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
