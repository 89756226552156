/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BatchMaster } from '../models';
import { BatchTerm } from '../models';
import { BatchTimeslot } from '../models';
import { ClassMaster } from '../models';
import { GradeMaster } from '../models';
import { SignedUrl } from '../models';
import { Student } from '../models';
import { StudentPayment } from '../models';
import { UserTimeslot } from '../models';
import { UserTimeslotHistory } from '../models';
/**
 * StudentApi - axios parameter creator
 * @export
 */
export const StudentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to add details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentSchedule: async (body: UserTimeslotHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStudentSchedule.');
            }
            const localVarPath = `/student/student_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPayment: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/list_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLowCreditBinding: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/low_credit_binding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMaster: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getStudentBatchMaster.');
            }
            const localVarPath = `/student/batch_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMasterTerms: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getStudentBatchMasterTerms.');
            }
            const localVarPath = `/student/batch_master_terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMasterTimeslots: async (batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling getStudentBatchMasterTimeslots.');
            }
            const localVarPath = `/student/batch_master_timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getStudentClassMaster.');
            }
            const localVarPath = `/student/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentSchedule: async (date: string, type: string, timezone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getStudentSchedule.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getStudentSchedule.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling getStudentSchedule.');
            }
            const localVarPath = `/student/student_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get today's classes for student
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentTodayClasses: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/today_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGradeMastersStudent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/list_grade_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStudentAttendance: async (classMasterId: number, time: string, eventType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling markStudentAttendance.');
            }
            // verify required parameter 'time' is not null or undefined
            if (time === null || time === undefined) {
                throw new RequiredError('time','Required parameter time was null or undefined when calling markStudentAttendance.');
            }
            // verify required parameter 'eventType' is not null or undefined
            if (eventType === null || eventType === undefined) {
                throw new RequiredError('eventType','Required parameter eventType was null or undefined when calling markStudentAttendance.');
            }
            const localVarPath = `/student/mark_student_attendance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student batches
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentBatches: async (status?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/student_batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentBatchesRm: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/student_batches_rm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentClasses: async (classType?: string, date?: string, fromDate?: string, toDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/student_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classType !== undefined) {
                localVarQueryParameter['classType'] = classType;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentGetStudentPayment: async (programId: number, batchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling studentGetStudentPayment.');
            }
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling studentGetStudentPayment.');
            }
            const localVarPath = `/student/student_payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get student profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/student/student_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentUploadProfilepic: async (profilePic: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling studentUploadProfilepic.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling studentUploadProfilepic.');
            }
            const localVarPath = `/student/student_upload_profilepic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update student profile
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentProfile: async (body: Student, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStudentProfile.');
            }
            const localVarPath = `/student/student_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentSchedule: async (body: UserTimeslotHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStudentSchedule.');
            }
            const localVarPath = `/student/student_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentApi - functional programming interface
 * @export
 */
export const StudentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to add details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentSchedule(body: UserTimeslotHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).createStudentSchedule(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPayment(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentPayment>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getListPayment(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLowCreditBinding(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentPayment>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getLowCreditBinding(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentBatchMaster(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchMaster>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentBatchMaster(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentBatchMasterTerms(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTerm>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentBatchMasterTerms(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentBatchMasterTimeslots(batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchTimeslot>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentBatchMasterTimeslots(batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentSchedule(date: string, type: string, timezone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentSchedule(date, type, timezone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get today's classes for student
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentTodayClasses(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).getStudentTodayClasses(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGradeMastersStudent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GradeMaster>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).listGradeMastersStudent(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStudentAttendance(classMasterId: number, time: string, eventType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).markStudentAttendance(classMasterId, time, eventType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student batches
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentBatches(status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchMaster>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentBatches(status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentBatchesRm(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchMaster>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentBatchesRm(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentClasses(classType, date, fromDate, toDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentGetStudentPayment(programId: number, batchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentPayment>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentGetStudentPayment(programId, batchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get student profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentUploadProfilepic(profilePic: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).studentUploadProfilepic(profilePic, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update student profile
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentProfile(body: Student, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).updateStudentProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentSchedule(body: UserTimeslotHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).updateStudentSchedule(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentApi - factory interface
 * @export
 */
export const StudentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to add details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentSchedule(body: UserTimeslotHistory, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return StudentApiFp(configuration).createStudentSchedule(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPayment(options?: any): AxiosPromise<Array<StudentPayment>> {
            return StudentApiFp(configuration).getListPayment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLowCreditBinding(options?: any): AxiosPromise<Array<StudentPayment>> {
            return StudentApiFp(configuration).getLowCreditBinding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMaster(batchId: number, options?: any): AxiosPromise<BatchMaster> {
            return StudentApiFp(configuration).getStudentBatchMaster(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMasterTerms(batchId: number, options?: any): AxiosPromise<Array<BatchTerm>> {
            return StudentApiFp(configuration).getStudentBatchMasterTerms(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get details of the batche
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentBatchMasterTimeslots(batchId: number, options?: any): AxiosPromise<Array<BatchTimeslot>> {
            return StudentApiFp(configuration).getStudentBatchMasterTimeslots(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentClassMaster(classMasterId: number, options?: any): AxiosPromise<ClassMaster> {
            return StudentApiFp(configuration).getStudentClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of student timeslot
         * @param {string} date 
         * @param {string} type 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentSchedule(date: string, type: string, timezone: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return StudentApiFp(configuration).getStudentSchedule(date, type, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get today's classes for student
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentTodayClasses(type?: string, options?: any): AxiosPromise<Array<ClassMaster>> {
            return StudentApiFp(configuration).getStudentTodayClasses(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGradeMastersStudent(options?: any): AxiosPromise<Array<GradeMaster>> {
            return StudentApiFp(configuration).listGradeMastersStudent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {string} time 
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStudentAttendance(classMasterId: number, time: string, eventType: string, options?: any): AxiosPromise<void> {
            return StudentApiFp(configuration).markStudentAttendance(classMasterId, time, eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student batches
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentBatches(status?: string, options?: any): AxiosPromise<Array<BatchMaster>> {
            return StudentApiFp(configuration).studentBatches(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentBatchesRm(options?: any): AxiosPromise<Array<BatchMaster>> {
            return StudentApiFp(configuration).studentBatchesRm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student_classes
         * @param {string} [classType] 
         * @param {string} [date] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<ClassMaster>> {
            return StudentApiFp(configuration).studentClasses(classType, date, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get details of student payment
         * @param {number} programId 
         * @param {number} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentGetStudentPayment(programId: number, batchId: number, options?: any): AxiosPromise<StudentPayment> {
            return StudentApiFp(configuration).studentGetStudentPayment(programId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get student profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentProfile(options?: any): AxiosPromise<Student> {
            return StudentApiFp(configuration).studentProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentUploadProfilepic(profilePic: string, email: string, options?: any): AxiosPromise<SignedUrl> {
            return StudentApiFp(configuration).studentUploadProfilepic(profilePic, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update student profile
         * @param {Student} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentProfile(body: Student, options?: any): AxiosPromise<Student> {
            return StudentApiFp(configuration).updateStudentProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update details of student timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentSchedule(body: UserTimeslotHistory, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return StudentApiFp(configuration).updateStudentSchedule(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentApi - object-oriented interface
 * @export
 * @class StudentApi
 * @extends {BaseAPI}
 */
export class StudentApi extends BaseAPI {
    /**
     * 
     * @summary api to add details of student timeslot
     * @param {UserTimeslotHistory} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public createStudentSchedule(body: UserTimeslotHistory, options?: any) {
        return StudentApiFp(this.configuration).createStudentSchedule(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of student payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getListPayment(options?: any) {
        return StudentApiFp(this.configuration).getListPayment(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of student payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getLowCreditBinding(options?: any) {
        return StudentApiFp(this.configuration).getLowCreditBinding(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentBatchMaster(batchId: number, options?: any) {
        return StudentApiFp(this.configuration).getStudentBatchMaster(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentBatchMasterTerms(batchId: number, options?: any) {
        return StudentApiFp(this.configuration).getStudentBatchMasterTerms(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get details of the batche
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentBatchMasterTimeslots(batchId: number, options?: any) {
        return StudentApiFp(this.configuration).getStudentBatchMasterTimeslots(batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentClassMaster(classMasterId: number, options?: any) {
        return StudentApiFp(this.configuration).getStudentClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of student timeslot
     * @param {string} date 
     * @param {string} type 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentSchedule(date: string, type: string, timezone: string, options?: any) {
        return StudentApiFp(this.configuration).getStudentSchedule(date, type, timezone, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get today's classes for student
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public getStudentTodayClasses(type?: string, options?: any) {
        return StudentApiFp(this.configuration).getStudentTodayClasses(type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of new GradeMaster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public listGradeMastersStudent(options?: any) {
        return StudentApiFp(this.configuration).listGradeMastersStudent(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {string} time 
     * @param {string} eventType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public markStudentAttendance(classMasterId: number, time: string, eventType: string, options?: any) {
        return StudentApiFp(this.configuration).markStudentAttendance(classMasterId, time, eventType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student batches
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentBatches(status?: string, options?: any) {
        return StudentApiFp(this.configuration).studentBatches(status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student batches
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentBatchesRm(options?: any) {
        return StudentApiFp(this.configuration).studentBatchesRm(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student_classes
     * @param {string} [classType] 
     * @param {string} [date] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentClasses(classType?: string, date?: string, fromDate?: string, toDate?: string, options?: any) {
        return StudentApiFp(this.configuration).studentClasses(classType, date, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get details of student payment
     * @param {number} programId 
     * @param {number} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentGetStudentPayment(programId: number, batchId: number, options?: any) {
        return StudentApiFp(this.configuration).studentGetStudentPayment(programId, batchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get student profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentProfile(options?: any) {
        return StudentApiFp(this.configuration).studentProfile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile pic upload for user
     * @summary upload profile pic of the User
     * @param {string} profilePic 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public studentUploadProfilepic(profilePic: string, email: string, options?: any) {
        return StudentApiFp(this.configuration).studentUploadProfilepic(profilePic, email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update student profile
     * @param {Student} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public updateStudentProfile(body: Student, options?: any) {
        return StudentApiFp(this.configuration).updateStudentProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update details of student timeslot
     * @param {UserTimeslotHistory} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public updateStudentSchedule(body: UserTimeslotHistory, options?: any) {
        return StudentApiFp(this.configuration).updateStudentSchedule(body, options).then((request) => request(this.axios, this.basePath));
    }
}
