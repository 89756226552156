import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  Grid,
  TextField,
  FormHelperText,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actionCreators, State } from '../../../state'
import { LoginRequest } from '../../../swagger/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import '../auth.scss'
import { loginFormValidationSchema } from '../../common-module/validation'
import StudentOtpLogin from './student-otp-login'
import SnackbarHelper from '../../common-module/snackbar-helper'
import { ApiController } from '../../../apiController'

function Login() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: '',
    password: '',
    isRememberme: true,
  })
  const [loginType, setLoginType] = useState('email')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { studentEmailLogin } = bindActionCreators(actionCreators, dispatch)
  const user = useSelector((state: State) => state.userLogin)

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((userLoggedIn) => {
      if (userLoggedIn && (userLoggedIn?.role === 'STUDENT' || userLoggedIn?.role === 'PARENT')) {
        // if(loginRequest.isRememberme) {

        // }
        navigate('/student/dashboard')
      }
    })
    getEmail()
  }, [])

  const getEmail = () => {
    const email = localStorage.getItem('email')
    if (email) {
      reset(loginRequest.email)
      loginRequest.email = email
    }
  }

  function redirectToForgetPassword() {
    navigate('/forgot-password')
  }

  const formOptions = { resolver: yupResolver(loginFormValidationSchema) }
  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  const onInputChange = (e: any) => {
    const { name, value } = e.currentTarget
    setLoginRequest({
      ...loginRequest,
      [name]: value,
    })
  }

  function onSubmit() {
    if (loginRequest.isRememberme) {
      localStorage.setItem('email', loginRequest.email)
    }
    studentEmailLogin(loginRequest)
    return false
  }

  const changeLoginType = (loginValue: string) => {
    setLoginType(loginValue)
  }

  return (
    <Box className="auth" sx={{ backgroundImage: 'url(/images/login-bg.svg)' }}>
      <SnackbarHelper />
      {!isAuthenticated && (
        <Grid
          container={true}
          spacing="2"
          alignItems="center"
          justifyContent="end"
          className="mvh-100"
        >
          <Grid item={true} md={6} lg={5} xl={4}>
            <Card sx={{ px: 3, py: 4, boxShadow: 3 }}>
              <div className="logo">
                <img src="/images/logo.png" alt="" />
              </div>
              <h1 className="text-center">Student Login</h1>
              {loginType === 'email' ? (
                <React.Fragment>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth={true} sx={{ mb: 3 }}>
                      <TextField
                        type="email"
                        error={!!errors.email}
                        {...register('email')}
                        fullWidth={true}
                        id="email"
                        value={loginRequest.email}
                        onChange={onInputChange}
                        label="Email id"
                        variant="outlined"
                      />
                      <FormHelperText className="error">{errors.email?.message}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ mb: 3 }}>
                      <TextField
                        type="password"
                        error={!!errors.password}
                        {...register('password')}
                        fullWidth={true}
                        id="password"
                        value={loginRequest.password}
                        onChange={onInputChange}
                        label="Password"
                        variant="outlined"
                        autoFocus={true}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            onSubmit()
                          }
                        }}
                      />
                      <FormHelperText className="error">{errors.password?.message}</FormHelperText>
                    </FormControl>
                    <Grid container={true} justifyContent="space-between">
                      <Grid item={true}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                setLoginRequest({
                                  ...loginRequest,
                                  isRememberme: e.target.checked,
                                })
                              }
                              checked={loginRequest.isRememberme}
                            />
                          }
                          color="primary"
                          name="isRememberme"
                          label="Remember me"
                        />
                      </Grid>
                      <Grid item={true}>
                        <Button
                          variant="text"
                          className="no-shadow"
                          onClick={() => redirectToForgetPassword()}
                        >
                          Forgot Password?
                        </Button>
                      </Grid>
                    </Grid>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className="btn btn-primary"
                      >
                        Log In
                      </Button>
                      <Typography sx={{ my: 2 }} variant="body1" color="initial">
                        Or
                      </Typography>
                      <Button
                        className="no-shadow"
                        variant="text"
                        color="primary"
                        type="button"
                        onClick={() => setLoginType('otp')}
                      >
                        Login with Mobile No
                      </Button>
                    </div>
                    {/* <Box sx={{ mt: 3 }} textAlign={'center'}>
                      <Typography variant="body1" color="initial">
                        Don't have an account?{' '}
                        <Link to="/sign-up" className="text-primary">
                          Register now
                        </Link>
                      </Typography>
                    </Box> */}
                  </form>
                </React.Fragment>
              ) : (
                <StudentOtpLogin changeLoginType={changeLoginType} />
              )}
            </Card>
          </Grid>
          <Grid item={true} lg={1} />
        </Grid>
      )}
    </Box>
  )
}

export default Login
