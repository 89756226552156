import { User } from './../../swagger/models/user'
/* eslint-disable import/no-anonymous-default-export */

import { Action } from '../actions/index'
import { ActionType } from '../action-types/index'

interface AuthUser {
  loggedInUser?: User
}

const initialState = {
  loggedInUser: {},
}

const authReducer = (state: AuthUser = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        loggedInUser: action.user,
      }
    default:
      return state
  }
}

export default authReducer
