import { StudentPortalAction } from './../actions/index'
/* eslint-disable import/no-anonymous-default-export */

import { ActionType } from '../action-types/index'
import { Student, UserTimeslot } from '../../swagger/models'

interface StudentPortal {
  getStudent?: Student
  userTimeslotHistory?: UserTimeslot[]
  shouldShowLoader: boolean
}

const initialState = {
  getStudent: {},
  userTimeslotHistory: [],
  shouldShowLoader: false,
}

const studentPortalReducer = (state: StudentPortal = initialState, action: StudentPortalAction) => {
  switch (action.type) {
    case ActionType.GET_STUDENT_PROFILE:
      return {
        ...state,
        getStudent: action.getStudent,
      }
    case ActionType.UPDATE_STUDENT_PROFILE:
      return {
        ...state,
        getStudent: action.updateStudent,
      }
    case ActionType.ADD_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    case ActionType.UPDATE_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    case ActionType.GET_TIMESLOT:
      return {
        ...state,
        userTimeslotHistory: action.usertimeslot,
      }
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        shouldShowLoader: action.shouldShowLoader,
      }
    default:
      return state
  }
}

export default studentPortalReducer
