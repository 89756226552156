import React from 'react'

function Loader() {
  return (
    <React.Fragment>
      <div className="loader">
        <div className="text-center">
          <div className="book">
            <div className="inner">
              <div className="left" />
              <div className="middle" />
              <div className="right" />
            </div>
            <ul>
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
          <br />
          Loading ...
        </div>
      </div>
    </React.Fragment>
  )
}

export default Loader
