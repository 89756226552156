import { ApiController } from '../../apiController'
import { Student, StudentPayment, UserTimeslot } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { snackbarAction, StudentMgmtAction } from '../actions'
import { Dispatch } from 'redux'

/* API to add the details of student and dispatch an event */
export const addStudentAPI = (student: Student) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.createStudent(student)
      dispatch({
        type: ActionType.ADD_STUDENT,
        studentDetail: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Student details captured successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to update the details of student and dispatch an event */
export const updateStudentAPI = (student: Student) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.updateStudent(student)
      dispatch({
        type: ActionType.UPDATE_STUDENT,
        studentDetail: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Student details updated successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to fetch the student details and dispatch an event*/
export const getStudentAPI = (studentId: number) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.fetchStudent(studentId)
      dispatch({
        type: ActionType.GET_STUDENT,
        studentDetail: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addStudentPaymentAPI = (paymentDetails: StudentPayment) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.createStudentPayment(
        paymentDetails
      )
      dispatch({
        type: ActionType.ADD_STUDENT_PAYMENT,
        paymentDetail: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Payment details captured successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateStudentPaymentAPI = (paymentDetails: StudentPayment) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.updateStudentPayment(
        paymentDetails
      )
      dispatch({
        type: ActionType.UPDATE_STUDENT_PAYMENT,
        paymentDetail: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Payment details updated successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateStudentClassCreditAPI = (paymentDetails: StudentPayment) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().studentMgmtApi.addClassCredit(
        paymentDetails
      )
      dispatch({
        type: ActionType.UPDATE_STUDENT_PAYMENT,
        paymentDetail: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class credits updated successfully',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addStudentTimeslotAPI = (
  timeslots: UserTimeslot[],
  uId: number,
  timeZoneOffset: string
) => {
  return async (dispatch: Dispatch<snackbarAction | StudentMgmtAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await (
        await ApiController.getInstance().studentMgmtApi.createStudentTimeslot(
          timeslots,
          uId,
          timeZoneOffset,
          true
        )
      ).data
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details captured successfully!',
      })
      dispatch({
        type: ActionType.ADD_STUDENT_TIMESLOT,
        usertimeslot: timeslotResponse,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const getStudentTimeslotAPI = (uId: number, queryDate: string, timeZone: string) => {
  return async (dispatch: Dispatch<StudentMgmtAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await (
        await ApiController.getInstance().studentMgmtApi.getStudentTimeslot(
          uId,
          queryDate.toString(),
          timeZone
        )
      ).data
      dispatch({
        type: ActionType.GET_STUDENT_TIMESLOT,
        usertimeslot: timeslotResponse,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
