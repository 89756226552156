import { ActionType, SnackbarActionType } from './../action-types/index'
import { Dispatch } from 'react'
import { Action, snackbarAction } from '../actions/index'
import { ApiController } from '../../apiController'
import { ProgramGroup, Term, TermClass, TermDocument } from '../../swagger/models'

export const updateTermClassAPI = (
  termClass: TermClass,
  termSubjectIndex: number,
  termClassIndex: number
) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const updatedTerm = await (
        await ApiController.getInstance().programMgmtApi.updateTermClass(termClass)
      ).data
      dispatch({
        type: ActionType.UPDATE_TERM_CLASS,
        termClassDetail: updatedTerm,
        subjectIndex: termSubjectIndex,
        classIndex: termClassIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Term class updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getTermAPI = (termId: number) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const termDetails = await (
        await ApiController.getInstance().programMgmtApi.getTermMaster(termId)
      ).data
      dispatch({
        type: ActionType.GET_TERM,
        termDetails,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateTermAPI = (termDetails: Term) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const updatedTermDetails = await (
        await ApiController.getInstance().programMgmtApi.updateTermMaster(termDetails)
      ).data
      dispatch({
        type: ActionType.UPDATE_TERM,
        termDetails: updatedTermDetails,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Term details updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addTermAPI = (termDetails: Term) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const newTermDetails = await (
        await ApiController.getInstance().programMgmtApi.createTermMaster(termDetails)
      ).data
      dispatch({
        type: ActionType.ADD_TERM,
        termDetails: newTermDetails,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Term created successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the term class chapter and dispatch an event */
export const deleteTermClassChapterAPI = (
  termClassChapterId: number,
  classIndex: number,
  subjectIndex: number
) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      await (
        await ApiController.getInstance().programMgmtApi.deleteTermClassChapter(termClassChapterId)
      ).data
      dispatch({
        type: ActionType.REMOVE_TERM_CLASS_CHAPTER,
        classIndex,
        subjectIndex,
        termClassChapterId,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class chapter successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to add the term document and dispatch an event */
export const addTermClassDocumentAPI = (
  termDocument: TermDocument,
  classIndex: number,
  subjectIndex: number
) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const termClassDocumentDetail = await (
        await ApiController.getInstance().programMgmtApi.createTermDocument(termDocument)
      ).data
      dispatch({
        type: ActionType.ADD_TERM_CLASS_DOCUMENT,
        classIndex,
        subjectIndex,
        termClassDocumentDetail,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class document added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the term document and dispatch an event */
export const deleteTermClassDocumentAPI = (
  termDocId: number,
  classIndex: number,
  subjectIndex: number
) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      await (
        await ApiController.getInstance().programMgmtApi.deleteTermDocument(termDocId)
      ).data
      dispatch({
        type: ActionType.REMOVE_TERM_CLASS_DOCUMENT,
        termDocId,
        classIndex,
        subjectIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class document deleted successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to delete the term class and dispatch an event */
export const deleteTermClassAPI = (
  termClassId: number,
  termIndex: number,
  subjectIndex: number
) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      await (
        await ApiController.getInstance().programMgmtApi.deleteTermClass(termClassId)
      ).data
      dispatch({
        type: ActionType.REMOVE_TERM_CLASS,
        termClassId,
        termIndex,
        subjectIndex,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Class document deleted successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to get the term list and dispatch an event*/
export const getTermListAPI = (programId: number, includeSubject: boolean) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const termList = await (
        await ApiController.getInstance().programMgmtApi.listTermMaster(includeSubject, programId)
      ).data
      dispatch({
        type: ActionType.GET_TERM_LIST,
        termList,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to get the Prpgram group list and dispatch an event*/
export const getProgramGroupListAPI = () => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const programGroupList = await (
        await ApiController.getInstance().programMgmtApi.listProgramGroups()
      ).data
      dispatch({
        type: ActionType.GET_PROGRAM_GROUP_LIST,
        programGroupList,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to update the program group and dispatch an event*/
export const updateProgramGroupApi = (programGroup: ProgramGroup) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const programGroupDetail = await (
        await ApiController.getInstance().programMgmtApi.updatesProgramGroup(programGroup)
      ).data
      dispatch({
        type: ActionType.UPDATE_PROGRAM_GROUP,
        programGroupDetail,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Program Group updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

/* API to add the program group and dispatch an event*/
export const addProgramGroupApi = (programGroup: ProgramGroup) => {
  return async (dispatch: Dispatch<Action | snackbarAction>) => {
    try {
      const programGroupDetail = await (
        await ApiController.getInstance().programMgmtApi.createsNewProgramGroup(programGroup)
      ).data
      dispatch({
        type: ActionType.ADD_PROGRAM_GROUP,
        programGroupDetail,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Program Group added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
