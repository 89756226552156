/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ClassMaster } from '../models';
import { DemoClassStudent } from '../models';
import { Lead } from '../models';
import { LeadManager } from '../models';
import { LeadSource } from '../models';
import { LeadStage } from '../models';
import { LeadSyncHistory } from '../models';
import { ListLeadsOutput } from '../models';
import { ListLeadsParams } from '../models';
import { SyncLeadOutput } from '../models';
/**
 * LeadMgmtApi - axios parameter creator
 * @export
 */
export const LeadMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLeadDemoClass: async (classMasterId: number, leadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling assignLeadDemoClass.');
            }
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling assignLeadDemoClass.');
            }
            const localVarPath = `/admin/lead-management/assign_lead_demo_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to cancel the scheduled demo class for lead
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelLeadDemoClass: async (classMasterId: number, leadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling cancelLeadDemoClass.');
            }
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling cancelLeadDemoClass.');
            }
            const localVarPath = `/admin/lead-management/cancel_lead_demo_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {string} [date] 
         * @param {Array&lt;string&gt;} [programs] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {Array&lt;string&gt;} [teachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoClasses: async (date?: string, programs?: Array<string>, subjects?: Array<string>, teachers?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lead-management/demo_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (programs) {
                localVarQueryParameter['programs'] = programs.join(COLLECTION_FORMATS.csv);
            }

            if (subjects) {
                localVarQueryParameter['subjects'] = subjects.join(COLLECTION_FORMATS.csv);
            }

            if (teachers) {
                localVarQueryParameter['teachers'] = teachers.join(COLLECTION_FORMATS.csv);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get excel file of list of leads.
         * @summary download_list_leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadListLeads: async (body: ListLeadsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadListLeads.');
            }
            const localVarPath = `/admin/lead-management/download_list_leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to get the details of the lead
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLead: async (leadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling getLead.');
            }
            const localVarPath = `/admin/lead-management/get_lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastSync: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lead-management/last_sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeads: async (body: ListLeadsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listLeads.');
            }
            const localVarPath = `/admin/lead-management/list_leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the leads assignee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsAssignee: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lead-management/list_leads_assignee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the leads source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsSource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lead-management/list_leads_source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the leads stage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsStage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lead-management/list_leads_stage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {boolean} dryRun 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} pageIndex 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLeads: async (dryRun: boolean, fromDate: string, toDate: string, pageIndex: string, pageSize: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dryRun' is not null or undefined
            if (dryRun === null || dryRun === undefined) {
                throw new RequiredError('dryRun','Required parameter dryRun was null or undefined when calling syncLeads.');
            }
            // verify required parameter 'fromDate' is not null or undefined
            if (fromDate === null || fromDate === undefined) {
                throw new RequiredError('fromDate','Required parameter fromDate was null or undefined when calling syncLeads.');
            }
            // verify required parameter 'toDate' is not null or undefined
            if (toDate === null || toDate === undefined) {
                throw new RequiredError('toDate','Required parameter toDate was null or undefined when calling syncLeads.');
            }
            // verify required parameter 'pageIndex' is not null or undefined
            if (pageIndex === null || pageIndex === undefined) {
                throw new RequiredError('pageIndex','Required parameter pageIndex was null or undefined when calling syncLeads.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling syncLeads.');
            }
            const localVarPath = `/admin/lead-management/sync_leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} crnId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleLead: async (crnId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crnId' is not null or undefined
            if (crnId === null || crnId === undefined) {
                throw new RequiredError('crnId','Required parameter crnId was null or undefined when calling syncSingleLead.');
            }
            const localVarPath = `/admin/lead-management/sync_single_lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (crnId !== undefined) {
                localVarQueryParameter['crnId'] = crnId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLead: async (prospectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            if (prospectId === null || prospectId === undefined) {
                throw new RequiredError('prospectId','Required parameter prospectId was null or undefined when calling updateLead.');
            }
            const localVarPath = `/admin/lead-management/update_lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (prospectId !== undefined) {
                localVarQueryParameter['prospectId'] = prospectId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadMgmtApi - functional programming interface
 * @export
 */
export const LeadMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignLeadDemoClass(classMasterId: number, leadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).assignLeadDemoClass(classMasterId, leadId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to cancel the scheduled demo class for lead
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelLeadDemoClass(classMasterId: number, leadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoClassStudent>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).cancelLeadDemoClass(classMasterId, leadId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {string} [date] 
         * @param {Array&lt;string&gt;} [programs] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {Array&lt;string&gt;} [teachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoClasses(date?: string, programs?: Array<string>, subjects?: Array<string>, teachers?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).demoClasses(date, programs, subjects, teachers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get excel file of list of leads.
         * @summary download_list_leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadListLeads(body: ListLeadsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).downloadListLeads(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to get the details of the lead
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLead(leadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).getLead(leadId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lastSync(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSyncHistory>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).lastSync(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLeads(body: ListLeadsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLeadsOutput>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).listLeads(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the leads assignee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLeadsAssignee(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadManager>>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).listLeadsAssignee(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the leads source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLeadsSource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadSource>>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).listLeadsSource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the leads stage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLeadsStage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadStage>>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).listLeadsStage(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {boolean} dryRun 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} pageIndex 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncLeads(dryRun: boolean, fromDate: string, toDate: string, pageIndex: string, pageSize: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncLeadOutput>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).syncLeads(dryRun, fromDate, toDate, pageIndex, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} crnId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSingleLead(crnId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).syncSingleLead(crnId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLead(prospectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadMgmtApiAxiosParamCreator(configuration).updateLead(prospectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeadMgmtApi - factory interface
 * @export
 */
export const LeadMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLeadDemoClass(classMasterId: number, leadId: number, options?: any): AxiosPromise<Lead> {
            return LeadMgmtApiFp(configuration).assignLeadDemoClass(classMasterId, leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to cancel the scheduled demo class for lead
         * @param {number} classMasterId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelLeadDemoClass(classMasterId: number, leadId: number, options?: any): AxiosPromise<DemoClassStudent> {
            return LeadMgmtApiFp(configuration).cancelLeadDemoClass(classMasterId, leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get the list of demo classes
         * @param {string} [date] 
         * @param {Array&lt;string&gt;} [programs] 
         * @param {Array&lt;string&gt;} [subjects] 
         * @param {Array&lt;string&gt;} [teachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoClasses(date?: string, programs?: Array<string>, subjects?: Array<string>, teachers?: Array<string>, options?: any): AxiosPromise<Array<ClassMaster>> {
            return LeadMgmtApiFp(configuration).demoClasses(date, programs, subjects, teachers, options).then((request) => request(axios, basePath));
        },
        /**
         * Get excel file of list of leads.
         * @summary download_list_leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadListLeads(body: ListLeadsParams, options?: any): AxiosPromise<string> {
            return LeadMgmtApiFp(configuration).downloadListLeads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to get the details of the lead
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLead(leadId: number, options?: any): AxiosPromise<Lead> {
            return LeadMgmtApiFp(configuration).getLead(leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastSync(options?: any): AxiosPromise<LeadSyncHistory> {
            return LeadMgmtApiFp(configuration).lastSync(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the leads
         * @param {ListLeadsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeads(body: ListLeadsParams, options?: any): AxiosPromise<ListLeadsOutput> {
            return LeadMgmtApiFp(configuration).listLeads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the leads assignee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsAssignee(options?: any): AxiosPromise<Array<LeadManager>> {
            return LeadMgmtApiFp(configuration).listLeadsAssignee(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the leads source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsSource(options?: any): AxiosPromise<Array<LeadSource>> {
            return LeadMgmtApiFp(configuration).listLeadsSource(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the leads stage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsStage(options?: any): AxiosPromise<Array<LeadStage>> {
            return LeadMgmtApiFp(configuration).listLeadsStage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {boolean} dryRun 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} pageIndex 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLeads(dryRun: boolean, fromDate: string, toDate: string, pageIndex: string, pageSize: string, options?: any): AxiosPromise<SyncLeadOutput> {
            return LeadMgmtApiFp(configuration).syncLeads(dryRun, fromDate, toDate, pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} crnId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleLead(crnId: string, options?: any): AxiosPromise<Lead> {
            return LeadMgmtApiFp(configuration).syncSingleLead(crnId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to sync the leads
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLead(prospectId: string, options?: any): AxiosPromise<Lead> {
            return LeadMgmtApiFp(configuration).updateLead(prospectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadMgmtApi - object-oriented interface
 * @export
 * @class LeadMgmtApi
 * @extends {BaseAPI}
 */
export class LeadMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api to get the list of demo classes
     * @param {number} classMasterId 
     * @param {number} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public assignLeadDemoClass(classMasterId: number, leadId: number, options?: any) {
        return LeadMgmtApiFp(this.configuration).assignLeadDemoClass(classMasterId, leadId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to cancel the scheduled demo class for lead
     * @param {number} classMasterId 
     * @param {number} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public cancelLeadDemoClass(classMasterId: number, leadId: number, options?: any) {
        return LeadMgmtApiFp(this.configuration).cancelLeadDemoClass(classMasterId, leadId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get the list of demo classes
     * @param {string} [date] 
     * @param {Array&lt;string&gt;} [programs] 
     * @param {Array&lt;string&gt;} [subjects] 
     * @param {Array&lt;string&gt;} [teachers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public demoClasses(date?: string, programs?: Array<string>, subjects?: Array<string>, teachers?: Array<string>, options?: any) {
        return LeadMgmtApiFp(this.configuration).demoClasses(date, programs, subjects, teachers, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get excel file of list of leads.
     * @summary download_list_leads
     * @param {ListLeadsParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public downloadListLeads(body: ListLeadsParams, options?: any) {
        return LeadMgmtApiFp(this.configuration).downloadListLeads(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to get the details of the lead
     * @param {number} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public getLead(leadId: number, options?: any) {
        return LeadMgmtApiFp(this.configuration).getLead(leadId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the leads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public lastSync(options?: any) {
        return LeadMgmtApiFp(this.configuration).lastSync(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the leads
     * @param {ListLeadsParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public listLeads(body: ListLeadsParams, options?: any) {
        return LeadMgmtApiFp(this.configuration).listLeads(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the leads assignee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public listLeadsAssignee(options?: any) {
        return LeadMgmtApiFp(this.configuration).listLeadsAssignee(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the leads source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public listLeadsSource(options?: any) {
        return LeadMgmtApiFp(this.configuration).listLeadsSource(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the leads stage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public listLeadsStage(options?: any) {
        return LeadMgmtApiFp(this.configuration).listLeadsStage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to sync the leads
     * @param {boolean} dryRun 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {string} pageIndex 
     * @param {string} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public syncLeads(dryRun: boolean, fromDate: string, toDate: string, pageIndex: string, pageSize: string, options?: any) {
        return LeadMgmtApiFp(this.configuration).syncLeads(dryRun, fromDate, toDate, pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to sync the leads
     * @param {string} crnId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public syncSingleLead(crnId: string, options?: any) {
        return LeadMgmtApiFp(this.configuration).syncSingleLead(crnId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to sync the leads
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadMgmtApi
     */
    public updateLead(prospectId: string, options?: any) {
        return LeadMgmtApiFp(this.configuration).updateLead(prospectId, options).then((request) => request(this.axios, this.basePath));
    }
}
