import * as Yup from 'yup'

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .matches(
      /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    ),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must not be more than 50 characters')
    .required('Password is required'),
})

export const otpFormValidationSchema = Yup.object().shape({
  phoneNo: Yup.string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Phone No. must be at least 6 characters')
    .max(12, 'Phone No. must not be more than 12 characters')
    .required('Phone No. is required'),
})

export const forgetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .matches(
      /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    )
    .email('Email is invalid'),
})

export const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must not be more than 50 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .max(50, 'Password must not be more than 50 characters')
    .required('Confirm Password is required'),
})

export const internalChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must not be more than 50 characters')
    .required('Password is required'),
  password: Yup.string()
    .notOneOf([Yup.ref('oldPassword'), null], 'Old Password and new password should be different')
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must not be more than 50 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must not be more than 50 characters')
    .required('Confirm Password is required'),
})

export const adminFormValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string()
    .required('First name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'First name must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Last name must be at least 2 characters'),
  phoneNo: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Phone no. must be at least 6 characters')
    .max(12, 'Phone no. must not be more than 12 characters'),
  role: Yup.string().required('User type is required'),
})

export const addProgramGroupValidationSchema = Yup.object().shape({
  programLevel: Yup.string()
    .required('Program name is required')
    .min(2, 'Program name must be at least 2 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(3, 'Description must be at least 3 characters'),
})

export const addProgramValidationSchema = Yup.object().shape({
  grade: Yup.array().required('Grade is required'),
  programName: Yup.string()
    .required('Program name is required')
    .matches(/^[aA-zZ0-9\s]+$/, 'Only alpha-numeric are allowed for this field ')
    .min(3, 'Program name must be at least 3 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(3, 'Description must be at least 3 characters'),
})

export const studentFormvalidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .matches(
      /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    ),
  firstName: Yup.string()
    // .required('First name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'First name must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Last name must be at least 2 characters'),
  // parentFirstName: Yup.string()
  //   .required('First name is required')
  //   .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
  //   .min(3, 'First name must be at least 3 characters'),
  // parentlastName: Yup.string()
  //   .required('Last name is required')
  //   .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
  //   .min(3, 'Last name must be at least 3 characters'),
  phoneNo: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Phone no. must be at least 6 characters')
    .max(12, 'Phone no. must not be more than 12 characters'),
  Timezone: Yup.string().required('Timezone is required'),
})

export const teacherFormvalidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .matches(
      /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    ),
  firstName: Yup.string()
    .required('First name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'First name must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Last name must be at least 2 characters'),
  phoneNo: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Phone no. must be at least 6 characters')
    .max(12, 'Phone no. must not be more than 12 characters'),
})

export const subjectMasterFormValidation = Yup.object().shape({
  subjectName: Yup.string()
    .required('Subject name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Subject Name must be at least 2 characters'),
  noOfClasses: Yup.string()
    .required('No Of Classes is required')
    .min(1, 'No of classes must be at least 1'),
})

export const termFormValidation = Yup.object().shape({
  termName: Yup.string()
    .required('Term name is required')
    .matches(/^[aA-zZ0-9\s&'/]+$/, 'Only alpha numerics are allowed for this field ')
    .min(2, 'Term Name must be at least 2 characters'),
  description: Yup.string().required('Description is required'),
})

export const classFormValidation = Yup.object().shape({
  className: Yup.string()
    .required('Class name is required')
    .matches(/^[aA-zZ0-9\s']+$/, 'Only alpha numerics are allowed for this field ')
    .min(3, 'Class Name must be at least 3 characters'),
  classDescription: Yup.string().required('Description is required'),
})

export const classMasterFormValidation = Yup.object().shape({
  className: Yup.string()
    .required('Class name is required')
    .matches(/^[aA-zZ0-9\s']+$/, 'Only alpha numerics are allowed for this field ')
    .min(3, 'Class Name must be at least 3 characters'),
  description: Yup.string().required('Description is required'),
  studentCapacityMax: Yup.string()
    .required('No Of students is required')
    .max(100, 'No of students should be less than 100')
    .min(1, 'No of students must be at least 1'),
})

export const studentPaymentFormValidation = Yup.object().shape({
  paidAmount: Yup.number().required('Amount is required'),
  provider: Yup.string().required('Provider is required'),
})

export const batchFormValidationSchema = Yup.object().shape({
  batchName: Yup.string()
    .required('Batch name is required')
    .matches(/^[aA-zZ0-9\s']+$/, 'Only alpha numerics are allowed for this field ')
    .min(3, 'Batch Name must be at least 3 characters'),
  studentCapacityMax: Yup.string()
    .required('No Of students is required')
    .max(100, 'No of students should be less than 100')
    .min(1, 'No of students must be at least 1'),
})
