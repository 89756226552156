/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ListAdminsOutput } from '../models';
import { ListAdminsParams } from '../models';
import { ListAdminsParams1 } from '../models';
import { SignedUrl } from '../models';
import { User } from '../models';
import { UserTimeslotHistory } from '../models';
/**
 * UserMgmtApi - axios parameter creator
 * @export
 */
export const UserMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAdminProfile: async (uId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling activateAdminProfile.');
            }
            const localVarPath = `/admin/user-management/activate_admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {boolean} isRepeatWeeklyScheduleOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAvailability: async (body: UserTimeslotHistory, isRepeatWeeklyScheduleOn: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUserAvailability.');
            }
            // verify required parameter 'isRepeatWeeklyScheduleOn' is not null or undefined
            if (isRepeatWeeklyScheduleOn === null || isRepeatWeeklyScheduleOn === undefined) {
                throw new RequiredError('isRepeatWeeklyScheduleOn','Required parameter isRepeatWeeklyScheduleOn was null or undefined when calling createUserAvailability.');
            }
            const localVarPath = `/admin/user-management/user_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (isRepeatWeeklyScheduleOn !== undefined) {
                localVarQueryParameter['isRepeatWeeklyScheduleOn'] = isRepeatWeeklyScheduleOn;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewAdmin: async (body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createsNewAdmin.');
            }
            const localVarPath = `/admin/user-management/admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminProfile: async (uId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling deleteAdminProfile.');
            }
            const localVarPath = `/admin/user-management/admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get excel file of list of admins.
         * @summary download_list_admins
         * @param {ListAdminsParams1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadListAdmins: async (body: ListAdminsParams1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadListAdmins.');
            }
            const localVarPath = `/admin/user-management/download_list_admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminProfile: async (uId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling fetchAdminProfile.');
            }
            const localVarPath = `/admin/user-management/admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListAdminProfiles: async (role?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user-management/list_admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAvailability: async (uId: number, date: string, type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling getUserAvailability.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getUserAvailability.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getUserAvailability.');
            }
            const localVarPath = `/admin/user-management/user_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListAdminsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListAdminProfiles: async (body: ListAdminsParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postListAdminProfiles.');
            }
            const localVarPath = `/admin/user-management/list_admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling resendInvitation.');
            }
            const localVarPath = `/admin/user-management/resend_invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to suspend admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendAdminProfile: async (uId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling suspendAdminProfile.');
            }
            const localVarPath = `/admin/user-management/suspend_admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfile: async (body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAdminProfile.');
            }
            const localVarPath = `/admin/user-management/admin_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAvailability: async (body: UserTimeslotHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUserAvailability.');
            }
            const localVarPath = `/admin/user-management/user_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePic: async (profilePic: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling uploadProfilePic.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling uploadProfilePic.');
            }
            const localVarPath = `/admin/user-management/upload_profilepic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserMgmtApi - functional programming interface
 * @export
 */
export const UserMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAdminProfile(uId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).activateAdminProfile(uId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {boolean} isRepeatWeeklyScheduleOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserAvailability(body: UserTimeslotHistory, isRepeatWeeklyScheduleOn: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTimeslotHistory>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).createUserAvailability(body, isRepeatWeeklyScheduleOn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createsNewAdmin(body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).createsNewAdmin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminProfile(uId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).deleteAdminProfile(uId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get excel file of list of admins.
         * @summary download_list_admins
         * @param {ListAdminsParams1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadListAdmins(body: ListAdminsParams1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).downloadListAdmins(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminProfile(uId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).fetchAdminProfile(uId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListAdminProfiles(role?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).getListAdminProfiles(role, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAvailability(uId: number, date: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTimeslotHistory>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).getUserAvailability(uId, date, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListAdminsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postListAdminProfiles(body: ListAdminsParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdminsOutput>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).postListAdminProfiles(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInvitation(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).resendInvitation(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to suspend admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendAdminProfile(uId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).suspendAdminProfile(uId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminProfile(body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).updateAdminProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAvailability(body: UserTimeslotHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTimeslotHistory>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).updateUserAvailability(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilePic(profilePic: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await UserMgmtApiAxiosParamCreator(configuration).uploadProfilePic(profilePic, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserMgmtApi - factory interface
 * @export
 */
export const UserMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to activate admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAdminProfile(uId: number, options?: any): AxiosPromise<void> {
            return UserMgmtApiFp(configuration).activateAdminProfile(uId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {boolean} isRepeatWeeklyScheduleOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAvailability(body: UserTimeslotHistory, isRepeatWeeklyScheduleOn: boolean, options?: any): AxiosPromise<UserTimeslotHistory> {
            return UserMgmtApiFp(configuration).createUserAvailability(body, isRepeatWeeklyScheduleOn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewAdmin(body: User, options?: any): AxiosPromise<User> {
            return UserMgmtApiFp(configuration).createsNewAdmin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminProfile(uId: number, options?: any): AxiosPromise<void> {
            return UserMgmtApiFp(configuration).deleteAdminProfile(uId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get excel file of list of admins.
         * @summary download_list_admins
         * @param {ListAdminsParams1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadListAdmins(body: ListAdminsParams1, options?: any): AxiosPromise<string> {
            return UserMgmtApiFp(configuration).downloadListAdmins(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminProfile(uId: number, options?: any): AxiosPromise<User> {
            return UserMgmtApiFp(configuration).fetchAdminProfile(uId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListAdminProfiles(role?: string, options?: any): AxiosPromise<Array<User>> {
            return UserMgmtApiFp(configuration).getListAdminProfiles(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAvailability(uId: number, date: string, type: string, options?: any): AxiosPromise<UserTimeslotHistory> {
            return UserMgmtApiFp(configuration).getUserAvailability(uId, date, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListAdminsParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListAdminProfiles(body: ListAdminsParams, options?: any): AxiosPromise<ListAdminsOutput> {
            return UserMgmtApiFp(configuration).postListAdminProfiles(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new admin
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation(email: string, options?: any): AxiosPromise<void> {
            return UserMgmtApiFp(configuration).resendInvitation(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to suspend admin
         * @param {number} uId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendAdminProfile(uId: number, options?: any): AxiosPromise<void> {
            return UserMgmtApiFp(configuration).suspendAdminProfile(uId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {User} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminProfile(body: User, options?: any): AxiosPromise<User> {
            return UserMgmtApiFp(configuration).updateAdminProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to add details of user timeslot
         * @param {UserTimeslotHistory} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAvailability(body: UserTimeslotHistory, options?: any): AxiosPromise<UserTimeslotHistory> {
            return UserMgmtApiFp(configuration).updateUserAvailability(body, options).then((request) => request(axios, basePath));
        },
        /**
         * profile pic upload for user
         * @summary upload profile pic of the User
         * @param {string} profilePic 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePic(profilePic: string, email: string, options?: any): AxiosPromise<SignedUrl> {
            return UserMgmtApiFp(configuration).uploadProfilePic(profilePic, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserMgmtApi - object-oriented interface
 * @export
 * @class UserMgmtApi
 * @extends {BaseAPI}
 */
export class UserMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api to activate admin
     * @param {number} uId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public activateAdminProfile(uId: number, options?: any) {
        return UserMgmtApiFp(this.configuration).activateAdminProfile(uId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to add details of user timeslot
     * @param {UserTimeslotHistory} body 
     * @param {boolean} isRepeatWeeklyScheduleOn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public createUserAvailability(body: UserTimeslotHistory, isRepeatWeeklyScheduleOn: boolean, options?: any) {
        return UserMgmtApiFp(this.configuration).createUserAvailability(body, isRepeatWeeklyScheduleOn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new admin
     * @param {User} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public createsNewAdmin(body: User, options?: any) {
        return UserMgmtApiFp(this.configuration).createsNewAdmin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete the details of admin
     * @param {number} uId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public deleteAdminProfile(uId: number, options?: any) {
        return UserMgmtApiFp(this.configuration).deleteAdminProfile(uId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get excel file of list of admins.
     * @summary download_list_admins
     * @param {ListAdminsParams1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public downloadListAdmins(body: ListAdminsParams1, options?: any) {
        return UserMgmtApiFp(this.configuration).downloadListAdmins(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to fetch existing admin
     * @param {number} uId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public fetchAdminProfile(uId: number, options?: any) {
        return UserMgmtApiFp(this.configuration).fetchAdminProfile(uId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the admin
     * @param {string} [role] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public getListAdminProfiles(role?: string, options?: any) {
        return UserMgmtApiFp(this.configuration).getListAdminProfiles(role, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to add details of user timeslot
     * @param {number} uId 
     * @param {string} date 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public getUserAvailability(uId: number, date: string, type: string, options?: any) {
        return UserMgmtApiFp(this.configuration).getUserAvailability(uId, date, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the admin
     * @param {ListAdminsParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public postListAdminProfiles(body: ListAdminsParams, options?: any) {
        return UserMgmtApiFp(this.configuration).postListAdminProfiles(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new admin
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public resendInvitation(email: string, options?: any) {
        return UserMgmtApiFp(this.configuration).resendInvitation(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to suspend admin
     * @param {number} uId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public suspendAdminProfile(uId: number, options?: any) {
        return UserMgmtApiFp(this.configuration).suspendAdminProfile(uId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update the details of admin
     * @param {User} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public updateAdminProfile(body: User, options?: any) {
        return UserMgmtApiFp(this.configuration).updateAdminProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to add details of user timeslot
     * @param {UserTimeslotHistory} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public updateUserAvailability(body: UserTimeslotHistory, options?: any) {
        return UserMgmtApiFp(this.configuration).updateUserAvailability(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * profile pic upload for user
     * @summary upload profile pic of the User
     * @param {string} profilePic 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMgmtApi
     */
    public uploadProfilePic(profilePic: string, email: string, options?: any) {
        return UserMgmtApiFp(this.configuration).uploadProfilePic(profilePic, email, options).then((request) => request(this.axios, this.basePath));
    }
}
