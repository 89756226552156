import {
  Button,
  Box,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import moment from 'moment'

function KidPersonalDetails({ handleNext }: any) {
  const [dob, setDob] = useState<any>(moment())
  const [age, setAge] = useState('')
  const [relation, setRelation] = useState('')

  const ageChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string)
  }

  const relationChange = (event: SelectChangeEvent) => {
    setRelation(event.target.value as string)
  }
  return (
    <Fragment>
      <form>
        <h2 className="form-title">Student details</h2>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="firstName"
                fullWidth={true}
                id="firstName"
                label="First name"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="lastName"
                fullWidth={true}
                id="lastName"
                label="Last name"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date of Birth"
                  value={dob}
                  onChange={(newValue) => {
                    setDob(newValue)
                  }}
                  maxDate={moment().add(-3, 'years')}
                  renderInput={(dateParams) => <TextField {...dateParams} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <InputLabel id="age-select-label">Age</InputLabel>
              <Select
                labelId="age-select-label"
                id="age-select"
                value={age}
                label="Age"
                onChange={ageChange}
              >
                <MenuItem value={20}>5-10</MenuItem>
                <MenuItem value={10}>10-15</MenuItem>
                <MenuItem value={30}>15-20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* container */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup row={true} aria-label="gender" name="row-radio-buttons-group">
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        <h2 className="form-title">Parent Details</h2>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="parentFirstName"
                fullWidth={true}
                id="parentFirstName"
                label="First name"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="parentLastName"
                fullWidth={true}
                id="parentLastName"
                label="Last name"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <InputLabel id="relation-select-label">Relationship with Kid</InputLabel>
              <Select
                labelId="relation-select-label"
                id="relation-select"
                value={relation}
                label="Age"
                onChange={relationChange}
              >
                <MenuItem value={20}>Father</MenuItem>
                <MenuItem value={10}>Mother</MenuItem>
                <MenuItem value={30}>Gaurdian</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true} md={6} />
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="parentEmail"
                fullWidth={true}
                id="parentEmail"
                label="Email"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="parentPhoneNo"
                fullWidth={true}
                id="parentPhoneNo"
                label="Phone No."
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 6 */}
        </Grid>
        {/* container */}
        <Box sx={{ mt: 3 }} textAlign={'center'}>
          <Button variant="outlined" color="primary" onClick={handleNext}>
            Next
          </Button>
        </Box>
      </form>
    </Fragment>
  )
}

export default KidPersonalDetails
