/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ListTeacherOutput } from '../models';
import { ListTeachersParams } from '../models';
import { Teacher } from '../models';
import { TeacherSubject } from '../models';
import { UserTimeslot } from '../models';
/**
 * TeacherMgmtApi - axios parameter creator
 * @export
 */
export const TeacherMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate teacher
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTeacherProfile: async (teacherId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling activateTeacherProfile.');
            }
            const localVarPath = `/admin/teacher-management/activate_teacher_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to resend invitation to teacher
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendInvitation: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling adminResendInvitation.');
            }
            const localVarPath = `/admin/teacher-management/resend_invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacher: async (body: Teacher, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTeacher.');
            }
            const localVarPath = `/admin/teacher-management/teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new teacher subject
         * @param {TeacherSubject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherSubject: async (body: TeacherSubject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTeacherSubject.');
            }
            const localVarPath = `/admin/teacher-management/teacher_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacher: async (teacherId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling deleteTeacher.');
            }
            const localVarPath = `/admin/teacher-management/teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete the details of teacher subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherSubject: async (teacherSubjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherSubjectId' is not null or undefined
            if (teacherSubjectId === null || teacherSubjectId === undefined) {
                throw new RequiredError('teacherSubjectId','Required parameter teacherSubjectId was null or undefined when calling deleteTeacherSubject.');
            }
            const localVarPath = `/admin/teacher-management/teacher_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherSubjectId !== undefined) {
                localVarQueryParameter['teacherSubjectId'] = teacherSubjectId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTeacher: async (teacherId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling fetchTeacher.');
            }
            const localVarPath = `/admin/teacher-management/teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get teacher_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherTimeslot: async (uId: number, date: string, timezone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling getTeacherTimeslot.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getTeacherTimeslot.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling getTeacherTimeslot.');
            }
            const localVarPath = `/admin/teacher-management/teacher_timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListTeachersParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacherPost: async (body: ListTeachersParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listTeacherPost.');
            }
            const localVarPath = `/admin/teacher-management/list_teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api post teacher_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTimeslot: async (body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling teacherTimeslot.');
            }
            // verify required parameter 'uId' is not null or undefined
            if (uId === null || uId === undefined) {
                throw new RequiredError('uId','Required parameter uId was null or undefined when calling teacherTimeslot.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling teacherTimeslot.');
            }
            const localVarPath = `/admin/teacher-management/teacher_timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uId !== undefined) {
                localVarQueryParameter['uId'] = uId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (isRepeatWeeklyScheduleOn !== undefined) {
                localVarQueryParameter['isRepeatWeeklyScheduleOn'] = isRepeatWeeklyScheduleOn;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacher: async (body: Teacher, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTeacher.');
            }
            const localVarPath = `/admin/teacher-management/teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeacherMgmtApi - functional programming interface
 * @export
 */
export const TeacherMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api to activate teacher
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTeacherProfile(teacherId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).activateTeacherProfile(teacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to resend invitation to teacher
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResendInvitation(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).adminResendInvitation(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeacher(body: Teacher, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).createTeacher(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new teacher subject
         * @param {TeacherSubject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeacherSubject(body: TeacherSubject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherSubject>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).createTeacherSubject(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeacher(teacherId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).deleteTeacher(teacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete the details of teacher subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeacherSubject(teacherSubjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).deleteTeacherSubject(teacherSubjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTeacher(teacherId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).fetchTeacher(teacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get teacher_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherTimeslot(uId: number, date: string, timezone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).getTeacherTimeslot(uId, date, timezone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListTeachersParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeacherPost(body: ListTeachersParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTeacherOutput>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).listTeacherPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api post teacher_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserTimeslot>>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).teacherTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeacher(body: Teacher, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await TeacherMgmtApiAxiosParamCreator(configuration).updateTeacher(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TeacherMgmtApi - factory interface
 * @export
 */
export const TeacherMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api to activate teacher
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTeacherProfile(teacherId: number, options?: any): AxiosPromise<Teacher> {
            return TeacherMgmtApiFp(configuration).activateTeacherProfile(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to resend invitation to teacher
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendInvitation(email: string, options?: any): AxiosPromise<void> {
            return TeacherMgmtApiFp(configuration).adminResendInvitation(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacher(body: Teacher, options?: any): AxiosPromise<Teacher> {
            return TeacherMgmtApiFp(configuration).createTeacher(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new teacher subject
         * @param {TeacherSubject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherSubject(body: TeacherSubject, options?: any): AxiosPromise<TeacherSubject> {
            return TeacherMgmtApiFp(configuration).createTeacherSubject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete the details of admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacher(teacherId: number, options?: any): AxiosPromise<void> {
            return TeacherMgmtApiFp(configuration).deleteTeacher(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete the details of teacher subject
         * @param {number} teacherSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherSubject(teacherSubjectId: number, options?: any): AxiosPromise<void> {
            return TeacherMgmtApiFp(configuration).deleteTeacherSubject(teacherSubjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to fetch existing admin
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTeacher(teacherId: number, options?: any): AxiosPromise<Teacher> {
            return TeacherMgmtApiFp(configuration).fetchTeacher(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get teacher_timeslot
         * @param {number} uId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherTimeslot(uId: number, date: string, timezone: string, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return TeacherMgmtApiFp(configuration).getTeacherTimeslot(uId, date, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to list all the admin
         * @param {ListTeachersParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacherPost(body: ListTeachersParams, options?: any): AxiosPromise<ListTeacherOutput> {
            return TeacherMgmtApiFp(configuration).listTeacherPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api post teacher_timeslot
         * @param {Array&lt;UserTimeslot&gt;} body 
         * @param {number} uId 
         * @param {string} timezone 
         * @param {boolean} [isRepeatWeeklyScheduleOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any): AxiosPromise<Array<UserTimeslot>> {
            return TeacherMgmtApiFp(configuration).teacherTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update the details of admin
         * @param {Teacher} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacher(body: Teacher, options?: any): AxiosPromise<Teacher> {
            return TeacherMgmtApiFp(configuration).updateTeacher(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeacherMgmtApi - object-oriented interface
 * @export
 * @class TeacherMgmtApi
 * @extends {BaseAPI}
 */
export class TeacherMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api to activate teacher
     * @param {number} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public activateTeacherProfile(teacherId: number, options?: any) {
        return TeacherMgmtApiFp(this.configuration).activateTeacherProfile(teacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to resend invitation to teacher
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public adminResendInvitation(email: string, options?: any) {
        return TeacherMgmtApiFp(this.configuration).adminResendInvitation(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new admin
     * @param {Teacher} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public createTeacher(body: Teacher, options?: any) {
        return TeacherMgmtApiFp(this.configuration).createTeacher(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new teacher subject
     * @param {TeacherSubject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public createTeacherSubject(body: TeacherSubject, options?: any) {
        return TeacherMgmtApiFp(this.configuration).createTeacherSubject(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete the details of admin
     * @param {number} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public deleteTeacher(teacherId: number, options?: any) {
        return TeacherMgmtApiFp(this.configuration).deleteTeacher(teacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete the details of teacher subject
     * @param {number} teacherSubjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public deleteTeacherSubject(teacherSubjectId: number, options?: any) {
        return TeacherMgmtApiFp(this.configuration).deleteTeacherSubject(teacherSubjectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to fetch existing admin
     * @param {number} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public fetchTeacher(teacherId: number, options?: any) {
        return TeacherMgmtApiFp(this.configuration).fetchTeacher(teacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get teacher_timeslot
     * @param {number} uId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public getTeacherTimeslot(uId: number, date: string, timezone: string, options?: any) {
        return TeacherMgmtApiFp(this.configuration).getTeacherTimeslot(uId, date, timezone, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to list all the admin
     * @param {ListTeachersParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public listTeacherPost(body: ListTeachersParams, options?: any) {
        return TeacherMgmtApiFp(this.configuration).listTeacherPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api post teacher_timeslot
     * @param {Array&lt;UserTimeslot&gt;} body 
     * @param {number} uId 
     * @param {string} timezone 
     * @param {boolean} [isRepeatWeeklyScheduleOn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public teacherTimeslot(body: Array<UserTimeslot>, uId: number, timezone: string, isRepeatWeeklyScheduleOn?: boolean, options?: any) {
        return TeacherMgmtApiFp(this.configuration).teacherTimeslot(body, uId, timezone, isRepeatWeeklyScheduleOn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update the details of admin
     * @param {Teacher} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeacherMgmtApi
     */
    public updateTeacher(body: Teacher, options?: any) {
        return TeacherMgmtApiFp(this.configuration).updateTeacher(body, options).then((request) => request(this.axios, this.basePath));
    }
}
