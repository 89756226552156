/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BatchClass } from '../models';
import { BatchMaster } from '../models';
import { ClassMaster } from '../models';
import { ClassMasterDocument } from '../models';
import { DemoClass } from '../models';
import { DemoClassStudent } from '../models';
import { Lead } from '../models';
import { ListClassesOutput } from '../models';
import { ListClassesParams } from '../models';
import { Student } from '../models';
import { StudentClass } from '../models';
import { Teacher } from '../models';
/**
 * ClassMgmtApi - axios parameter creator
 * @export
 */
export const ClassMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadToDemo: async (demoClassId: number, leadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demoClassId' is not null or undefined
            if (demoClassId === null || demoClassId === undefined) {
                throw new RequiredError('demoClassId','Required parameter demoClassId was null or undefined when calling addLeadToDemo.');
            }
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling addLeadToDemo.');
            }
            const localVarPath = `/admin/class-management/add_lead_to_demo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (demoClassId !== undefined) {
                localVarQueryParameter['demoClassId'] = demoClassId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignClassTeacher: async (classMasterId: number, teacherId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling assignClassTeacher.');
            }
            // verify required parameter 'teacherId' is not null or undefined
            if (teacherId === null || teacherId === undefined) {
                throw new RequiredError('teacherId','Required parameter teacherId was null or undefined when calling assignClassTeacher.');
            }
            const localVarPath = `/admin/class-management/assign_class_teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;DemoClassStudent&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDemoClassLeads: async (body: Array<DemoClassStudent>, classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling assignDemoClassLeads.');
            }
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling assignDemoClassLeads.');
            }
            const localVarPath = `/admin/class-management/assign_demo_class_leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;StudentClass&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRegularClassStudents: async (body: Array<StudentClass>, classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling assignRegularClassStudents.');
            }
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling assignRegularClassStudents.');
            }
            const localVarPath = `/admin/class-management/assign_regular_class_students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling cancelClassMaster.');
            }
            const localVarPath = `/admin/class-management/cancel_class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassMaster: async (body: ClassMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createClassMaster.');
            }
            const localVarPath = `/admin/class-management/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested class master
         * @param {ClassMasterDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassMasterDocument: async (body: ClassMasterDocument, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createClassMasterDocument.');
            }
            const localVarPath = `/admin/class-management/class_master_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling deleteClassMaster.');
            }
            const localVarPath = `/admin/class-management/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete requested class master document
         * @param {number} classDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassMasterDocument: async (classDocId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classDocId' is not null or undefined
            if (classDocId === null || classDocId === undefined) {
                throw new RequiredError('classDocId','Required parameter classDocId was null or undefined when calling deleteClassMasterDocument.');
            }
            const localVarPath = `/admin/class-management/class_master_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classDocId !== undefined) {
                localVarQueryParameter['classDocId'] = classDocId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} demoClassStuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadFromDemo: async (demoClassId: number, demoClassStuId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demoClassId' is not null or undefined
            if (demoClassId === null || demoClassId === undefined) {
                throw new RequiredError('demoClassId','Required parameter demoClassId was null or undefined when calling deleteLeadFromDemo.');
            }
            // verify required parameter 'demoClassStuId' is not null or undefined
            if (demoClassStuId === null || demoClassStuId === undefined) {
                throw new RequiredError('demoClassStuId','Required parameter demoClassStuId was null or undefined when calling deleteLeadFromDemo.');
            }
            const localVarPath = `/admin/class-management/delete_lead_from_demo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (demoClassId !== undefined) {
                localVarQueryParameter['demoClassId'] = demoClassId;
            }

            if (demoClassStuId !== undefined) {
                localVarQueryParameter['demoClassStuId'] = demoClassStuId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getClassMaster.');
            }
            const localVarPath = `/admin/class-management/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMasterBatchClass: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getClassMasterBatchClass.');
            }
            const localVarPath = `/admin/class-management/class_master_batch_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMasterDemoClass: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getClassMasterDemoClass.');
            }
            const localVarPath = `/admin/class-management/class_master_demo_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassBatches: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/class-management/list_class_batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClasses: async (body: ListClassesParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listClasses.');
            }
            const localVarPath = `/admin/class-management/list_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableLeads: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling loadClassAvailableLeads.');
            }
            const localVarPath = `/admin/class-management/load_available_leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the students
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableStudents: async (timezone: string, classMasterId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling loadClassAvailableStudents.');
            }
            const localVarPath = `/admin/class-management/load_available_students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the teachers
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableTeachers: async (timezone: string, classMasterId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone','Required parameter timezone was null or undefined when calling loadClassAvailableTeachers.');
            }
            const localVarPath = `/admin/class-management/load_available_teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {BatchClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchClass: async (body: BatchClass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBatchClass.');
            }
            const localVarPath = `/admin/class-management/batch_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassMaster: async (body: ClassMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateClassMaster.');
            }
            const localVarPath = `/admin/class-management/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {DemoClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemoClass: async (body: DemoClass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDemoClass.');
            }
            const localVarPath = `/admin/class-management/demo_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassMgmtApi - functional programming interface
 * @export
 */
export const ClassMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLeadToDemo(demoClassId: number, leadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoClassStudent>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).addLeadToDemo(demoClassId, leadId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignClassTeacher(classMasterId: number, teacherId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).assignClassTeacher(classMasterId, teacherId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;DemoClassStudent&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDemoClassLeads(body: Array<DemoClassStudent>, classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemoClassStudent>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).assignDemoClassLeads(body, classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;StudentClass&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRegularClassStudents(body: Array<StudentClass>, classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentClass>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).assignRegularClassStudents(body, classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).cancelClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClassMaster(body: ClassMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).createClassMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested class master
         * @param {ClassMasterDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClassMasterDocument(body: ClassMasterDocument, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMasterDocument>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).createClassMasterDocument(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).deleteClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete requested class master document
         * @param {number} classDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClassMasterDocument(classDocId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).deleteClassMasterDocument(classDocId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} demoClassStuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeadFromDemo(demoClassId: number, demoClassStuId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).deleteLeadFromDemo(demoClassId, demoClassStuId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).getClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassMasterBatchClass(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchClass>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).getClassMasterBatchClass(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassMasterDemoClass(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoClass>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).getClassMasterDemoClass(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassBatches(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchMaster>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).listClassBatches(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClasses(body: ListClassesParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClassesOutput>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).listClasses(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadClassAvailableLeads(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lead>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).loadClassAvailableLeads(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the students
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadClassAvailableStudents(timezone: string, classMasterId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Student>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).loadClassAvailableStudents(timezone, classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the teachers
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadClassAvailableTeachers(timezone: string, classMasterId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Teacher>>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).loadClassAvailableTeachers(timezone, classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {BatchClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchClass(body: BatchClass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchClass>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).updateBatchClass(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClassMaster(body: ClassMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).updateClassMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {DemoClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDemoClass(body: DemoClass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoClass>> {
            const localVarAxiosArgs = await ClassMgmtApiAxiosParamCreator(configuration).updateDemoClass(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClassMgmtApi - factory interface
 * @export
 */
export const ClassMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadToDemo(demoClassId: number, leadId: number, options?: any): AxiosPromise<DemoClassStudent> {
            return ClassMgmtApiFp(configuration).addLeadToDemo(demoClassId, leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {number} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignClassTeacher(classMasterId: number, teacherId: number, options?: any): AxiosPromise<ClassMaster> {
            return ClassMgmtApiFp(configuration).assignClassTeacher(classMasterId, teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;DemoClassStudent&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDemoClassLeads(body: Array<DemoClassStudent>, classMasterId: number, options?: any): AxiosPromise<Array<DemoClassStudent>> {
            return ClassMgmtApiFp(configuration).assignDemoClassLeads(body, classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {Array&lt;StudentClass&gt;} body 
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRegularClassStudents(body: Array<StudentClass>, classMasterId: number, options?: any): AxiosPromise<Array<StudentClass>> {
            return ClassMgmtApiFp(configuration).assignRegularClassStudents(body, classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClassMaster(classMasterId: number, options?: any): AxiosPromise<ClassMaster> {
            return ClassMgmtApiFp(configuration).cancelClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassMaster(body: ClassMaster, options?: any): AxiosPromise<ClassMaster> {
            return ClassMgmtApiFp(configuration).createClassMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested class master
         * @param {ClassMasterDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassMasterDocument(body: ClassMasterDocument, options?: any): AxiosPromise<ClassMasterDocument> {
            return ClassMgmtApiFp(configuration).createClassMasterDocument(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassMaster(classMasterId: number, options?: any): AxiosPromise<void> {
            return ClassMgmtApiFp(configuration).deleteClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete requested class master document
         * @param {number} classDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassMasterDocument(classDocId: number, options?: any): AxiosPromise<void> {
            return ClassMgmtApiFp(configuration).deleteClassMasterDocument(classDocId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} demoClassId 
         * @param {number} demoClassStuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadFromDemo(demoClassId: number, demoClassStuId: number, options?: any): AxiosPromise<void> {
            return ClassMgmtApiFp(configuration).deleteLeadFromDemo(demoClassId, demoClassStuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMaster(classMasterId: number, options?: any): AxiosPromise<ClassMaster> {
            return ClassMgmtApiFp(configuration).getClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMasterBatchClass(classMasterId: number, options?: any): AxiosPromise<BatchClass> {
            return ClassMgmtApiFp(configuration).getClassMasterBatchClass(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassMasterDemoClass(classMasterId: number, options?: any): AxiosPromise<DemoClass> {
            return ClassMgmtApiFp(configuration).getClassMasterDemoClass(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the batches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassBatches(options?: any): AxiosPromise<Array<BatchMaster>> {
            return ClassMgmtApiFp(configuration).listClassBatches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ListClassesParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClasses(body: ListClassesParams, options?: any): AxiosPromise<ListClassesOutput> {
            return ClassMgmtApiFp(configuration).listClasses(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the leads
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableLeads(classMasterId: number, options?: any): AxiosPromise<Array<Lead>> {
            return ClassMgmtApiFp(configuration).loadClassAvailableLeads(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the students
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableStudents(timezone: string, classMasterId?: number, options?: any): AxiosPromise<Array<Student>> {
            return ClassMgmtApiFp(configuration).loadClassAvailableStudents(timezone, classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the teachers
         * @param {string} timezone 
         * @param {number} [classMasterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClassAvailableTeachers(timezone: string, classMasterId?: number, options?: any): AxiosPromise<Array<Teacher>> {
            return ClassMgmtApiFp(configuration).loadClassAvailableTeachers(timezone, classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {BatchClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchClass(body: BatchClass, options?: any): AxiosPromise<BatchClass> {
            return ClassMgmtApiFp(configuration).updateBatchClass(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {ClassMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassMaster(body: ClassMaster, options?: any): AxiosPromise<ClassMaster> {
            return ClassMgmtApiFp(configuration).updateClassMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the classes
         * @param {DemoClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDemoClass(body: DemoClass, options?: any): AxiosPromise<DemoClass> {
            return ClassMgmtApiFp(configuration).updateDemoClass(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassMgmtApi - object-oriented interface
 * @export
 * @class ClassMgmtApi
 * @extends {BaseAPI}
 */
export class ClassMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} demoClassId 
     * @param {number} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public addLeadToDemo(demoClassId: number, leadId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).addLeadToDemo(demoClassId, leadId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {number} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public assignClassTeacher(classMasterId: number, teacherId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).assignClassTeacher(classMasterId, teacherId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {Array&lt;DemoClassStudent&gt;} body 
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public assignDemoClassLeads(body: Array<DemoClassStudent>, classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).assignDemoClassLeads(body, classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {Array&lt;StudentClass&gt;} body 
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public assignRegularClassStudents(body: Array<StudentClass>, classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).assignRegularClassStudents(body, classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public cancelClassMaster(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).cancelClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {ClassMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public createClassMaster(body: ClassMaster, options?: any) {
        return ClassMgmtApiFp(this.configuration).createClassMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested class master
     * @param {ClassMasterDocument} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public createClassMasterDocument(body: ClassMasterDocument, options?: any) {
        return ClassMgmtApiFp(this.configuration).createClassMasterDocument(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public deleteClassMaster(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).deleteClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete requested class master document
     * @param {number} classDocId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public deleteClassMasterDocument(classDocId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).deleteClassMasterDocument(classDocId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} demoClassId 
     * @param {number} demoClassStuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public deleteLeadFromDemo(demoClassId: number, demoClassStuId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).deleteLeadFromDemo(demoClassId, demoClassStuId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public getClassMaster(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).getClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public getClassMasterBatchClass(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).getClassMasterBatchClass(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public getClassMasterDemoClass(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).getClassMasterDemoClass(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the batches
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public listClassBatches(options?: any) {
        return ClassMgmtApiFp(this.configuration).listClassBatches(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {ListClassesParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public listClasses(body: ListClassesParams, options?: any) {
        return ClassMgmtApiFp(this.configuration).listClasses(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the leads
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public loadClassAvailableLeads(classMasterId: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).loadClassAvailableLeads(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the students
     * @param {string} timezone 
     * @param {number} [classMasterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public loadClassAvailableStudents(timezone: string, classMasterId?: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).loadClassAvailableStudents(timezone, classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the teachers
     * @param {string} timezone 
     * @param {number} [classMasterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public loadClassAvailableTeachers(timezone: string, classMasterId?: number, options?: any) {
        return ClassMgmtApiFp(this.configuration).loadClassAvailableTeachers(timezone, classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {BatchClass} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public updateBatchClass(body: BatchClass, options?: any) {
        return ClassMgmtApiFp(this.configuration).updateBatchClass(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {ClassMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public updateClassMaster(body: ClassMaster, options?: any) {
        return ClassMgmtApiFp(this.configuration).updateClassMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the classes
     * @param {DemoClass} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMgmtApi
     */
    public updateDemoClass(body: DemoClass, options?: any) {
        return ClassMgmtApiFp(this.configuration).updateDemoClass(body, options).then((request) => request(this.axios, this.basePath));
    }
}
