import { Card, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment } from 'react'
import KidEducationalDetails from './kid-educational-details'
import KidPersonalDetails from './kid-personal-details'

const steps = ['Personal details', 'Education details']
function KidProfileContainer() {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Fragment>
      <Box className="auth" sx={{ backgroundImage: 'url(/images/login-bg.svg)' }}>
        <Grid
          container={true}
          spacing="2"
          alignItems="center"
          justifyContent="end"
          className="mvh-100"
        >
          <Grid item={true} md={6} lg={5} xl={4}>
            <Card sx={{ px: 3, py: 4, boxShadow: 3 }} className="scrollable-form-card">
              <div className="logo">
                <img src="/images/logo.png" alt="" />
              </div>
              <h1 className="text-center">My kid's profile</h1>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: { optional?: React.ReactNode } = {}
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
                <Box className="card-body">
                  <Box>
                    {activeStep === 0 ? (
                      <KidPersonalDetails handleNext={handleNext} />
                    ) : (
                      <KidEducationalDetails handleBack={handleBack} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item={true} lg={1} />
        </Grid>
      </Box>
    </Fragment>
  )
}

export default KidProfileContainer
