import React from 'react'
// import { useAuth0 } from "@auth0/auth0-react";

export default function Logout() {
  return (
    <React.Fragment>
      <div>
        <button className="btn btn-primary">Log Out</button>
      </div>
    </React.Fragment>
  )
}
