import { ApiController } from '../../apiController'
import { Teacher, TeacherSubject, UserTimeslot } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { Dispatch } from 'redux'
import { snackbarAction, TeacherMgmtAction } from '../actions'

export const addTeacherAPI = (teacher: Teacher) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().teacherMgmtApi.createTeacher(teacher)
      dispatch({
        type: ActionType.ADD_TEACHER,
        addTeacher: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Teacher details added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getTeacherAPI = (teacherId: number) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().teacherMgmtApi.fetchTeacher(teacherId)
      dispatch({
        type: ActionType.GET_TEACHER,
        getTeacher: userData.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateTeacherAPI = (teacher: Teacher) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().teacherMgmtApi.updateTeacher(teacher)
      dispatch({
        type: ActionType.UPDATE_TEACHER,
        updateTeacher: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Teacher details updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addTeacherSubjectAPI = (teacherSubject: TeacherSubject) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      const userData = await ApiController.getInstance().teacherMgmtApi.createTeacherSubject(
        teacherSubject
      )
      dispatch({
        type: ActionType.ADD_TEACHER_SUBJECT,
        addTeacherSubject: userData.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Subject details added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const removeTeacherSubjectAPI = (teacherSubjectId: number) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      await (
        await ApiController.getInstance().teacherMgmtApi.deleteTeacherSubject(teacherSubjectId)
      ).data
      dispatch({
        type: ActionType.REMOVE_TEACHER_SUBJECT,
        teacherSubjectId,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Subject details removed successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const addTeacherTimeslotAPI = (
  timeslots: UserTimeslot[],
  uId: number,
  timeZoneOffset: string
) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await (
        await ApiController.getInstance().teacherMgmtApi.teacherTimeslot(
          timeslots,
          uId,
          timeZoneOffset,
          true
        )
      ).data
      dispatch({
        type: ActionType.ADD_TEACHER_TIMESLOT,
        usertimeslot: timeslotResponse,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details captured successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const getTeacherTimeslotAPI = (uId: number, queryDate: string, timeZone: string) => {
  return async (dispatch: Dispatch<TeacherMgmtAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await (
        await ApiController.getInstance().teacherMgmtApi.getTeacherTimeslot(
          uId,
          queryDate.toString(),
          timeZone
        )
      ).data
      dispatch({
        type: ActionType.GET_TEACHER_TIMESLOT,
        usertimeslot: timeslotResponse,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
