/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChapterMaster } from '../models';
import { GradeMaster } from '../models';
import { ListProgramMasterOutput } from '../models';
import { ListProgramMasterParams } from '../models';
import { ListTermOutput } from '../models';
import { ListTermParams } from '../models';
import { ProgramGroup } from '../models';
import { ProgramMaster } from '../models';
import { SubjectMaster } from '../models';
import { Term } from '../models';
import { TermClass } from '../models';
import { TermDocument } from '../models';
/**
 * ProgramMgmtApi - axios parameter creator
 * @export
 */
export const ProgramMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChapterMaster: async (body: ChapterMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createChapterMaster.');
            }
            const localVarPath = `/admin/program-management/chapter_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramMaster: async (body: ProgramMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createProgramMaster.');
            }
            const localVarPath = `/admin/program-management/program_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubjectMaster: async (body: SubjectMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSubjectMaster.');
            }
            const localVarPath = `/admin/program-management/subject_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermClass: async (body: TermClass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTermClass.');
            }
            const localVarPath = `/admin/program-management/term_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermDocument: async (body: TermDocument, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTermDocument.');
            }
            const localVarPath = `/admin/program-management/term_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermMaster: async (body: Term, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTermMaster.');
            }
            const localVarPath = `/admin/program-management/term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new grade_master
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewGradeMaster: async (body: GradeMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createsNewGradeMaster.');
            }
            const localVarPath = `/admin/program-management/grade_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to create new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewProgramGroup: async (body: ProgramGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createsNewProgramGroup.');
            }
            const localVarPath = `/admin/program-management/program_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChapterMaster: async (chapMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapMasterId' is not null or undefined
            if (chapMasterId === null || chapMasterId === undefined) {
                throw new RequiredError('chapMasterId','Required parameter chapMasterId was null or undefined when calling deleteChapterMaster.');
            }
            const localVarPath = `/admin/program-management/chapter_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (chapMasterId !== undefined) {
                localVarQueryParameter['chapMasterId'] = chapMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {string} gradeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGradeMaster: async (gradeName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradeName' is not null or undefined
            if (gradeName === null || gradeName === undefined) {
                throw new RequiredError('gradeName','Required parameter gradeName was null or undefined when calling deleteGradeMaster.');
            }
            const localVarPath = `/admin/program-management/grade_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (gradeName !== undefined) {
                localVarQueryParameter['gradeName'] = gradeName;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api delete detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramGroup: async (progGroupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'progGroupId' is not null or undefined
            if (progGroupId === null || progGroupId === undefined) {
                throw new RequiredError('progGroupId','Required parameter progGroupId was null or undefined when calling deleteProgramGroup.');
            }
            const localVarPath = `/admin/program-management/program_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (progGroupId !== undefined) {
                localVarQueryParameter['progGroupId'] = progGroupId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramMaster: async (programId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling deleteProgramMaster.');
            }
            const localVarPath = `/admin/program-management/program_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectMaster: async (subMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subMasterId' is not null or undefined
            if (subMasterId === null || subMasterId === undefined) {
                throw new RequiredError('subMasterId','Required parameter subMasterId was null or undefined when calling deleteSubjectMaster.');
            }
            const localVarPath = `/admin/program-management/subject_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subMasterId !== undefined) {
                localVarQueryParameter['subMasterId'] = subMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api delete detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermClass: async (termSubClassId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termSubClassId' is not null or undefined
            if (termSubClassId === null || termSubClassId === undefined) {
                throw new RequiredError('termSubClassId','Required parameter termSubClassId was null or undefined when calling deleteTermClass.');
            }
            const localVarPath = `/admin/program-management/term_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termSubClassId !== undefined) {
                localVarQueryParameter['termSubClassId'] = termSubClassId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term class chapter
         * @param {number} termClassChapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermClassChapter: async (termClassChapterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termClassChapterId' is not null or undefined
            if (termClassChapterId === null || termClassChapterId === undefined) {
                throw new RequiredError('termClassChapterId','Required parameter termClassChapterId was null or undefined when calling deleteTermClassChapter.');
            }
            const localVarPath = `/admin/program-management/term_class_chapter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termClassChapterId !== undefined) {
                localVarQueryParameter['termClassChapterId'] = termClassChapterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term document
         * @param {number} termDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermDocument: async (termDocId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termDocId' is not null or undefined
            if (termDocId === null || termDocId === undefined) {
                throw new RequiredError('termDocId','Required parameter termDocId was null or undefined when calling deleteTermDocument.');
            }
            const localVarPath = `/admin/program-management/term_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termDocId !== undefined) {
                localVarQueryParameter['termDocId'] = termDocId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermMaster: async (termId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            if (termId === null || termId === undefined) {
                throw new RequiredError('termId','Required parameter termId was null or undefined when calling deleteTermMaster.');
            }
            const localVarPath = `/admin/program-management/term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termId !== undefined) {
                localVarQueryParameter['termId'] = termId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term subject
         * @param {number} termSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermSubject: async (termSubjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termSubjectId' is not null or undefined
            if (termSubjectId === null || termSubjectId === undefined) {
                throw new RequiredError('termSubjectId','Required parameter termSubjectId was null or undefined when calling deleteTermSubject.');
            }
            const localVarPath = `/admin/program-management/term_subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termSubjectId !== undefined) {
                localVarQueryParameter['termSubjectId'] = termSubjectId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChapterMaster: async (chapMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapMasterId' is not null or undefined
            if (chapMasterId === null || chapMasterId === undefined) {
                throw new RequiredError('chapMasterId','Required parameter chapMasterId was null or undefined when calling getChapterMaster.');
            }
            const localVarPath = `/admin/program-management/chapter_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (chapMasterId !== undefined) {
                localVarQueryParameter['chapMasterId'] = chapMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramGroup: async (progGroupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'progGroupId' is not null or undefined
            if (progGroupId === null || progGroupId === undefined) {
                throw new RequiredError('progGroupId','Required parameter progGroupId was null or undefined when calling getProgramGroup.');
            }
            const localVarPath = `/admin/program-management/program_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (progGroupId !== undefined) {
                localVarQueryParameter['progGroupId'] = progGroupId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramMaster: async (programId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling getProgramMaster.');
            }
            const localVarPath = `/admin/program-management/program_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectMaster: async (subMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subMasterId' is not null or undefined
            if (subMasterId === null || subMasterId === undefined) {
                throw new RequiredError('subMasterId','Required parameter subMasterId was null or undefined when calling getSubjectMaster.');
            }
            const localVarPath = `/admin/program-management/subject_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subMasterId !== undefined) {
                localVarQueryParameter['subMasterId'] = subMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermClass: async (termSubClassId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termSubClassId' is not null or undefined
            if (termSubClassId === null || termSubClassId === undefined) {
                throw new RequiredError('termSubClassId','Required parameter termSubClassId was null or undefined when calling getTermClass.');
            }
            const localVarPath = `/admin/program-management/term_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termSubClassId !== undefined) {
                localVarQueryParameter['termSubClassId'] = termSubClassId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermMaster: async (termId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            if (termId === null || termId === undefined) {
                throw new RequiredError('termId','Required parameter termId was null or undefined when calling getTermMaster.');
            }
            const localVarPath = `/admin/program-management/term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (termId !== undefined) {
                localVarQueryParameter['termId'] = termId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the chapter master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChapterMasters: async (subMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subMasterId' is not null or undefined
            if (subMasterId === null || subMasterId === undefined) {
                throw new RequiredError('subMasterId','Required parameter subMasterId was null or undefined when calling listChapterMasters.');
            }
            const localVarPath = `/admin/program-management/list_chapter_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subMasterId !== undefined) {
                localVarQueryParameter['subMasterId'] = subMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGradeMasters: async (progGroupId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/program-management/list_grade_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (progGroupId !== undefined) {
                localVarQueryParameter['progGroupId'] = progGroupId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {ListProgramMasterParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPostProgramMasters: async (body: ListProgramMasterParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listPostProgramMasters.');
            }
            const localVarPath = `/admin/program-management/list_program_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the term master
         * @param {ListTermParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPostTerms: async (body: ListTermParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listPostTerms.');
            }
            const localVarPath = `/admin/program-management/list_term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of new program_group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/program-management/list_program_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramMasters: async (progGroupId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/program-management/list_program_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (progGroupId !== undefined) {
                localVarQueryParameter['progGroupId'] = progGroupId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the subject master
         * @param {number} [programId] 
         * @param {boolean} [isIncludeChapters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubjectMasters: async (programId?: number, isIncludeChapters?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/program-management/list_subject_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            if (isIncludeChapters !== undefined) {
                localVarQueryParameter['isIncludeChapters'] = isIncludeChapters;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTermClass: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/program-management/list_term_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get list of all the Term master
         * @param {boolean} includeSubject 
         * @param {number} [programId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTermMaster: async (includeSubject: boolean, programId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeSubject' is not null or undefined
            if (includeSubject === null || includeSubject === undefined) {
                throw new RequiredError('includeSubject','Required parameter includeSubject was null or undefined when calling listTermMaster.');
            }
            const localVarPath = `/admin/program-management/list_term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeSubject !== undefined) {
                localVarQueryParameter['includeSubject'] = includeSubject;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChapterMaster: async (body: ChapterMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateChapterMaster.');
            }
            const localVarPath = `/admin/program-management/chapter_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramMaster: async (body: ProgramMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProgramMaster.');
            }
            const localVarPath = `/admin/program-management/program_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubjectMaster: async (body: SubjectMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubjectMaster.');
            }
            const localVarPath = `/admin/program-management/subject_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermClass: async (body: TermClass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTermClass.');
            }
            const localVarPath = `/admin/program-management/term_class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermMaster: async (body: Term, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTermMaster.');
            }
            const localVarPath = `/admin/program-management/term_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatesGradeMaster: async (body: GradeMaster, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatesGradeMaster.');
            }
            const localVarPath = `/admin/program-management/grade_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api to update new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatesProgramGroup: async (body: ProgramGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatesProgramGroup.');
            }
            const localVarPath = `/admin/program-management/program_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgramMgmtApi - functional programming interface
 * @export
 */
export const ProgramMgmtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChapterMaster(body: ChapterMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChapterMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createChapterMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProgramMaster(body: ProgramMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createProgramMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubjectMaster(body: SubjectMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createSubjectMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTermClass(body: TermClass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermClass>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createTermClass(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTermDocument(body: TermDocument, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermDocument>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createTermDocument(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTermMaster(body: Term, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Term>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createTermMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new grade_master
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createsNewGradeMaster(body: GradeMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GradeMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createsNewGradeMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to create new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createsNewProgramGroup(body: ProgramGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramGroup>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).createsNewProgramGroup(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChapterMaster(chapMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteChapterMaster(chapMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {string} gradeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGradeMaster(gradeName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteGradeMaster(gradeName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api delete detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramGroup(progGroupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteProgramGroup(progGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramMaster(programId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteProgramMaster(programId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubjectMaster(subMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteSubjectMaster(subMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api delete detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermClass(termSubClassId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteTermClass(termSubClassId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term class chapter
         * @param {number} termClassChapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermClassChapter(termClassChapterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteTermClassChapter(termClassChapterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term document
         * @param {number} termDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermDocument(termDocId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteTermDocument(termDocId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermMaster(termId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteTermMaster(termId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to delete detail of requested term subject
         * @param {number} termSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermSubject(termSubjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).deleteTermSubject(termSubjectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChapterMaster(chapMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChapterMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getChapterMaster(chapMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramGroup(progGroupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramGroup>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getProgramGroup(progGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramMaster(programId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getProgramMaster(programId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectMaster(subMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getSubjectMaster(subMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermClass(termSubClassId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermClass>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getTermClass(termSubClassId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermMaster(termId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Term>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).getTermMaster(termId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the chapter master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChapterMasters(subMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChapterMaster>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listChapterMasters(subMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGradeMasters(progGroupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GradeMaster>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listGradeMasters(progGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {ListProgramMasterParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPostProgramMasters(body: ListProgramMasterParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProgramMasterOutput>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listPostProgramMasters(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the term master
         * @param {ListTermParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPostTerms(body: ListTermParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTermOutput>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listPostTerms(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of new program_group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramGroup>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listProgramGroups(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramMasters(progGroupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramMaster>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listProgramMasters(progGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the subject master
         * @param {number} [programId] 
         * @param {boolean} [isIncludeChapters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubjectMasters(programId?: number, isIncludeChapters?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubjectMaster>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listSubjectMasters(programId, isIncludeChapters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTermClass(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TermClass>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listTermClass(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get list of all the Term master
         * @param {boolean} includeSubject 
         * @param {number} [programId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTermMaster(includeSubject: boolean, programId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Term>>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).listTermMaster(includeSubject, programId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChapterMaster(body: ChapterMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChapterMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updateChapterMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramMaster(body: ProgramMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updateProgramMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubjectMaster(body: SubjectMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updateSubjectMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTermClass(body: TermClass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermClass>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updateTermClass(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTermMaster(body: Term, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Term>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updateTermMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatesGradeMaster(body: GradeMaster, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GradeMaster>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updatesGradeMaster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api to update new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatesProgramGroup(body: ProgramGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramGroup>> {
            const localVarAxiosArgs = await ProgramMgmtApiAxiosParamCreator(configuration).updatesProgramGroup(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProgramMgmtApi - factory interface
 * @export
 */
export const ProgramMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChapterMaster(body: ChapterMaster, options?: any): AxiosPromise<ChapterMaster> {
            return ProgramMgmtApiFp(configuration).createChapterMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramMaster(body: ProgramMaster, options?: any): AxiosPromise<ProgramMaster> {
            return ProgramMgmtApiFp(configuration).createProgramMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubjectMaster(body: SubjectMaster, options?: any): AxiosPromise<SubjectMaster> {
            return ProgramMgmtApiFp(configuration).createSubjectMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermClass(body: TermClass, options?: any): AxiosPromise<TermClass> {
            return ProgramMgmtApiFp(configuration).createTermClass(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested Term class master
         * @param {TermDocument} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermDocument(body: TermDocument, options?: any): AxiosPromise<TermDocument> {
            return ProgramMgmtApiFp(configuration).createTermDocument(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTermMaster(body: Term, options?: any): AxiosPromise<Term> {
            return ProgramMgmtApiFp(configuration).createTermMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new grade_master
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewGradeMaster(body: GradeMaster, options?: any): AxiosPromise<GradeMaster> {
            return ProgramMgmtApiFp(configuration).createsNewGradeMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to create new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createsNewProgramGroup(body: ProgramGroup, options?: any): AxiosPromise<ProgramGroup> {
            return ProgramMgmtApiFp(configuration).createsNewProgramGroup(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChapterMaster(chapMasterId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteChapterMaster(chapMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {string} gradeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGradeMaster(gradeName: string, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteGradeMaster(gradeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api delete detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramGroup(progGroupId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteProgramGroup(progGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramMaster(programId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteProgramMaster(programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectMaster(subMasterId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteSubjectMaster(subMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api delete detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermClass(termSubClassId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteTermClass(termSubClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete detail of requested term class chapter
         * @param {number} termClassChapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermClassChapter(termClassChapterId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteTermClassChapter(termClassChapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete detail of requested term document
         * @param {number} termDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermDocument(termDocId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteTermDocument(termDocId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api create detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermMaster(termId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteTermMaster(termId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to delete detail of requested term subject
         * @param {number} termSubjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermSubject(termSubjectId: number, options?: any): AxiosPromise<void> {
            return ProgramMgmtApiFp(configuration).deleteTermSubject(termSubjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested Chapter master
         * @param {number} chapMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChapterMaster(chapMasterId: number, options?: any): AxiosPromise<ChapterMaster> {
            return ProgramMgmtApiFp(configuration).getChapterMaster(chapMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested program level master
         * @param {number} progGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramGroup(progGroupId: number, options?: any): AxiosPromise<ProgramGroup> {
            return ProgramMgmtApiFp(configuration).getProgramGroup(progGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested program master
         * @param {number} programId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramMaster(programId: number, options?: any): AxiosPromise<ProgramMaster> {
            return ProgramMgmtApiFp(configuration).getProgramMaster(programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested subject master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectMaster(subMasterId: number, options?: any): AxiosPromise<SubjectMaster> {
            return ProgramMgmtApiFp(configuration).getSubjectMaster(subMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested Term class master
         * @param {number} termSubClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermClass(termSubClassId: number, options?: any): AxiosPromise<TermClass> {
            return ProgramMgmtApiFp(configuration).getTermClass(termSubClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get detail of requested Term master
         * @param {number} termId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermMaster(termId: number, options?: any): AxiosPromise<Term> {
            return ProgramMgmtApiFp(configuration).getTermMaster(termId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the chapter master
         * @param {number} subMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChapterMasters(subMasterId: number, options?: any): AxiosPromise<Array<ChapterMaster>> {
            return ProgramMgmtApiFp(configuration).listChapterMasters(subMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of new GradeMaster
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGradeMasters(progGroupId?: number, options?: any): AxiosPromise<Array<GradeMaster>> {
            return ProgramMgmtApiFp(configuration).listGradeMasters(progGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {ListProgramMasterParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPostProgramMasters(body: ListProgramMasterParams, options?: any): AxiosPromise<ListProgramMasterOutput> {
            return ProgramMgmtApiFp(configuration).listPostProgramMasters(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the term master
         * @param {ListTermParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPostTerms(body: ListTermParams, options?: any): AxiosPromise<ListTermOutput> {
            return ProgramMgmtApiFp(configuration).listPostTerms(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of new program_group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramGroups(options?: any): AxiosPromise<Array<ProgramGroup>> {
            return ProgramMgmtApiFp(configuration).listProgramGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the program master
         * @param {number} [progGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramMasters(progGroupId?: number, options?: any): AxiosPromise<Array<ProgramMaster>> {
            return ProgramMgmtApiFp(configuration).listProgramMasters(progGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the subject master
         * @param {number} [programId] 
         * @param {boolean} [isIncludeChapters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubjectMasters(programId?: number, isIncludeChapters?: boolean, options?: any): AxiosPromise<Array<SubjectMaster>> {
            return ProgramMgmtApiFp(configuration).listSubjectMasters(programId, isIncludeChapters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the Term class master
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTermClass(options?: any): AxiosPromise<Array<TermClass>> {
            return ProgramMgmtApiFp(configuration).listTermClass(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get list of all the Term master
         * @param {boolean} includeSubject 
         * @param {number} [programId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTermMaster(includeSubject: boolean, programId?: number, options?: any): AxiosPromise<Array<Term>> {
            return ProgramMgmtApiFp(configuration).listTermMaster(includeSubject, programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update detail of requested Chapter master
         * @param {ChapterMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChapterMaster(body: ChapterMaster, options?: any): AxiosPromise<ChapterMaster> {
            return ProgramMgmtApiFp(configuration).updateChapterMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update detail of requested program master
         * @param {ProgramMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramMaster(body: ProgramMaster, options?: any): AxiosPromise<ProgramMaster> {
            return ProgramMgmtApiFp(configuration).updateProgramMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update detail of requested subject master
         * @param {SubjectMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubjectMaster(body: SubjectMaster, options?: any): AxiosPromise<SubjectMaster> {
            return ProgramMgmtApiFp(configuration).updateSubjectMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update detail of requested Term class master
         * @param {TermClass} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermClass(body: TermClass, options?: any): AxiosPromise<TermClass> {
            return ProgramMgmtApiFp(configuration).updateTermClass(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update detail of requested Term master
         * @param {Term} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermMaster(body: Term, options?: any): AxiosPromise<Term> {
            return ProgramMgmtApiFp(configuration).updateTermMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update new GradeMaster
         * @param {GradeMaster} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatesGradeMaster(body: GradeMaster, options?: any): AxiosPromise<GradeMaster> {
            return ProgramMgmtApiFp(configuration).updatesGradeMaster(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api to update new program_group
         * @param {ProgramGroup} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatesProgramGroup(body: ProgramGroup, options?: any): AxiosPromise<ProgramGroup> {
            return ProgramMgmtApiFp(configuration).updatesProgramGroup(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProgramMgmtApi - object-oriented interface
 * @export
 * @class ProgramMgmtApi
 * @extends {BaseAPI}
 */
export class ProgramMgmtApi extends BaseAPI {
    /**
     * 
     * @summary api create detail of requested Chapter master
     * @param {ChapterMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createChapterMaster(body: ChapterMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createChapterMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested program master
     * @param {ProgramMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createProgramMaster(body: ProgramMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createProgramMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested subject master
     * @param {SubjectMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createSubjectMaster(body: SubjectMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createSubjectMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested Term class master
     * @param {TermClass} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createTermClass(body: TermClass, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createTermClass(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested Term class master
     * @param {TermDocument} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createTermDocument(body: TermDocument, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createTermDocument(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested Term master
     * @param {Term} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createTermMaster(body: Term, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createTermMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new grade_master
     * @param {GradeMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createsNewGradeMaster(body: GradeMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createsNewGradeMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to create new program_group
     * @param {ProgramGroup} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public createsNewProgramGroup(body: ProgramGroup, options?: any) {
        return ProgramMgmtApiFp(this.configuration).createsNewProgramGroup(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested Chapter master
     * @param {number} chapMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteChapterMaster(chapMasterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteChapterMaster(chapMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update new GradeMaster
     * @param {string} gradeName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteGradeMaster(gradeName: string, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteGradeMaster(gradeName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api delete detail of requested program level master
     * @param {number} progGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteProgramGroup(progGroupId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteProgramGroup(progGroupId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested program master
     * @param {number} programId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteProgramMaster(programId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteProgramMaster(programId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested subject master
     * @param {number} subMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteSubjectMaster(subMasterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteSubjectMaster(subMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api delete detail of requested Term class master
     * @param {number} termSubClassId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteTermClass(termSubClassId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteTermClass(termSubClassId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete detail of requested term class chapter
     * @param {number} termClassChapterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteTermClassChapter(termClassChapterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteTermClassChapter(termClassChapterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete detail of requested term document
     * @param {number} termDocId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteTermDocument(termDocId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteTermDocument(termDocId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api create detail of requested Term master
     * @param {number} termId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteTermMaster(termId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteTermMaster(termId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to delete detail of requested term subject
     * @param {number} termSubjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public deleteTermSubject(termSubjectId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).deleteTermSubject(termSubjectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested Chapter master
     * @param {number} chapMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getChapterMaster(chapMasterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getChapterMaster(chapMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested program level master
     * @param {number} progGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getProgramGroup(progGroupId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getProgramGroup(progGroupId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested program master
     * @param {number} programId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getProgramMaster(programId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getProgramMaster(programId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested subject master
     * @param {number} subMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getSubjectMaster(subMasterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getSubjectMaster(subMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested Term class master
     * @param {number} termSubClassId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getTermClass(termSubClassId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getTermClass(termSubClassId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get detail of requested Term master
     * @param {number} termId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public getTermMaster(termId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).getTermMaster(termId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the chapter master
     * @param {number} subMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listChapterMasters(subMasterId: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listChapterMasters(subMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of new GradeMaster
     * @param {number} [progGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listGradeMasters(progGroupId?: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listGradeMasters(progGroupId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the program master
     * @param {ListProgramMasterParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listPostProgramMasters(body: ListProgramMasterParams, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listPostProgramMasters(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the term master
     * @param {ListTermParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listPostTerms(body: ListTermParams, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listPostTerms(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of new program_group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listProgramGroups(options?: any) {
        return ProgramMgmtApiFp(this.configuration).listProgramGroups(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the program master
     * @param {number} [progGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listProgramMasters(progGroupId?: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listProgramMasters(progGroupId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the subject master
     * @param {number} [programId] 
     * @param {boolean} [isIncludeChapters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listSubjectMasters(programId?: number, isIncludeChapters?: boolean, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listSubjectMasters(programId, isIncludeChapters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the Term class master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listTermClass(options?: any) {
        return ProgramMgmtApiFp(this.configuration).listTermClass(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get list of all the Term master
     * @param {boolean} includeSubject 
     * @param {number} [programId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public listTermMaster(includeSubject: boolean, programId?: number, options?: any) {
        return ProgramMgmtApiFp(this.configuration).listTermMaster(includeSubject, programId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update detail of requested Chapter master
     * @param {ChapterMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updateChapterMaster(body: ChapterMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updateChapterMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update detail of requested program master
     * @param {ProgramMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updateProgramMaster(body: ProgramMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updateProgramMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update detail of requested subject master
     * @param {SubjectMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updateSubjectMaster(body: SubjectMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updateSubjectMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update detail of requested Term class master
     * @param {TermClass} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updateTermClass(body: TermClass, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updateTermClass(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update detail of requested Term master
     * @param {Term} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updateTermMaster(body: Term, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updateTermMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update new GradeMaster
     * @param {GradeMaster} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updatesGradeMaster(body: GradeMaster, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updatesGradeMaster(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api to update new program_group
     * @param {ProgramGroup} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramMgmtApi
     */
    public updatesProgramGroup(body: ProgramGroup, options?: any) {
        return ProgramMgmtApiFp(this.configuration).updatesProgramGroup(body, options).then((request) => request(this.axios, this.basePath));
    }
}
