import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiController } from '../../apiController'
import { LoginRequest, User } from '../../swagger/models'
import './auth.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { changePasswordValidationSchema } from '../common-module/validation'
import { useDispatch } from 'react-redux'
import { showErrorSnackbar, showSuccessSnackbar } from '../../state/action-creators/snackbarActions'
import SnackbarHelper from '../common-module/snackbar-helper'
import Loader from '../common-module/loader'

function ChangePassword() {
  const dispatch = useDispatch()
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({})
  const [userEmail, setUserEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [user, setUser] = useState<User>({})
  const [passwordVisibility, setPasswordVisibility] = React.useState({
    newPassword: false,
    confPassword: false,
  })
  const navigate = useNavigate()
  const params = useParams()
  const token: string = params.token!

  const [showLoader, setShowLoader] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      try {
        setShowLoader(true)
        const userResponse = await (
          await ApiController.getInstance().openApi.fetchTokenEmail(token)
        ).data
        if (userResponse) {
          setUserEmail(userResponse.email)
          setUser(userResponse)
          await setLoginRequest({
            ...loginRequest,
            email: userResponse.email,
            token: params.token,
          })
        }
        setShowLoader(false)
      } catch (error: any) {
        setShowLoader(false)
        if (error?.response?.data?.message === 'Link is expired') {
          setErrorMessage('Link is expired')
        }
        dispatch(showErrorSnackbar(error?.response?.data?.message))
      }
    })()
  }, [])

  async function updatePassword() {
    try {
      setShowLoader(true)
      await ApiController.getInstance().openApi.resetPassword(loginRequest)
      dispatch(showSuccessSnackbar('Password set successfully'))
      ApiController.getInstance().clearToken()
      setShowLoader(false)
      setTimeout(() => {
        if (user.role === 'TEACHER') {
          navigate('/teacher/login')
        } else if (user.role === 'STUDENT' || user.role === 'PARENT') {
          navigate('/student/login')
        } else {
          navigate('/admin/login')
        }
      }, 2000)
    } catch (error: any) {
      setShowLoader(false)
      dispatch(showErrorSnackbar(error?.response?.data?.message))
    }
  }

  const formOptions = { resolver: yupResolver(changePasswordValidationSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  async function onSubmit(data: any) {
    await updatePassword()
    return false
  }

  const handleClickShowPassword = (name: any) => {
    switch (name) {
      case 'newPassword':
        setPasswordVisibility({
          ...passwordVisibility,
          newPassword: !passwordVisibility.newPassword,
        })
        break

      case 'confPassword':
        setPasswordVisibility({
          ...passwordVisibility,
          confPassword: !passwordVisibility.confPassword,
        })
        break
      default:
        break
    }
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <React.Fragment>
      <SnackbarHelper />
      {showLoader && <Loader />}
      <div className="main pl-3">
        <div className="auth h-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container={true}
              spacing="2"
              className="h-100"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item={true} md={6} lg={5} xl={4}>
                <div className="logo">
                  <img src="/images/logo.png" alt="" />
                </div>
                <Card sx={{ px: 3, py: 4 }}>
                  <h1 className="text-center">
                    Change Password
                    {/* <small className="d-block">
                      Enter your email id to get password reset link.
                    </small> */}
                  </h1>
                  {errorMessage && (
                    <Typography className="no-data" textAlign="center">
                      {errorMessage}
                    </Typography>
                  )}
                  {userEmail && (
                    <div>
                      <TextField
                        type="email"
                        fullWidth={true}
                        id="email"
                        label="Email id"
                        value={userEmail || ''}
                        variant="outlined"
                        sx={{ mb: 2 }}
                        disabled={true}
                        required={true}
                      />
                      <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          type={passwordVisibility.newPassword ? 'text' : 'password'}
                          error={!!errors.password}
                          {...register('password')}
                          fullWidth={true}
                          id="password"
                          label="New Password"
                          sx={{ mb: 2 }}
                          required={true}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword('newPassword')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {passwordVisibility.newPassword ? (
                                  <i className="ri-eye-off-line" />
                                ) : (
                                  <i className="ri-eye-line" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText className="error">
                          {errors.password?.message}
                        </FormHelperText>
                      </FormControl>
                      {/* <FormControl>
										<TextField type="password" error={!!errors.password}  {...register('password')} fullWidth id="password" label="New Password" variant="outlined" sx={{ mb: 2 }} required={true}/>
									</FormControl> */}
                      <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                          type={passwordVisibility.confPassword ? 'text' : 'password'}
                          error={!!errors.confirmPassword}
                          {...register('confirmPassword')}
                          fullWidth={true}
                          id="conf_password"
                          label="Confirm Password"
                          value={loginRequest?.password}
                          sx={{ mb: 2 }}
                          required={true}
                          onChange={(e) => {
                            setLoginRequest({
                              ...loginRequest,
                              password: e.currentTarget.value,
                            })
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword('confPassword')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {passwordVisibility.confPassword ? (
                                  <i className="ri-eye-off-line" />
                                ) : (
                                  <i className="ri-eye-line" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {/* <TextField type="password" error={!!errors.confirmPassword}  {...register('confirmPassword')} fullWidth id="conf_password" label="Confirm Password" variant="outlined" sx={{ mb: 3 }} required={true} /> */}
                        <FormHelperText className="error">
                          {errors.confirmPassword?.message}
                        </FormHelperText>
                      </FormControl>
                      <div className="text-center">
                        <Button variant="contained" className="btn btn-primary" type="submit">
                          Set New password
                        </Button>
                      </div>
                    </div>
                  )}
                </Card>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
