/* eslint-disable import/no-anonymous-default-export */

import { BatchMgmtActions } from '../actions/index'
import { ActionType } from '../action-types/index'
import { BatchClass, BatchMaster, BatchTerm, BatchTimeslot } from '../../swagger/models'

interface BatchManagement {
  listBatches?: BatchMaster[]
  countOfBactches?: number
  batch?: BatchMaster
  actionType?: string
  shouldShowLoader: boolean
}

const initialState = {
  listBatches: [],
  countOfBactches: 0,
  batch: {},
  actionType: '',
  shouldShowLoader: false,
}

const listBatchReducer = (state: BatchManagement = initialState, action: BatchMgmtActions) => {
  switch (action.type) {
    case ActionType.FETCH_LIST_BATCH_MASTER:
      return {
        ...state,
        listBatches: action.batches,
        countOfBactches: action.countOfBactches,
      }
    case ActionType.GET_BATCH_MASTER:
      return {
        ...state,
        actionType: 'GET_BATCH',
        batch: action.batch,
      }
    case ActionType.ADD_BATCH_MASTER:
      return {
        ...state,
        actionType: 'ADD_BATCH',
        batch: action.batch,
      }
    case ActionType.UPDATE_BATCH_MASTER:
      return {
        ...state,
        actionType: 'UPDATE_BATCH',
        batch: {
          ...state.batch,
          batchName: action.updateBatch.batchName,
          studentCapacityMax: action.updateBatch.studentCapacityMax,
          startDate: action.updateBatch.startDate,
          rmId: action.updateBatch.rmId,
          timezone: action.updateBatch.timezone,
        },
      }
    case ActionType.ASSIGN_BATCH_SUBJECT_TEACHER:
      return {
        ...state,
        actionType: 'SUBJECT_TEACHER',
        batch: {
          ...state.batch,
          BatchTerms: [
            ...state.batch?.BatchTerms.slice(0, action.batchTermindex),
            {
              ...state.batch?.BatchTerms[action.batchTermindex],
              BatchSubjects: action.BatchSubjects,
            },
            ...state.batch?.BatchTerms.slice(action.batchTermindex + 1),
          ],
        },
      }
    case ActionType.ASSIGN_BATCH_TERM_STUDENT:
      return {
        ...state,
        actionType: 'BATCH_STUDENT',
        batch: {
          ...state.batch,
          BatchTerms: [
            ...state.batch?.BatchTerms.slice(0, action.batchTermindex),
            {
              ...state.batch?.BatchTerms[action.batchTermindex],
              BatchStudents: action.BatchStudents,
            },
            ...state.batch?.BatchTerms.slice(action.batchTermindex + 1),
          ],
        },
      }
    case ActionType.SCHEDULE_BATCH_TIMESLOTS:
      return {
        ...state,
        actionType: 'TIMESLOT',
        batch: {
          ...state.batch,
          BatchTimeslots: action.BatchTimeslots,
          BatchTerms: [
            ...state.batch?.BatchTerms.slice(0, action.batchTermIndex),
            {
              ...state.batch?.BatchTerms[action.batchTermIndex],
              startDate: action.startDate,
              BatchClasses: state.batch?.BatchTerms[action.batchTermIndex]?.BatchClasses?.map(
                (batchClass: BatchClass) => {
                  const filterBatchNotification = action.BatchTimeslots.filter(
                    (i: BatchTimeslot) => i.BatchClass.batchClassId === batchClass.batchClassId
                  )[0]
                  if (
                    !filterBatchNotification ||
                    batchClass.batchClassId !== filterBatchNotification.BatchClass.batchClassId
                  ) {
                    return batchClass
                  }
                  return {
                    ...batchClass,
                    batchTimeslotId: filterBatchNotification.batchTimeslotId,
                    ClassMaster: {
                      ...batchClass.ClassMaster,
                      date: filterBatchNotification.date,
                      startTime: filterBatchNotification.startTime,
                    },
                  }
                }
              ),
            },
            ...state.batch?.BatchTerms.slice(action.batchTermIndex + 1),
          ],
        },
      }
    case ActionType.DELETE_BATCH_TIMESLOTS:
      return {
        ...state,
        actionType: 'TIMESLOT',
        batch: {
          ...state.batch,
          BatchTimeslots: action.BatchTimeslots,
          BatchTerms: [
            ...state.batch?.BatchTerms.slice(0, action.batchTermIndex),
            {
              ...state.batch?.BatchTerms[action.batchTermIndex],
              BatchClasses: state.batch?.BatchTerms[action.batchTermIndex]?.BatchClasses?.map(
                (batchClass: BatchClass) => {
                  const filterBatchNotification = action.BatchTimeslots.filter(
                    (i: BatchTimeslot) => i.BatchClass.batchClassId === batchClass.batchClassId
                  )[0]
                  if (
                    filterBatchNotification &&
                    batchClass.batchClassId === filterBatchNotification.BatchClass.batchClassId
                  ) {
                    return batchClass
                  }
                  return {
                    ...batchClass,
                    batchTimeslotId: null,
                  }
                }
              ),
            },
            ...state.batch?.BatchTerms.slice(action.batchTermIndex + 1),
          ],
        },
      }
    case ActionType.DELETE_SINGLE_BATCH_TIMESLOTS:
      return {
        ...state,
        actionType: 'TIMESLOT',
        batch: {
          ...state.batch,
          BatchTimeslots: action.BatchTimeslots,
        },
      }
    case ActionType.BATCH_PUBLISHED:
      return {
        ...state,
        actionType: 'PUBLISHED',
      }
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        shouldShowLoader: action.shouldShowLoader,
      }
    case ActionType.DELETE_BATCH_TERM:
      const updatedBatchTermsArray = state.batch?.BatchTerms
      updatedBatchTermsArray.splice(action.batchTermIndex, 1)
      return {
        ...state,
        batch: {
          ...state.batch,
          BatchTerms: updatedBatchTermsArray,
        },
      }
    case ActionType.ADD_BATCH_TERM:
      const updatedBatchTerms = state.batch?.BatchTerms
      updatedBatchTerms.push(action.batchTerm)
      return {
        ...state,
        batch: {
          ...state.batch,
          BatchTerms: updatedBatchTerms,
        },
      }
    case ActionType.DELETE_BATCH_TERM_SUBJECT:
      return {
        ...state,
        batch: {
          ...state.batch,
          BatchTerms: state.batch?.BatchTerms?.map((batchTerm: BatchTerm, index: number) => {
            if (index !== action.batchTermIndex) {
              return batchTerm
            }
            const updatedBatchSubjectsArray = batchTerm?.BatchSubjects
            updatedBatchSubjectsArray.splice(action.batchTermSubjectIndex, 1)

            return {
              ...batchTerm,
              BatchSubjects: updatedBatchSubjectsArray,
            }
          }),
        },
      }
    default:
      return state
  }
}

export default listBatchReducer
