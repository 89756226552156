import { Alert, IconButton, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { clearSnackbar } from '../../state/action-creators/snackbarActions'
import { State } from '../../state'
import { Fragment } from 'react'

export default function SnackbarHelper() {
  const dispatch = useDispatch()

  const { snackbarMessage, successSnackbarOpen, type, timer } = useSelector(
    (state: State) => state.snackberReducer
  )

  function handleClose() {
    dispatch(clearSnackbar())
  }

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <i className="ri-close-circle-line" />
      </IconButton>
    </Fragment>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={successSnackbarOpen}
      autoHideDuration={timer}
      onClose={handleClose}
    >
      <Alert severity={type}>{snackbarMessage}</Alert>
    </Snackbar>
  )
}
