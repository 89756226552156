import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormLabel,
  FormHelperText,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ApiController } from '../../../apiController'
import { actionCreators } from '../../../state'
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../state/action-creators/snackbarActions'
import { LoginPhoneRequest } from '../../../swagger/models'
import { countries } from '../../common-module/country'
import SnackbarHelper from '../../common-module/snackbar-helper'
import { otpFormValidationSchema } from '../../common-module/validation'

function StudentOtpLogin({ changeLoginType }: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showOTP, setShowOTP] = useState(false)
  const [country, setCountry] = useState<any>({ code: 'IN', label: 'India', phone: '91' })
  const [loginRequest, setLoginRequest] = useState<LoginPhoneRequest>({
    countryCode: '+91',
    phoneNo: 0,
    isRememberme: true,
  })

  const { studentPhoneLogin } = bindActionCreators(actionCreators, dispatch)
  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((userLoggedIn) => {
      if (userLoggedIn && (userLoggedIn?.role === 'STUDENT' || userLoggedIn?.role === 'PARENT')) {
        navigate('/student/dashboard')
      }
    })
  }, [])

  const onInputChange = (e: any) => {
    const { name, value } = e.currentTarget
    setLoginRequest({
      ...loginRequest,
      [name]: value,
    })
  }

  const formOptions = { resolver: yupResolver(otpFormValidationSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  function onSubmit(data: any) {
    studentPhoneLogin(loginRequest)
    return false
  }

  async function sendOTP() {
    if (!loginRequest.phoneNo) {
      dispatch(showErrorSnackbar('Enter valid Phone No'))
      return
    }
    try {
      await ApiController.getInstance().openApi.sendOtp(
        loginRequest.phoneNo,
        loginRequest.countryCode
      )
      dispatch(showSuccessSnackbar('OTP sent successfully to your registerd mobile number'))
      setShowOTP(true)
    } catch (error: any) {
      dispatch(showErrorSnackbar(error?.response?.data?.message))
    }
    return false
  }

  return (
    <React.Fragment>
      <SnackbarHelper />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={4}>
            <Autocomplete
              id="countryCode"
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              options={countries}
              autoHighlight={true}
              value={country}
              onChange={(event: any, newValue: any) => {
                setCountry(newValue)
                setLoginRequest({
                  ...loginRequest,
                  countryCode: '+' + newValue?.phone,
                })
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  +{option.phone} {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-country', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          {/* item */}
          <Grid item={true} md={true}>
            <FormControl fullWidth={true}>
              <TextField
                fullWidth={true}
                error={!!errors.phoneNo}
                {...register('phoneNo')}
                id="phoneNo"
                type="number"
                label="Phone No."
                variant="outlined"
                onChange={onInputChange}
                value={loginRequest.phoneNo || ''}
                name="phoneNo"
              />
              <FormHelperText className="error">{errors.phoneNo?.message}</FormHelperText>
            </FormControl>
          </Grid>
          {/* item */}
        </Grid>
        {/* container */}
        {showOTP && (
          <FormControl fullWidth={true} sx={{ mt: 3 }}>
            <FormLabel>Enter 6 Digit OTP</FormLabel>
            <OtpInput
              value={loginRequest.otp}
              onChange={(otp: any) => setLoginRequest({ ...loginRequest, otp })}
              numInputs={6}
              isInputNum={true}
              inputStyle="otpInput"
              className="otpContainer"
            />
          </FormControl>
        )}
        <Grid container={true} sx={{ mt: 2 }} justifyContent="space-between">
          <Grid item={true} />
          <Grid item={true} />
        </Grid>
        {showOTP ? (
          <Box textAlign={'right'}>
            <Button variant="text" className="no-shadow" onClick={() => sendOTP()}>
              <i className="ri-restart-line mr-1" />
              Resend OTP
            </Button>
          </Box>
        ) : (
          ''
        )}
        {showOTP ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setLoginRequest({
                    ...loginRequest,
                    isRememberme: e.target.checked,
                  })
                }
                checked={loginRequest.isRememberme}
              />
            }
            color="primary"
            name="isRememberme"
            label="Remember me"
          />
        ) : (
          ''
        )}
        <div className="text-center">
          {showOTP ? (
            <Button
              variant="contained"
              type="submit"
              size="large"
              className="btn btn-primary"
              disabled={loginRequest?.otp?.length !== 6}
            >
              Login
            </Button>
          ) : (
            <Button
              variant="contained"
              type="button"
              size="large"
              className="btn btn-primary"
              onClick={sendOTP}
            >
              Send OTP
            </Button>
          )}
          <Typography sx={{ my: 2 }} variant="body1" color="initial">
            Or
          </Typography>
          <Button
            variant="text"
            color="primary"
            className="no-shadow"
            onClick={() => changeLoginType('email')}
          >
            Login with Email
          </Button>
        </div>
        {/* <Box sx={{ mt: 3 }} textAlign={'center'}>
          <Typography variant="body1" color="initial">
            Don't have an account?{' '}
            <Link to="./sign-up" className="text-primary">
              Register now
            </Link>
          </Typography>
        </Box> */}
      </form>
    </React.Fragment>
  )
}

export default StudentOtpLogin
