/* eslint-disable import/no-anonymous-default-export */

import { TeacherMgmtAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Teacher, TeacherSubject, UserTimeslot } from '../../swagger/models'

interface TeacherManagement {
  listTeachers?: Teacher[]
  userTimeslots?: UserTimeslot[]
  getTeacher?: Teacher
  shouldShowLoader: boolean
}

const initialState = {
  listTeachers: [],
  userTimeslots: [],
  getTeacher: {},
  shouldShowLoader: false,
}

const teacherMgmtReducer = (state: TeacherManagement = initialState, action: TeacherMgmtAction) => {
  switch (action.type) {
    case ActionType.FETCH_LIST_TEACHER:
      return {
        ...state,
        listTeachers: action.teachers,
      }
    case ActionType.ADD_TEACHER:
      return {
        ...state,
        getTeacher: action.addTeacher,
      }
    case ActionType.UPDATE_TEACHER:
      return {
        ...state,
        getTeacher: action.updateTeacher,
      }
    case ActionType.GET_TEACHER:
      return {
        ...state,
        getTeacher: action.getTeacher,
      }
    case ActionType.ADD_TEACHER_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.UPDATE_TEACHER_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.GET_TEACHER_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.ADD_TEACHER_SUBJECT:
      return {
        ...state,
        getTeacher: {
          ...state.getTeacher,
          TeacherSubjects: [...state.getTeacher?.TeacherSubjects, action.addTeacherSubject],
        },
      }
    case ActionType.REMOVE_TEACHER_SUBJECT:
      const index = state.getTeacher?.TeacherSubjects.findIndex(
        (teacherSubject: TeacherSubject) =>
          teacherSubject.teacherSubjectId === action.teacherSubjectId
      )
      const updatedSubjectsArray = state.getTeacher?.TeacherSubjects
      updatedSubjectsArray.splice(index, 1)
      return {
        ...state,
        getTeacher: {
          ...state.getTeacher,
          TeacherSubjects: updatedSubjectsArray,
        },
      }
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        shouldShowLoader: action.shouldShowLoader,
      }
    default:
      return state
  }
}

export default teacherMgmtReducer
