/* tslint:disable */
/* eslint-disable */
/**
 * Beyond Skool
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ClassMaster } from '../models';
/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadClassMaster: async (classMasterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classMasterId' is not null or undefined
            if (classMasterId === null || classMasterId === undefined) {
                throw new RequiredError('classMasterId','Required parameter classMasterId was null or undefined when calling getLeadClassMaster.');
            }
            const localVarPath = `/lead/class_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classMasterId !== undefined) {
                localVarQueryParameter['classMasterId'] = classMasterId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api get lead_classes
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadClasses: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/lead/lead_classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeadClassMaster(classMasterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassMaster>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).getLeadClassMaster(classMasterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary api get lead_classes
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadClasses(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassMaster>>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).leadClasses(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary api get list of all the classes
         * @param {number} classMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadClassMaster(classMasterId: number, options?: any): AxiosPromise<ClassMaster> {
            return LeadApiFp(configuration).getLeadClassMaster(classMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api get lead_classes
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadClasses(date?: string, options?: any): AxiosPromise<Array<ClassMaster>> {
            return LeadApiFp(configuration).leadClasses(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
    /**
     * 
     * @summary api get list of all the classes
     * @param {number} classMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public getLeadClassMaster(classMasterId: number, options?: any) {
        return LeadApiFp(this.configuration).getLeadClassMaster(classMasterId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary api get lead_classes
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public leadClasses(date?: string, options?: any) {
        return LeadApiFp(this.configuration).leadClasses(date, options).then((request) => request(this.axios, this.basePath));
    }
}
