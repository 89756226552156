import { AlertColor } from '@mui/material'
import { SnackbarActionType } from '../action-types'
import { snackbarAction } from '../actions'

interface SnackbarInterface {
  successSnackbarOpen?: boolean
  snackbarMessage?: string
  infoSnackbarOpen?: boolean
  type?: AlertColor
  timer?: number
}

const initialState = {
  successSnackbarOpen: false,
  snackbarMessage: '',
  infoSnackbarOpen: false,
  type: undefined,
  timer: 2000,
}

const snackbarReducer = (state: SnackbarInterface = initialState, action: snackbarAction) => {
  switch (action.type) {
    case SnackbarActionType.SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        type: 'success',
        snackbarMessage: action.message,
        timer: action.timer,
      }
    case SnackbarActionType.SNACKBAR_FAILURE:
      return {
        ...state,
        successSnackbarOpen: true,
        type: 'error',
        snackbarMessage: action.message,
        timer: action.timer,
      }
    case SnackbarActionType.SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        infoSnackbarOpen: false,
      }
    default:
      return state
  }
}

export default snackbarReducer
