import { TermDocument } from './../../swagger/models/term-document'
import { TermClass } from './../../swagger/models/term-class'
/* eslint-disable import/no-anonymous-default-export */

import { Action } from '../actions/index'
import { ActionType } from '../action-types/index'
import { ProgramGroup, Term, TermClassChapter, TermSubject } from '../../swagger/models'

interface ProgramManagement {
  getTerm?: Term
  listTerms?: Term[]
  listProgramGroups: ProgramGroup[]
  actionType?: string
}

const initialState = {
  getTerm: {},
  listTerms: [],
  listProgramGroups: [],
}

const programMgmtReducer = (state: ProgramManagement = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.GET_TERM:
      return {
        ...state,
        getTerm: action.termDetails,
        actionType: 'GET',
      }
    case ActionType.GET_TERM_LIST:
      return {
        ...state,
        listTerms: action.termList,
        actionType: 'GET',
      }
    case ActionType.GET_PROGRAM_GROUP_LIST:
      return {
        ...state,
        listProgramGroups: action.programGroupList,
        actionType: 'GET',
      }
    case ActionType.ADD_PROGRAM_GROUP:
      return {
        ...state,
        listProgramGroups: [...state.listProgramGroups, action.programGroupDetail],
        actionType: 'GET',
      }
    case ActionType.UPDATE_PROGRAM_GROUP:
      const pgIndex = state.listProgramGroups.findIndex(
        (i: ProgramGroup) => i.progGroupId === action.programGroupDetail.progGroupId
      )
      return {
        ...state,
        listProgramGroups: [
          ...state.listProgramGroups.slice(0, pgIndex),
          action.programGroupDetail,
          ...state.listProgramGroups.slice(pgIndex + 1),
        ],
        actionType: 'GET',
      }
    case ActionType.UPDATE_TERM:
      return {
        ...state,
        getTerm: action.termDetails,
        actionType: 'UPDATE',
      }
    case ActionType.ADD_TERM:
      return {
        ...state,
        getTerm: action.termDetails,
        actionType: 'ADD',
      }
    case ActionType.UPDATE_TERM_CLASS:
      return {
        ...state,
        getTerm: {
          ...state.getTerm,
          TermSubjects: [
            ...state.getTerm?.TermSubjects.slice(0, action.subjectIndex),
            {
              ...state.getTerm?.TermSubjects[action.subjectIndex],
              TermClasses: [
                ...state.getTerm?.TermSubjects[action.subjectIndex]?.TermClasses.slice(
                  0,
                  action.classIndex
                ),
                action.termClassDetail,
                ...state.getTerm?.TermSubjects[action.subjectIndex]?.TermClasses.slice(
                  action.classIndex + 1
                ),
              ],
            },
            ...state.getTerm?.TermSubjects.slice(action.subjectIndex + 1),
          ],
        },
        actionType: 'UPDATE',
      }
    case ActionType.ADD_TERM_CLASS_DOCUMENT:
      return {
        ...state,
        getTerm: {
          ...state.getTerm,
          TermSubjects: state.getTerm?.TermSubjects?.map(
            (termSubject: TermSubject, index: number) => {
              if (index !== action.subjectIndex) {
                return termSubject
              }
              return {
                ...termSubject,
                TermClasses: termSubject.TermClasses?.map(
                  (termClass: TermClass, classIndex: number) => {
                    if (classIndex !== action.classIndex) {
                      return termClass
                    }
                    return {
                      ...termClass,
                      TermDocuments: [...termClass.TermDocuments, action.termClassDocumentDetail],
                    }
                  }
                ),
              }
            }
          ),
        },
        actionType: 'UPDATE',
      }
    case ActionType.REMOVE_TERM_CLASS_DOCUMENT:
      return {
        ...state,
        getTerm: {
          ...state.getTerm,
          TermSubjects: state.getTerm?.TermSubjects?.map(
            (termSubject: TermSubject, index: number) => {
              if (index !== action.subjectIndex) {
                return termSubject
              }
              return {
                ...termSubject,
                TermClasses: termSubject.TermClasses?.map(
                  (termClass: TermClass, classIndex: number) => {
                    if (classIndex !== action.classIndex) {
                      return termClass
                    }
                    const documentIndex = termClass?.TermDocuments.findIndex(
                      (i: TermDocument) => i.termDocId === action.termDocId
                    )
                    const updatedTermDocsArray = termClass?.TermDocuments
                    updatedTermDocsArray.splice(documentIndex, 1)
                    return {
                      ...termClass,
                      TermDocuments: updatedTermDocsArray,
                    }
                  }
                ),
              }
            }
          ),
        },
        actionType: 'UPDATE',
      }
    case ActionType.REMOVE_TERM_CLASS_CHAPTER:
      return {
        ...state,
        getTerm: {
          ...state.getTerm,
          TermSubjects: state.getTerm?.TermSubjects?.map(
            (termSubject: TermSubject, index: number) => {
              if (index !== action.subjectIndex) {
                return termSubject
              }
              return {
                ...termSubject,
                TermClasses: termSubject.TermClasses?.map(
                  (termClass: TermClass, classIndex: number) => {
                    if (classIndex !== action.classIndex) {
                      return termClass
                    }
                    const classChapterIndex = termClass?.TermClassChapters.findIndex(
                      (i: TermClassChapter) => i.termClassChapterId === action.termClassChapterId
                    )
                    const updatedTermChaptersArray = termClass?.TermClassChapters
                    updatedTermChaptersArray.splice(classChapterIndex, 1)
                    return {
                      ...termClass,
                      TermClassChapters: updatedTermChaptersArray,
                    }
                  }
                ),
              }
            }
          ),
        },
        actionType: 'UPDATE',
      }
    case ActionType.REMOVE_TERM_CLASS:
      return {
        ...state,
        listTerms: state.listTerms?.map((term: Term, index: number) => {
          if (index !== action.termIndex) {
            return term
          }
          return {
            ...term,
            TermSubjects: term?.TermSubjects?.map(
              (termSubject: TermSubject, subjectIndex: number) => {
                if (subjectIndex !== action.subjectIndex) {
                  return termSubject
                }
                const termClassIndex = termSubject?.TermClasses.findIndex(
                  (i: TermClass) => i.termSubClassId === action.termClassId
                )
                const updatedTermClassesArray = termSubject?.TermClasses
                updatedTermClassesArray.splice(termClassIndex, 1)
                return {
                  ...termSubject,
                  TermClasses: updatedTermClassesArray,
                }
              }
            ),
          }
        }),
        actionType: 'UPDATE',
      }
    default:
      return state
  }
}

export default programMgmtReducer
