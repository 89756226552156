/* eslint-disable import/no-anonymous-default-export */

import { Action, TeacherPortalAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Teacher, TeacherSubject, UserTimeslot } from '../../swagger/models'

interface TeacherPortal {
  getTeacher?: Teacher
  teacherSubjectList?: TeacherSubject[]
  addTeacherSubject?: TeacherSubject
  userTimeslots?: UserTimeslot[]
  shouldShowLoader: boolean
}

const initialState = {
  getTeacher: {},
  teacherSubjectList: [],
  userTimeslots: [],
  shouldShowLoader: false,
}

const teacherPortalReducer = (
  state: TeacherPortal = initialState,
  action: Action | TeacherPortalAction
) => {
  switch (action.type) {
    case ActionType.GET_TEACHER_PROFILE:
      return {
        ...state,
        getTeacher: action.getTeacherProfile,
      }
    case ActionType.UPDATE_TEACHER_PROFILE:
      return {
        ...state,
        getTeacher: action.updatedTeacherDetails,
      }
    case ActionType.LIST_TEACHER_SUBJECTS:
      return {
        ...state,
        teacherSubjectList: action.teacherSubjectList,
      }
    case ActionType.ADD_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.UPDATE_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.GET_TIMESLOT:
      return {
        ...state,
        userTimeslots: action.usertimeslot,
      }
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        shouldShowLoader: action.shouldShowLoader,
      }
    default:
      return state
  }
}

export default teacherPortalReducer
