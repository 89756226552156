import {
  Button,
  Stack,
  Grid,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import React, { Fragment, useState } from 'react'

function KidEducationalDetails({ handleBack }: any) {
  const [grade, setGrade] = useState('')
  const [board, setBoard] = useState('')

  const gradeChange = (event: SelectChangeEvent) => {
    setGrade(event.target.value as string)
  }
  const boardChange = (event: SelectChangeEvent) => {
    setBoard(event.target.value as string)
  }

  return (
    <Fragment>
      <h2 className="form-title">Education Details</h2>
      <form>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <InputLabel id="grade-select-label">Grade</InputLabel>
              <Select
                labelId="grade-select-label"
                id="grade-select"
                value={grade}
                label="Grade"
                onChange={gradeChange}
              >
                <MenuItem value={10}>Grade 1</MenuItem>
                <MenuItem value={20}>Grade 2</MenuItem>
                <MenuItem value={30}>Grade 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* item */}
          <Grid item={true} md={6}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <InputLabel id="board-select-label">Board</InputLabel>
              <Select
                labelId="board-select-label"
                id="board-select"
                value={board}
                label="board"
                onChange={boardChange}
              >
                <MenuItem value={10}>board 1</MenuItem>
                <MenuItem value={20}>board 2</MenuItem>
                <MenuItem value={30}>board 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* item */}
        </Grid>
        {/* container */}
        <FormControl fullWidth={true} sx={{ mb: 3 }}>
          <TextField
            type="schoolName"
            fullWidth={true}
            id="schoolName"
            label="School name"
            variant="outlined"
            required={true}
            autoComplete="off"
          />
        </FormControl>
        <h2 className="form-title">Location details</h2>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={4}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="city"
                fullWidth={true}
                id="city"
                label="City"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 4 */}
          <Grid item={true} md={4}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="state"
                fullWidth={true}
                id="state"
                label="State"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 4 */}
          <Grid item={true} md={4}>
            <FormControl fullWidth={true} sx={{ mb: 3 }}>
              <TextField
                type="country"
                fullWidth={true}
                id="country"
                label="Country"
                variant="outlined"
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* 4 */}
        </Grid>
        {/* container */}
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{ mt: 3 }}>
          <Button type="button" variant="text" color="primary" onClick={handleBack}>
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button type="button" variant="text" color="primary">
            skip
          </Button>
        </Stack>
      </form>
    </Fragment>
  )
}

export default KidEducationalDetails
