import { ApiController } from '../../apiController'
import { Student, UserTimeslotHistory } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { Dispatch } from 'redux'
import { snackbarAction, StudentPortalAction } from '../actions'

export const addTimeslotAPI = (timeslot: UserTimeslotHistory) => {
  return async (dispatch: Dispatch<StudentPortalAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await ApiController.getInstance().studentApi.createStudentSchedule(
        timeslot
      )
      dispatch({
        type: ActionType.ADD_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details added successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const updateTimeslotAPI = (timeslot: UserTimeslotHistory) => {
  return async (dispatch: Dispatch<StudentPortalAction | snackbarAction>) => {
    try {
      const timeslotResponse = await ApiController.getInstance().studentApi.updateStudentSchedule(
        timeslot
      )
      dispatch({
        type: ActionType.UPDATE_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Timeslot details updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const getTimeslotAPI = (queryDate: string, classType: string, timezone: string) => {
  return async (dispatch: Dispatch<StudentPortalAction | snackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const timeslotResponse = await ApiController.getInstance().studentApi.getStudentSchedule(
        queryDate.toString(),
        classType,
        timezone
      )
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: ActionType.GET_TIMESLOT,
        usertimeslot: timeslotResponse.data,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const getStudentProfileApi = () => {
  return async (dispatch: Dispatch<StudentPortalAction | snackbarAction>) => {
    try {
      const userResponse = await ApiController.getInstance().studentApi.studentProfile()
      dispatch({
        type: ActionType.GET_STUDENT_PROFILE,
        getStudent: userResponse.data,
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
export const updateStudentProfileApi = (studentDetail: Student) => {
  return async (dispatch: Dispatch<StudentPortalAction | snackbarAction>) => {
    try {
      const userResponse = await ApiController.getInstance().studentApi.updateStudentProfile(
        studentDetail
      )
      dispatch({
        type: ActionType.UPDATE_STUDENT_PROFILE,
        updateStudent: userResponse.data,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Details updated successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}
